import { CalculatedLinesResponse } from './promo';

export enum LegacySources {
  Local = 'L',
  CallCenter = 'C',
  Web = 'W',
  Apps = 'P',
  Kiosk = 'K',
  TabletPOS = 'T',
  Jahez = 'J',
  Hunger = 'H',
}
export enum OrderLineTypes {
  composed = 'composed',
  crossSales = 'cross',
  promotion = 'promo',
  simple = 'simple',
}
export enum OrderTypes {
  Recoger = 'R',
  Domicilio = 'D',
  Local = 'L',
}

export enum enumDeviceTypes {
  android = 'A',
  ios = 'I',
  webMobile = 'M',
  webDesktop = 'D',
}

export enum orderAggregatorStatus {
  elaborated = 'E',
  picked = 'P',
  cancelled = 'C',
  delivered = 'D',
}

// export interface OrderCommonLineInterface {
//   id: string
//   quantity: number
//   type: OrderLineTypes
//   unitPrice: number
// }

// export interface OrderSubLineInterface {
//   saleItemID: string
//   toppings: string
//   toppingsNbr: number
//   unitPrice: number
// }

// export interface OrderLineInterface extends OrderCommonLineInterface {
//   sublines: OrderSubLineInterface[]
// }

// export interface OrderPromoLineInterface extends OrderCommonLineInterface {
//   sublines: OrderLineInterface[]
// }

export interface Address {
  addressID?: number;
  streetID: number;
  stDirectoryID: number;
  home?: string | null;
  additionalIndications: string;
  stNumber?: number;
  building?: string;
  stairNbr?: string;
  flatNbr?: string;
  doorNbr?: string;
  lat: number;
  lng: number;
  resolvedAddress: string;
  description?: string;
  addressType?: string | null;
}

export interface OrderInterface {
  address?: Address;
  total?: number;
  branchID: number;
  change: number;
  customerID: number;
  device: string;
  fee: CalculatedLinesResponse;
  feePromoCode: string;
  language: string;
  lines: CalculatedLinesResponse[];
  orderNumber: number;
  orderType: OrderTypes;
  paymentMethod: number;
  promisedTime: string;
  source: string;
  status: string;
  addressID?: number;
  clickNcar?: string;
  deliveryIndications?: string;
  phoneNumber?: number;
  ts?: string;
}
