import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { environment } from '@environments/environment';
import { FormConfig } from '@core/modules/angular-ionic-forms/common/config';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { FormMetadataButtonInterface, FormMetadataFieldInterface } from '@core/modules/angular-ionic-forms/common/interfaces';
import { ApiService } from '@services/api.service';
import { RxjsService } from '@services/rxjs.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  MenuFamilyProductInterface,
  MenuFamilyToppingInterface,
  MenuInterface,
  PromoInterface,
  OrderInterface,
  OrderTypes,
  PromoTypes
} from '@lib/promo-engine';
import { LangService } from '@services/lang.service';
import { CreateOrderInterface, MenuCustomToppingGroupInterface, PgMenu, PgOrder } from '@lib/utils';
import { DataService } from '@services/data.service';
import { StorageService } from '@services/storage.service';
import { StorageVariables, kiosk } from '@core/constants';
import { PlatformService } from '@services/platform.service';
import { takeUntil } from 'rxjs/operators';
import { LangChangeEvent } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { FaeventsService } from '@services/faevents.service';
import { DeliveryService } from '@services/delivery.service';

export interface PromoCodeResponse {
  errors: any[]
  ticket: OrderInterface
}

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
})
export class ModalsComponent {
  deliveryType: string = OrderTypes.Domicilio;
  productsUrl: string = environment.productsUrl
  assets: string = environment.assetsUrl
  customMsg: string
  cvv: string
  customParams: any
  templateName: string
  handleChoiceOrderBy: string
  formMetadata: FormMetadata
  promoCodeError: string
  successMsg: string
  chooseOneDough: boolean = false;
  dough: any;
  isIos: boolean;
  lang: string;
  buttonPromoCode: FormMetadataButtonInterface[]
  fieldsPromoCode: FormMetadataFieldInterface[] = [
    {
      class: '',
      disabled: false,
      group: "",
      id: "promocode",
      isReadOnly: false,
      fieldType: 'text',
      label: '',
      name: 'promocode',
      placeholder: 'LANG_ENTER_PROMO_CODE_HERE',
      options: {
        label: {
          color: "success",
          position: "stacked"
        },
        size: {
          lg: '12',
          md: '12',
          sm: '12',
          xl: '12',
          xs: '12'
        },
        filter: "upperPipe"
      },
      position: 1,
      validators: [
        {
          errorMsg: '',
          params: {},
          validatorType: 'required'
        },
        {
          errorMsg: 'LANG_INVALID_CODE',
          params: {
            length: 15
          },
          validatorType: 'maxLength'
        },
        {
          errorMsg: 'LANG_INVALID_CODE',
          params: {
            length: 3
          },
          validatorType: 'minLength'
        }
      ],
      value: ''
    }]
  fieldsCommentCrossales: FormMetadataFieldInterface[] = [
    {
      class: '',
      disabled: false,
      group: "",
      id: "comment",
      isReadOnly: false,
      fieldType: 'text',
      label: '',
      name: 'comment',
      placeholder: '',
      options: {
        label: {
          color: "success",
          position: "stacked"
        },
        size: {
          lg: '12',
          md: '12',
          sm: '12',
          xl: '12',
          xs: '12'
        }
      },
      position: 1,
      validators: [
        {
          errorMsg: '',
          params: {
            length: 99
          },
          validatorType: 'maxLength'
        },

      ],
      value: ''
    }]
  formConfig: FormConfig
  formConfigCommentCrossales: FormConfig
  order: OrderInterface
  promotion: PromoInterface
  productOutOfStock: MenuFamilyProductInterface[] = []
  toppingsOutOfStock: MenuFamilyToppingInterface[] = []
  promotionsOutOfStock: any[] = []
  private pgMenu: PgMenu
  private unsubscribe = new Subject<void>();
  detailIcon: string
  isKiosk: boolean = kiosk
  kioskBranch: any
  error: string
  discountPromoType: PromoTypes = PromoTypes.Descuento
  toppingGroupsError: MenuCustomToppingGroupInterface[]
  excludedToppings: MenuFamilyToppingInterface[]
  includedToppings: MenuFamilyToppingInterface[]
  customMsgIsLang: boolean = true
  constructor(
    private readonly _router: Router,
    private readonly modalController: ModalController,
    private readonly navParams: NavParams,
    private readonly api: ApiService,
    private readonly langService: LangService,
    private readonly rxjsService: RxjsService,
    private readonly dataService: DataService,
    private readonly storageService: StorageService,
    private readonly platformService: PlatformService,
    private readonly platform: Platform,
    private faevents: FaeventsService,
    private readonly deliveryService: DeliveryService
  ) {
    if (this.platformService.isIos()) {
      this.buttonPromoCode = [
        {
          buttonType: 'S',
          class: 'buttonEnterPromoCodeIos ion-float-end',
          color: 'primary',
          expand: 'default',
          fill: 'solid',
          label: 'LANG_ENTER',
          position: 2,
          size: 'default'
        }
      ]
    } else {
      this.buttonPromoCode = [
        {
          buttonType: 'S',
          class: 'buttonEnterPromoCode ion-float-end',
          color: 'primary',
          expand: 'default',
          fill: 'solid',
          label: 'LANG_ENTER',
          position: 2,
          size: 'default'
        }
      ]
    }
    this.formConfig = {
      buttons: this.buttonPromoCode,
      commitMode: 'submit',
      fields: this.fieldsPromoCode,
      focusOnFirstElement: true,
      formName: 'promoCodeForm',
      groups: [],
      isReadOnly: false
    }
    this.formConfigCommentCrossales = {
      buttons: this.buttonPromoCode,
      commitMode: 'submit',
      fields: this.fieldsCommentCrossales,
      focusOnFirstElement: true,
      formName: 'crossForm',
      groups: [],
      isReadOnly: false
    }
  }
  ionViewWillEnter(): void {
    let lang: string = this.langService.getCurrentLang()
    this.customMsg = this.navParams.get('customMsg')
    this.customMsgIsLang = this.customMsg.startsWith('LANG_')
    this.customParams = this.navParams.get('customParams')
    if (this.templateName == 'chooseDough') {
      if (this.customParams.id !== undefined) {
        this.showDough(this.customParams)
      }
    }
    this.templateName = this.navParams.get('templateName')
    if (this.templateName == 'outOfStock') {
      this.storageService.getItem(`${StorageVariables.menu}-${lang}`).subscribe(menu => {
        this.pgMenu = new PgMenu(menu, [])
        this.customParams.products.forEach(value => {
          this.productOutOfStock.push(this.pgMenu.getProduct(value))
        })
        this.customParams.toppings.forEach(value => {
          this.toppingsOutOfStock.push(this.pgMenu.getTopping(menu, value))
        })
      }, error => {
        console.log(error)
      })
    } else if (this.templateName === 'excludedItems') {
      this.promotionsOutOfStock = this.customParams.excludedItems.reduce(
        (result: any[], value) => {
          if (value && value.orderType.includes(this.customParams.excludedOrderType)) {
            result.push({
              description: value.description,
              id: value.id
            })
          }
          return result
        }, []
      )
    } else if (this.templateName === 'toppingGroupsError') {
      this.toppingGroupsError = this.customParams.toppingGroups
    } else if (this.templateName === 'toppingsPromotion') {
      this.excludedToppings = []
      this.includedToppings = []
      this.storageService.getItem(`${StorageVariables.menu}-${lang}`).subscribe(menu => {
        this.pgMenu = new PgMenu(menu, [])
        if (this.customParams.excluded_toppings && Array.isArray(this.customParams.excluded_toppings) && this.customParams.excluded_toppings.length > 0) {
          this.excludedToppings = this.customParams.excluded_toppings.map(t => this.pgMenu.getTopping(menu, t))
        }
        if (this.customParams.included_topping && Array.isArray(this.customParams.included_topping) && this.customParams.included_topping.length > 0) {
          this.includedToppings = this.customParams.included_topping.map(t => this.pgMenu.getTopping(menu, t))
        }
      }, error => {
        console.log(error)
      })
    }
  }
  ngOnInit() {

    this.detailIcon = this.langService.isRtl() ? 'chevron-back' : 'chevron-forward';

    this.platform.ready().then(
      () => {
        this.langService.onLangChange().pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(
          (result: LangChangeEvent) => {
            this.lang = result.lang
            this.detailIcon = result.lang === 'ar' ? 'chevron-back' : 'chevron-forward';
          }
        )
      }
    )

    const langConfig = this.langService.getLangConfig()
    this.lang = langConfig.lang

    if (this.templateName == 'promoCode') {
      this.formMetadata = new FormMetadata(this.formConfig)
    }
    if (this.templateName == 'commentCrossales') {
      this.formMetadata = new FormMetadata(this.formConfigCommentCrossales)
    }
    if (this.platformService.isIos()) {
      this.isIos = true;
    } else {
      this.isIos = false;
    }
  }
  goTo(page: string): void {
    this._router.navigate(['/' + page])
    this.dismiss()
  }
  handleChoice(event) {
    this.handleChoiceOrderBy = event.detail.value;
  }
  clear() {
    this.handleChoiceOrderBy = '';
    this.modalController.dismiss({
      result: this.handleChoiceOrderBy
    }, '', this.templateName)
  }
  // Dismiss Modal
  dismiss(): void {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    if (this.chooseOneDough) {
      this.chooseOneDough = false;

      if (this.customParams.id !== undefined) {
        this.modalController.dismiss({
          'dismissed': true
        })
      }

    } else if (this.templateName === 'commentCrossales') {
      this.modalController.dismiss({
        result: '',
        params: this.customParams
      })
    } else {
      this.modalController.dismiss({
        'dismissed': true
      })
    }
  }
  dismissModalWithOptionOrder(): void {
    this.modalController.dismiss({
      result: this.handleChoiceOrderBy
    }, '', this.templateName)
  }
  dismissModalWithBranch(chosenOption: any): void {
    this.modalController.dismiss({ result: chosenOption }, '', this.templateName)
  }
  dismissModalWithOption(chosenOption: boolean): void {
    this.modalController.dismiss({
      result: chosenOption
    }, '', this.templateName)
  }
  dismissModalWithPromoCode(promoCode: string): void {
    this.modalController.dismiss({
      result: promoCode
    })
  }
  // addSelectedPromotion
  getFormData(event) {
    let promoCode: string = event.value.promocode
    this.promoCodeError = ''
    this.successMsg = ''
    this.rxjsService.createObservableFromMultipleRequests([
      this.rxjsService.createObservableFromPromise(this.dataService.getMenu()),
      this.rxjsService.createObservableFromPromise(this.dataService.getOrder()),
      this.rxjsService.createObservableFromPromise(this.dataService.getPromotions())
    ]).subscribe(
      (result: [MenuInterface, CreateOrderInterface, PromoInterface[]]) => {
        let menu: MenuInterface = result[0]
        let order: CreateOrderInterface = result[1]
        let promotions: PromoInterface[] = result[2]
        let pgOrder: PgOrder = new PgOrder(order, menu, promotions)
        this.api.checkPromoCode(pgOrder.prepareOrderToSaveTicket(true), promoCode).toPromise().then(
          (promotion: PromoInterface) => {
            if (promotion && 'id' in promotion) {
              this.dataService.addPromotionToOrder(promotion, menu, order, promotions, promoCode).then(
                async () => {
                  this.api.setData('showCart')
                  this.successMsg = 'LANG_PROMOCODE_APPLIED'
                  let that: this = this
                  setTimeout(() => {
                    that.dismiss()
                    that.successMsg = ''
                  }, 2000)
                },
                (errors: any[]) => {
                  this.setPromoCodeError(promoCode, errors)
                }
              )
            } else {
              this.setPromoCodeError(promoCode, [])
            }
          },
          (err: HttpErrorResponse) => {
            try {
              let info = this.dataService.getErrorInfoSaveTicket(err)
              if (info.type === 'PromoOnlyFirstOrder') {
                let promotion: PromoInterface = promotions.find(p => p.id === info.id)
                this.setPromoCodeError(
                  promoCode,
                  [
                    {
                      'code': info.type,
                      'promoID': (promotion! && 'description' in promotion) ? promotion.description : info.id
                    }
                  ]
                )
              } else {
                this.setPromoCodeError(promoCode)
              }
            } catch (e) {
              this.setPromoCodeError(promoCode)
            }
          }
        )
      },
      () => {
        this.setPromoCodeError(promoCode)
      }
    )
  }
  async setPromoCodeError(promoCode: string, errors: any[] = []) {
    this.promoCodeError = await this.langService.getCustomMsgFromPromoEngineResult(errors, promoCode)
    this.faevents.preLogEvent("app_response_error", { "error_message": "Wrong promo code" })
  }
  showDough(dough) {
    this.chooseOneDough = true;
    this.dough = dough;
  }

  getComment(event) {
    let comment: string = event.value.comment
    this.modalController.dismiss({
      result: comment,
      params: this.customParams
    })
  }

  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.assets + "images/promonotfound.png") {
        event.target.src = this.assets + "images/promonotfound.png"
      }
    }
  }
}