import { EventEmitter, Injectable } from '@angular/core';
import { RxjsService, CustomObservableType } from '@services/rxjs.service';
import { ApiService } from '@services/api.service';
import { OrderTypes } from '@lib/promo-engine';
import { StorageService } from '@services/storage.service'
import { UserDataService } from '@services/user-data.service';
import { GeolocationService } from '@core/services/geolocation.service';
import { StorageVariables } from '@core/constants';
import { SelectPromiseTimeService } from '@services/select-promise-time.service';
import { LangService } from './lang.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  private updateDropDown: EventEmitter<any> = new EventEmitter();
  private updateAddressSelected: EventEmitter<any> = new EventEmitter();
  private placeOrder: EventEmitter<any> = new EventEmitter();
  private zoneDelivery;
  public addresses : any = false;
  public data : any =false;
  private notifyBranches: EventEmitter<any> = new EventEmitter();
  public branchPickup : any = false;
  public selectedAddress : any = false;
  public locationAndZone : any = false;
  private lastPickupOrder : any = false;
  public userPosition : any = false;
  private branchSelectedByDefault : any = false;
  public userPositionRequested = false;
  public customerDetails : any = false;

  private screenSaverValue = new BehaviorSubject({});

  getScreenValue = this.screenSaverValue.asObservable();

  branchKiosk: any = false

  lang: string
  constructor(private rxjsService: RxjsService,
    private readonly _api: ApiService,    
    private geoLocationService: GeolocationService, 
    private userDataService: UserDataService,
    private readonly storageService: StorageService,
    private pTimeService: SelectPromiseTimeService,
    private langService: LangService
    ) { 
      this.zoneDelivery = false;
      const langConfig = this.langService.getLangConfig()
      this.lang = langConfig.lang
    }


  // public mergeAddreses(addresses){
  //   let merged = [];
  //   this.addresses.forEach(element => {
  //       if(element.addressID && element.addressID <=0  || element.orderNumber){
  //         merged.push(element);
  //       }
  //   });
  //   addresses.forEach(element => {
  //     merged.push(element);
  //   });
  //   this.addresses = merged;
  // }

  public getKioskBranch(){

    this.storageService.getItem(StorageVariables.kioskBranch).subscribe(kioskBranch => {
      this.branchKiosk = kioskBranch
    },
    error => {
      console.log(error)
    })

    return this.branchKiosk
  }

  public setKioskBranch(id){
    this.branchKiosk = id;
    this.storageService.setItem(StorageVariables.kioskBranch, id);
  }
 
  public fill_up_dropdown(addresses) : void{ // new location from address saved
    this.updateDropDown.emit(addresses);
  }


  public onUpdateDropDown() : EventEmitter<any> {
    return this.updateDropDown;
  }

  public newAddressSelected(addresses){
    this.updateAddressSelected.emit(addresses);
  }

  public onAddressSelected() : EventEmitter<any> {
    return this.updateAddressSelected;
  }

  public placeOrderEmmit(data){
    this.placeOrder.emit(data);
  }

  public onPlaceOrder() : EventEmitter<any> {
    return this.placeOrder;
  }

  public getZoneDelivery(){
    return this.zoneDelivery;
  }

  public setZoneDelivery(zone){
    this.zoneDelivery = zone;
  }

  findZoneDelivery(arg0: { lat: any; lng: any; }): CustomObservableType<any> {
    const pendingResult: CustomObservableType<any> = this.rxjsService.createCustomObservable()
    let that = this;
    function resolve(result) {
      that.zoneDelivery = result;
      pendingResult.next(result)
      pendingResult.complete()
    }
    function resolveError() {
      that.zoneDelivery = false;
      pendingResult.error({})
      pendingResult.complete()
    }

    this._api.findZoneDelivery(arg0.lat + "," + arg0.lng).subscribe(
      result => {
        if (result.branch && result.zone) {
          resolve(result)
          // let branch = result.branch;
          // this._api.getPromiseTimes(branch.id, OrderTypes.Domicilio).subscribe(
          //   times => {
          //     result.branch.promiseTimes = times;
          //     resolve(result);
          //   },
          //   error => {
          //     result.branch.promiseTimes = [];
          //     resolve(result);
          //   }
          // );
        } else {
          resolveError();
        }
      },
      error => {
        resolveError();
      }
    )
    return pendingResult

  }

  public getPromiseTimes() {
    if (this.zoneDelivery && this.zoneDelivery.branch) {
      this._api.getPromiseTimes(this.zoneDelivery.branch.id, OrderTypes.Domicilio).subscribe(
        times => {
          this.zoneDelivery.branch.promisedTimes = times;
          this.pTimeService.updatePromiseTimes(times);
        },
        error => {
          this.zoneDelivery.branch.promisedTimes = [];
          this.pTimeService.clearPromisesNoDeliveryTime();
        }
      );
    }
  }

  public onUpdateBranches() : EventEmitter<any> {
    return this.notifyBranches;
  }


  public isGoodPosition = function(position){      
    return (position.lat && position.lng && position.lat>=-90 && position.lat <=90 && position.lng>=-180 && position.lng<=180)
  }


  public getUser = async function () {
    var res = { user: false };
    res.user = await this.userDataService.getUser().toPromise().catch(e => res.user = false);
    if(res.user){
      this.userDataService.isLoged = res.user;
    }
    return res.user;
  }

  public customerAddresses = async function () {
    var last = { addresses: [] }
    last.addresses = await this._api.getCustomerAddressesNoLoading().toPromise().catch(e => last.addresses = []);
    let filtered = [];
    last.addresses.forEach(lastAdress => {
      if(this.isGoodPosition(lastAdress)){
        filtered.push(lastAdress);
      }
    });
    last.addresses = filtered;
    last.addresses.sort(function (a, b) {
      return (b.addressID) - (a.addressID)
    });
    return last.addresses.length > 0 ? last.addresses : false;
  }

  public getAddresses = async function() {

    let user = await this.getUser();
    if(!user){
      return [];
    }else if (this.addresses) {
      return this.addresses;
    } else {
      let addresses = await this.customerAddresses()
      if (addresses) {
        addresses = addresses.filter(address => address.hasOwnProperty('addressID') && address.description !== null && address.description !== '');
        this.addresses =  addresses;
        return this.addresses;
      } else {
        this.addresses =  [];
        return this.addresses;
        }
    }
  }

  init_B_PDZ = async (fromGetPosition = false, locateMe: any = false,evtName:any = false,evtName2 : any =false): Promise<any> => {

    let that = this;
    let defaultDelivery = { lat: 24.796069, lng: 46.62901 };
    let defaultPickup = { lat: 24.796069, lng: 46.62901 };

    const findNearest = async function (position) {
      var res = {};
      res["branch"] = await that._api.getNearestBranches("" + position.lat + "," + position.lng+"&loading=false", that.lang, 1).toPromise().catch(e => res["branch"] = false);
      if (res["branch"]) {
        return (res["branch"][0]);
      } else {
        return false;
      }
    }

    const getZone = async function (position) {
      var res = { data: false };
      res.data = await that._api.findZoneDelivery("" + position.lat + "," + position.lng+"&loading=false").toPromise().catch(e => res.data = false);
      return res.data;
    }

    const lastPickupOrder = async function () {
      var last_ = { order: [] }
      last_.order = await that._api.getCustomerOrders(1, OrderTypes.Recoger+"&loading=false").toPromise().catch(e => last_.order = []);
      if (last_.order.length ) {
        that.lastPickupOrder =  last_.order[0];
        that.storageService.setItem(StorageVariables.lastPickUpOrder, last_.order[0])
        return true;
      }else{
        that.lastPickupOrder =  false;
      }
      return false;
    }
    const getCustomerDetails = async function () {
      var res = { data: [] }
      res.data = await that._api.getCustomerDetails().toPromise().catch();
      if (res.data.length > 0) {
        var loyalty = { points: [] }
        loyalty.points = await that._api.getCustomerLoyaltyPoints().toPromise().catch();
        that.customerDetails = res.data[0];
        let loyaltyPoints = 0;
        if (loyalty.points.length > 0) {
          loyalty.points.forEach(loyal => {
            loyaltyPoints += loyal.amount;
          });
        }
        that.customerDetails.loyaltyPoints = loyaltyPoints;
      } else {
        that.customerDetails = false;
      }
      return that.customerDetails
    }

    const getLastDeliveryOrder = async function () {
      var last_ = { order: [] }
      last_.order = await that._api.getCustomerOrders(1, OrderTypes.Domicilio + "&loading=false").toPromise().catch(e => last_.order = []);
      if (last_.order.length > 0 && that.isGoodPosition(last_.order[0])) {
        return last_.order[0];
      }
      return false;
    }

    const flowLastDelivery = async function () {
      let last = await getLastDeliveryOrder();
      if (last) {
        let res = { last: last, zone: false };
        res.zone = await getZone({ lat: last.lat, lng: last.lng });
        if (res.zone) {
          return res
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    const flowLocationDelivery = async function (){
        that.storageService.removeItem(StorageVariables.locationAndZone)
        that.locationAndZone = false;
        return await getZone(defaultDelivery);
    }

    const DELIVERY_ = async function () {
      let user = await that.getUser();
      if (user) {
        let lastDelivery = await flowLastDelivery();
        if (lastDelivery) {
          that.storageService.removeItem(StorageVariables.locationAndZone)
          that.locationAndZone = false;
          that.storageService.setItem(StorageVariables.selectedAddress, { type: 'LastOrder', 'address': lastDelivery.last, 'zone': lastDelivery.zone });
          that.selectedAddress = { type: 'LastOrder', 'address': lastDelivery.last, 'zone': lastDelivery.zone };
          return lastDelivery.zone;
        } else {
          that.storageService.removeItem(StorageVariables.selectedAddress)
          that.selectedAddress = false;
          return await flowLocationDelivery();
        }
      } else {
        that.storageService.removeItem(StorageVariables.selectedAddress)
        that.selectedAddress = false;
        return await flowLocationDelivery();
      }
    }

    const getBranchKiosk = async function (id) {
      var res = {};
      res["branch"] = await that._api.getBranchById(id, that.lang, OrderTypes.Recoger,false).toPromise().catch(e => res["branch"] = false);
      if (res["branch"]) {
        return (res["branch"]);
      } else {
        return false;
      }
    }

    const PICKUP_ = async function () {
        that.userPosition = false;
        that.storageService.setItem(StorageVariables.allowLocation, false)
        that.storageService.removeItem(StorageVariables.location);
        return await findNearest(defaultPickup);
    }

    let res;

    if(this.branchKiosk){
      let pickup = await getBranchKiosk(this.branchKiosk);
      res = { delivery: false, pickup: pickup };
    }
    else{
   
      if (fromGetPosition) {
      let delivery = await DELIVERY_();
      let pickup;
      if (locateMe) {
        pickup = await PICKUP_();
      } else {
        this.storageService.setItem(StorageVariables.allowLocation, false)
        pickup = await findNearest(defaultPickup);
      }
      res = { delivery: delivery, pickup: pickup };
    } else {
      let response = await Promise.all([DELIVERY_(), PICKUP_()])
      res = { delivery: response[0], pickup: response[1] };
    }
  }

    if (res.pickup) {
      this.storageService.setItem(StorageVariables.branchPickup, res.pickup)
      this.branchPickup = res.pickup;
      if(this.branchPickup.id == 5 && !this.lastPickupOrder && !this.userPosition){
          this.branchSelectedByDefault = true;
      }else{
        this.branchSelectedByDefault = false;
      }
    }

    if (res.delivery) {
      this.storageService.setItem(StorageVariables.branchDelivery, res.delivery.branch)
      this.storageService.setItem(StorageVariables.currentZone, res.delivery.zone)
    }

    if(evtName){
      res["evtName"]= evtName;
    }
    if(evtName2){
      res["evtName2"]= evtName2;
    }

    this.notifyBranches.emit(res);
    this.data = res;
  
  }

  public overrideBranchPickup(branch) {
    branch.userSelection = true;
    this.storageService.setItem(StorageVariables.branchPickup, branch)
    this.branchPickup = branch;
  }

  public isBranchByDefault() : boolean{
    return this.branchSelectedByDefault;
  }

  setScreenSaver<T = any>(data: T) {
    this.screenSaverValue.next(data);
  }



}
