import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@src/environments/environment';
import { Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { isGroundr, isMaestro } from '@core/functions';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  
  isGroundr: boolean = isGroundr()
  isMaestro: boolean = isMaestro()

  //Pantalla para confirmar cuestionario
  showConfirmQuestionnaire: boolean = false;
  //Pantalla para mostrar el cuestionario
  showQuestionnaire: boolean = false;
  // Lista de preguntas para el cuestionario con su respectivo texto traducido
  questions = [
    {
      text: 'LANG_FIRST_QUESTION', // Clave de traducción para la primera pregunta
      stars: [false, false, false, false, false] // Estrellas para la primera pregunta
    },
    {
      text: 'LANG_SECOND_QUESTION', // Clave de traducción para la segunda pregunta
      stars: [false, false, false, false, false] // Estrellas para la segunda pregunta
    },
    {
      text: 'LANG_THIRD_QUESTION',
      stars: [false, false, false, false, false]
    },
    {
      text: 'LANG_FOURTH_QUESTION',
      stars: [false, false, false, false, false]
    },
    {
      text: 'LANG_FIFTH_QUESTION',
      stars: [false, false, false, false, false]
    },
    {
      text: 'LANG_SIXTH_QUESTION',
      stars: [false, false, false, false, false]
    },
    {
      text: 'LANG_SEVENTH_QUESTION',
      stars: [false, false, false, false, false]
    },
    {
      text: 'LANG_EIGHTH_QUESTION',
      stars: [false, false, false, false, false]
    },
    {
      text: 'LANG_NINTH_QUESTION',
      stars: [false, false, false, false, false]
    },
    {
      text: 'LANG_TENTH_QUESTION',
      stars: [false, false, false, false, false]
    },
  ];

  //Questionnaire
  showThanks: boolean = false;

  assets: string = environment.assetsUrl;
  @Input() page?: string = ""
  @Input() set getScore(value: number) {
    this.score = value ? value : 0
    if(value){
      this.disableRating = true;
    }
  }

  @Output() dataRatingEvent = new EventEmitter<{rating:number,category:string}>();
  @Output() resetTimeOut =  new EventEmitter<boolean>()
 
  score: number = 0
  public showFacesRating: boolean = true
  disableRating: boolean = false
  public disableButtonExp = false
  category: string = ""
  public categorySelect = [{desc:"Item Customization",selected:false},{desc:"Navigation",selected:false},{desc:"Speed",selected:false},{desc:"Payment experience",selected:false}]
  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
    console.log(this.score)
  }

  rating(number, event) {
    this.disableButtonExp = true;
    this.score = number;
    var url = event.target.src.split('.svg');
    event.target.src = url[0] + "select.svg";
    this.resetTimeOut.emit(true);
    setTimeout(() => {
      this.showFacesRating = false;
    }, 500)
  }

  selectCategoryToImprove(position: number) {
    this.categorySelect[position].selected ?  this.categorySelect[position].selected = false:  this.categorySelect[position].selected = true;
  }

  submit() {
    var catSelected = [];
    this.categorySelect.forEach(function(current){
      if(current.selected) {
        catSelected.push(current.desc);
      }
    })
    
    //this.showConfirmQuestionnaire = true // Muestra un modal para confirmar responder el cuestionario
    this.dataRatingEvent.emit({rating:this.score,category:catSelected.join(",")});
  }

  //Botones Cuestionario
  answerQuestions(){
    this.showConfirmQuestionnaire = false;
    this.showQuestionnaire = true;
  }

  cancelQuestions(){
    window.location.reload();
  }

  // Actualiza las estrellas para la pregunta especificada
  setRating(questionIndex: number, rating: number) {
    
    this.questions[questionIndex].stars = this.questions[questionIndex].stars.map((_, index) => index < rating);
  }

  cancelQuesionnaire(){
    this.showQuestionnaire = false;
    window.location.reload();
  }

  submitQuesionnaire(){
    //1. Aquí falta que envíe las respuestas

    //2. Esconde la pantalla del cuestionario
    this.showQuestionnaire = false;
    //3. Muetsra la de felicitación durante 2s
    this.showThanks = true;
    setTimeout(() => {
      this.showThanks = false;
    }, 2000);

    //4. Redirige al screenSaver
    setTimeout(() => {
      window.location.reload();
    }, 1500);
    
  }

}
