import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LangService } from '@services/lang.service';
import { Subject } from 'rxjs';
import { ApiService } from '@services/api.service';
import { environment } from '@src/environments/environment';
import {UserDataService,PaymentTokenInterface} from '@services/user-data.service';
import { ModalService } from '@services/modal.service';
import { PlatformService } from '@services/platform.service';
import { kiosk } from '@core/constants';
import { isGroundr, isMaestro } from '@core/functions';

@Component({
  selector: 'app-select-payment',
  templateUrl: './select-payment.component.html',
  styleUrls: ['./select-payment.component.scss'],
})
export class SelectPaymentComponent implements OnInit {
  canUseWallets: boolean = false
  order: any
  deliveryFee: number = 0
  @Input() workingMethod: string = 'default'
  @Input() isLoggedIn: boolean
  @Input() set fee(value: number) {
    this.deliveryFee = value ? value : 0
  }
  @Input() set orderTracking(value: any) {
    if(this.order?.orderNumber !== value["orderNumber"] || this.order == undefined){
    this.order = value
    if (this.isLoggedIn && !this.isKiosk) {
      this.getCustomerWallet();
    }
    this._api.getPaymentMethods(this.order.branchID, this.order.orderType)
      .subscribe(
        (methods: any) => {
         
          let availables = [];
          let applePayCodes = [environment.gateWays.applePay.checkout.letter, environment.gateWays.applePay.hyperPay.letter]
          let walletCode = 'E';
          
                methods.forEach(method => {
                  if ((method.enabled == 'S') ) { 
                    if (applePayCodes.includes(method.paymentGateway)) {
                      if (this.platformService.isIos()) {
                        availables.push(method);
                      }
                    } else {
                      // let cashKsa: boolean = (environment.gateWays.checkout.client === 488 && method.id == 1)
                      if(method.paymentGateway!=walletCode && method.id != 16){ // Ocultar formas de pago
                        if( this.order.orderStatus == 'OnTheWay' && method.paymentGateway == 'N' || this.order.orderStatus == 'Arriving' && method.paymentGateway == 'N' ){
                        }else{
                          availables.push(method);
                        }
                      }  
                    }
                  }
                });
                this.paymentMethods = availables;
                this.paymentMethods = this.paymentMethods.sort((a, b) => (a.order < b.order) ? -1 : 1);

                this.centerDefPayCode(this.order);
                this.setTokensToGateway();

        },
        (err: any) => {
          console.log(err)
        }
      )
    }

  }
  isGroundr: boolean = isGroundr()
  isMaestro: boolean = isMaestro()
  @Output() pay = new EventEmitter();
  @Output() invoice = new EventEmitter()
  private unsubscribe = new Subject<void>()
  orientation: string;
  pathCard: string
  pathCredit: string
  paymentMethods = [];
  private lang: string
  public assets: string = environment.assetsUrl
  slideOpts: {};
  windowWidth: number
  classCustomRadio: string = "radioCustom";
  showCheckCustomRadio: boolean = false;
  classWalletRadio: string = "radioCustom";
  showCheckWalletRadio: boolean = false;
  // @ViewChild('slides') sliderElement: IonSlides;
  currentWallets: number = 0;
  size: number
  position: number
  selectedMethod: any;
  defaultPaymentCode: number = -1;
  isKiosk: boolean = kiosk
  kioskBranch: any
  disablePlaceOrder: boolean
  @Input() set disablePlace(value) {
    this.disablePlaceOrder = value
  }
  constructor(
    private readonly langService: LangService,
    private readonly _api: ApiService,
    public uds: UserDataService,
    private modalService: ModalService,
    private platformService: PlatformService
  ) { }

  ngOnDestroy(): void {
    // console.log('Destroyed')
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  // ngAfterViewInit() {
  //   if(!this.platformService.isServer()){
  //   this.sliderElement.length().then(
  //     (result) => {
  //       this.size = result;
  //     });
  //   }
  // }

  ngOnInit() {

    let pathImgGroundr = "";
    if(this.isGroundr) {
      pathImgGroundr = "groundr-";
    }

    this.pathCard = this.assets + "images/" + pathImgGroundr + "payWithCard.svg"
    this.pathCredit = this.assets + "images/" + pathImgGroundr + "payWithCredit.svg"

    if(!this.platformService.isServer()){
    this.windowWidth = window.innerWidth;
    // if (this.windowWidth > 767) {

    //   this.slideOpts = {
    //     speed: 3000,
    //     slidesPerView: 1.9,
    //      spaceBetween: 10,
    //     centeredSlides: true
    //   }

    // } else {
    //   this.slideOpts = {
    //     speed: 3000,
    //     slidesPerView: 1.1,
    //     centeredSlides: true
    //   }
    // }
  }

  const langConfig = this.langService.getLangConfig()
  this.orientation = langConfig.dir
  this.lang = langConfig.lang

    if(!this.platformService.isServer()){
    // this.platform.ready().then(
    //   () => {
    //     this.langService.onLangChange().pipe(
    //       takeUntil(this.unsubscribe)
    //     ).subscribe(
    //       (result: LangChangeEvent) => {
    //         this.sliderElement.update();
    //         if (result.lang == 'en') {
    //           this.orientation = 'ltr'
    //         } else {
    //           this.orientation = 'rtl'
    //         }
    //       }
    //     )
    //   }
    // )

      // if (this.isLoggedIn) {
      //   this.getCustomerWallet();
      // }
    }
      
  }

  public getCustomerWallet(){
    let that = this;
    let s = this._api.getCustomerWallet().subscribe(
      (wallets) => {
        s.unsubscribe()
        that.currentWallets = 0;
        wallets.forEach(function (value, index) {
          that.currentWallets += value["amount"];
        })
      })
  }

  setDefaultMethod() {

    if (!this.showCheckCustomRadio) {
      this.classCustomRadio = 'radioCustomSelected'
      this.uds.setDefaultPaymentMethod(this.selectedMethod.id);
      this.defaultPaymentCode = this.selectedMethod.id;
      this.showCheckCustomRadio = true;
    } else {
      this.classCustomRadio = 'radioCustom';
      this.showCheckCustomRadio = false;
    }
  }

  useWallets() {
    this.showCheckWalletRadio = !this.showCheckWalletRadio;
    if (this.showCheckWalletRadio) {
      this.classWalletRadio = 'radioCustomSelected'
    } else {
      this.classWalletRadio = 'radioCustom';
    }
    let isSelectedMethodDisabled = this.checkPaymentMethods(this.selectedMethod)
    if (isSelectedMethodDisabled) {
      this.selectedMethod = undefined
    }
    if (this.workingMethod !== 'default') {
      this.uds.setPaymentMethod(
        {
          method: this.selectedMethod,
          wallets: (this.currentWallets && this.classWalletRadio == 'radioCustomSelected') ? this.currentWallets : false
        }
      )
    }
  }

  checkPaymentMethods(paymentMethod: any) {
    let amount: number = (this.order.total + this.deliveryFee)
    let amountWallets: number = (this.showCheckWalletRadio && this.currentWallets > 0 && this.canUseWallets && this.classWalletRadio === 'radioCustomSelected') ? this.currentWallets : 0
    if (paymentMethod && paymentMethod.paymentGateway !== 'N' && (amount - amountWallets) <= 0) {
      return true
    }
    return false
  }

  // slideChanged(index) {
  //   if (index) {
  //     this.selectedMethod = this.paymentMethods[index];

  //     this.sliderElement.slideTo(index, 3000,false)
  //   } else {
  //     this.sliderElement.getActiveIndex().then(position => {
  //       this.selectedMethod = this.paymentMethods[position];
  //       if(this.selectedMethod.id != this.defaultPaymentCode){
  //         this.classCustomRadio = 'radioCustom'
  //         this.showCheckCustomRadio = false;
  //       }else{
  //         this.classCustomRadio = 'radioCustomSelected'
  //         this.showCheckCustomRadio = true;
  //       }
  //     });
  //   }
  // }

  selectMethod(index){
    this.selectedMethod = this.paymentMethods[index];
    console.log(this.selectedMethod)
    if(this.workingMethod!=='default'){
      this.uds.setPaymentMethod({method : this.selectedMethod,wallets:this.currentWallets && this.classWalletRadio== 'radioCustomSelected' ? this.currentWallets : false})
    }
  }

  // next() {
  //   this.sliderElement.slideNext()
  //   this.sliderElement.getActiveIndex().then(position => {
  //     this.selectedMethod = this.paymentMethods[position];
  //   });
  // }

  // prev() {
  //   this.sliderElement.slidePrev()
  //   this.sliderElement.getActiveIndex().then(position => {
  //     this.selectedMethod = this.paymentMethods[position];
  //   });
  // }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
 
    if (this.windowWidth > 767) {

      this.slideOpts = {
        speed: 3000,
        slidesPerView: 2.1,
        centeredSlides: true,
        spaceBetween: 0
      }

    } else {
      this.slideOpts = {
        speed: 3000,
        // autoplay: true,
        slidesPerView: 1.1,
        // spaceBetween: 10,
        centeredSlides: true
      }
    }
    // this.sliderElement.update();
  }

  public getSelectedMethodData(){
    return  {method : this.selectedMethod,wallets:this.currentWallets && this.classWalletRadio== 'radioCustomSelected' ? this.currentWallets : false};
  }

  public setTokensToGateway() {
    this.uds.getPaymentTokens().subscribe(
      (result: PaymentTokenInterface[]) => {
        let paymentTokens = result;
        this.paymentMethods.forEach(pM => {
          pM.paymentTokens = [];
        });
        paymentTokens.forEach(x => {
          this.paymentMethods.forEach(pM => {
            if (pM.paymentGateway == x.gateway) {
              pM.paymentTokens.push(x);
            }
          });
        });
        // this.selectedMethod = this.paymentMethods[0];

      }, (noTokensSaved) => {
        this.paymentMethods.forEach(pM => {
          pM.paymentTokens = [];
        });
        // this.selectedMethod = this.paymentMethods[0];

      }
    )
  }

  public showChooseCreditCard() {
    this.modalService.openModalChooseCreditCard(true, '', this.selectedMethod).then(result => {
      if (result?.data?.result) {
        let gateway = result.data.result.gateway;
        if (gateway == environment.gateWays.checkout.letter) {
          let type = result.data.result.type;
          let requireCvv = environment.gateWays.checkout.requireCvv;
          if (requireCvv.includes(type)) {
            let cvv = result.data.result.cvv;
            if (!cvv) {
              this.modalService.openModalWithCustomMsg('LANG_CVV_MUSTBE_ENTERED_ONTHIS_CREDITCARD');
              return;
            }
          }
        }
        this.selectedMethod.paymentTokenSelected = result.data.result;
        // console.log(this.selectedMethod);
      }
    },
      (err: any) => { }
    )
  }

  centerDefPayCode(order){

    return;
    this.uds.getDefaultPaymentMetod().subscribe(method=>{
      let index = 0;
      this.paymentMethods.forEach(tpm => {
        if(tpm.id == method){
          this.defaultPaymentCode = method;
          // this.sliderElement.slideTo(index, 0);
          this.classCustomRadio = 'radioCustomSelected'
          this.showCheckCustomRadio = true;
          this.selectedMethod = this.paymentMethods[index];
        }
        index++;
      });
    },
    no=>{
      //  this.sliderElement.slideTo(0, 0);
      this.selectedMethod = this.paymentMethods[0];
      console.log(order.paymentMethod)
      this.paymentMethods.forEach(payment  => {
        console.log(payment, order.paymentMethod)
        if(payment.id == order.paymentMethod){
          this.selectedMethod = payment;
        }
      })


    })

  }

  goToPay(value){
    this.pay.emit(value);
  }

  selectMethodKiosk(index: number): void {
    let pathImgGroundr = "";
    if(this.isGroundr) {
      pathImgGroundr = "groundr-";
    }
    if(index == 1) {
      this.pathCard = this.assets + "images/" + pathImgGroundr + "payWithCardSelect.svg"
      this.pathCredit = this.assets + "images/" + pathImgGroundr + "payWithCredit.svg"
     } else if(index == 0) {
       this.pathCredit = this.assets + "images/" + pathImgGroundr + "payWithCreditSelect.svg"
       this.pathCard = this.assets + "images/" + pathImgGroundr + "payWithCard.svg"
     } 
    if (!this.disablePlaceOrder) {
      this.selectedMethod = this.paymentMethods[index];
      if (this.selectedMethod.paymentGateway === environment.gateWays.invoice.tap.letter) {
        let invoiceMethod = JSON.parse(JSON.stringify(this.selectedMethod))
        let invoiceParams = null
        this.selectedMethod = this.paymentMethods.find(p => p.id == 1)
        this.uds.setPaymentMethod(
          {
            method: this.selectedMethod,
            wallets: false,
            invoiceMethod,
            invoiceParams
          }
        )
        this.invoice.emit(this.selectedMethod)
      } else {
        this.uds.setPaymentMethod({ method: this.selectedMethod, wallets: false })
        this.goToPay(this.selectedMethod)
      }
    }
  }

}

