import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { isGroundr, isMaestro } from '@core/functions';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-product-added',
  templateUrl: './product-added.component.html',
  styleUrls: ['./product-added.component.scss','./product-added.groundr.component.scss'],
})
export class ProductAddedComponent implements OnInit {

  constructor() { }

  isGroundr: boolean = isGroundr()
  isMaestro: boolean = isMaestro()
  assetsUrl: string = environment.assetsUrl
  @Input() cartTotal: number = 0
  @Input() isVisible: boolean = false

  ngOnInit() {}

}
