import { LegacySources, OrderTypes, OrderLineTypes } from './order';

export enum PromoDetailApplicableTo {
  family = 'F',
  group = 'G',
  multiple = 'M',
  saleItem = 'I',
  product = 'P',
}

export enum PromoDetailUnityDiscount {
  money = 'D',
  percentage = '%',
  fix = 'F',
}

export interface PromoLineInterface {
  applicable: PromoDetailApplicableTo;
  chargeLine: string;
  discountAmount: number;
  discountType: PromoDetailUnityDiscount;
  excludedItemsID: string;
  excludedToppingsID: string;
  includedItemsID: string;
  includedToppingsID: string;
  lineNbr: number;
  maxToppingsQtty: number;
  minToppingsQtty: number;
  saleItemID: string;
  saleItemID2: string;
  saleItemID3: string;
  webDescription: string;
  minItemsQtty?: number;
  maxItemsQtty?: number;
}

export interface PromoInterface {
  byPrice: string;
  chargeExtraToppings: string;
  chargeMoreExpensive: string;
  daysOfWeek: string;
  description: string;
  endingDate: string;
  endingTime1: string;
  endingTime2: string;
  familyGroupWebShow: number;
  hidden: string;
  holidayEve: string;
  holidays: string;
  id: string;
  lines: PromoLineInterface[];
  loyaltyPoints: number;
  manuallyAdded: string;
  message: string;
  minimumOrderAmount: number;
  minimumPromoCharge?: number;
  onePerCustomer: string;
  onlyFirstOrder?: string;
  onePerOrder: string;
  order: number;
  orderType: string;
  promoCodes: string;
  promoType: string;
  recurringPromo: number;
  reseted: string;
  salePrice: number;
  showWebHome: string;
  source: string;
  sourceName?: string;
  startingDate: string;
  startingTime1: string;
  startingTime2: string;
  validityDates: string;
  webDescription: string;
  wizard: string;
  hourlyRestriction: string;
  crossType: CrossSalesType;
  crossFindType: PromoDetailApplicableTo;
  crossFindCode: string;
  crossSuggestIn: CrossSalesSuggestIn;
  crossQuantityAsk: string;
  crossMultiple: string;
  crossShowAlways: string;
  crossValidDays: number;
  ts?: string;
  promoUserID?: string;
  webImageID?: string;
  webTag?: string;
  shortName?: string;
  showGroupFamily?: string;
  incompatiblePromotions?: string;
}

export interface ProductCalculatedResponse {
  total_num_ingredientes: number;
  total_precio_ingredientes: number;
  precio_venta: number;
  precio_base: number;
  calories: number;
  allergens: string;
  unitPrice: number;
  saleItemID: string;
  type: OrderLineTypes;
  errors: string[];
  toppings: Array<string[]> | Array<string>;
  full_toppings?: any[];
  pg_toppings?: string[];
  portions?: number;
  fee?: number;
  tax?: number;
  quantity?: number;
  sublines?: ProductCalculatedResponse[];
  customID?: number;
  points: number;
  price_discount?: number;
  amount_discount?: number;
  comments?: string;
  order?: number;
}

export interface PromotionCalculatedResponse {
  code?: string;
  id: string;
  saleItemID: string;
  sublines: ProductCalculatedResponse[];
  unitPrice: number;
  discount: number;
  type: OrderLineTypes;
  quantity?: number;
  customID?: number;
  promoClientID?: number;
  tax?: number;
  order?: number;
}

export interface CartCalculatedResponse {
  discarded: any[];
  complete: any[];
  promo: PromotionCalculatedResponse;
  errors: any[];
}

export interface IsPromoLineApplicable {
  product: boolean;
  include: boolean;
  toppings: boolean;
  exclude: boolean;
  include_topping: boolean;
  excluded_toppings: boolean;
  max_quantity: boolean;
  min_quantity: boolean;
}

export enum PromoTypes {
  Acumulable = 'acumulable',
  Descuento = 'descuento',
  Pack = 'pack',
  CrossSales = 'cross',
  Agregadores = 'agregador',
  Loyalty = 'loyalty',
  Multiple = 'múltiple',
  Delivery = 'delivery',
}

export enum CustomerLoyaltyStatus {
  White = 'white',
  Silver = 'silver',
  Gold = 'gold',
}

export interface MenuOptions {
  orderType?: OrderTypes;
  source?: LegacySources;
}
export interface PromoOptions extends MenuOptions {
  promoCode?: string;
}
export interface ProductOptions extends MenuOptions {
  checkOrderType?: boolean;
}

export enum CrossSalesType {
  Wallet = 'wallet', // (backend) Moneyback - importe en wallets sobre pedido o producto
  Cross = 'cross', //
  Delivery = 'delivery',
  Points = 'points',
  Xcross = 'xcross',
  Loyalty = 'loyalty',
  Comment = 'comment',
  Fee = 'fee',
}
export enum CrossSalesSuggestIn {
  Menu = 'M',
  Home = 'H',
  Cart = 'C',
}

export interface PromoUserInterface {
  ID: number;
  customerID: string;
  endingDate: string;
  pgCustomerID: number;
  phoneNumber: number;
  promoID: string;
  redeemed: string;
  startingDate: string;
  ts?: string;
}

export type CalculatedLinesResponse =
  | ProductCalculatedResponse
  | PromotionCalculatedResponse;
