import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuFamilyInterface } from '@lib/promo-engine';
import { PgCustomizer } from '@lib/utils';
import { CurrentItemsInterface } from '@pages/customizer/customizer.page';
import { environment } from '@environments/environment';
import { DeliveryService } from '@services/delivery.service';
import { StorageService } from '@services/storage.service';
import { kiosk } from '@core/constants';

@Component({
  selector: 'app-sizes',
  templateUrl: './sizes.component.html',
  styleUrls: ['./sizes.component.scss'],
})
export class SizesComponent implements OnInit {
  @Input() set currentData(value: CurrentItemsInterface) {
    if (value && value.family && value.menu && value.product) {
      let update: boolean = (value.forceReload) ? true : ((!this.current.family || !this.current.product) || (this.current.family.id !== value.family.id) || (this.current.product.id !== value.product.id))
      if (update) {
        this.current = this.pgCustomizer.copy(value)
        this.setSizes()
      }
      this.current.forceReload = false
    }
  }
  @Output() onFamilyChange: EventEmitter<CurrentItemsInterface> = new EventEmitter()
  current: CurrentItemsInterface
  currentSize: number
  pgCustomizer: PgCustomizer
  productsUrl: string = environment.productsUrl
  sizes: MenuFamilyInterface[]
  isHalfNdHalf: boolean
  assets: string = environment.assetsUrl;
  isKiosk: boolean = kiosk
  kioskBranch: any
  isPizza: boolean
  constructor(private readonly deliveryService: DeliveryService, private readonly storageService: StorageService) {
    this.current = {
      currentSide: null,
      dough: null,
      doughs: null,
      family: null,
      forceReload: false,
      forceUpdate: false,
      menu: null,
      product: null,
      promoEngineOptions: null,
      promoLine: null,
      selectionMode: null,
      stockProducts: null,
      stockToppings: null,
      recipeToppings: null,
      toppings: null
    }
    this.pgCustomizer = new PgCustomizer(null, null, {
      families: [],
      majorGroups: [],
      toppingGroups: []
    }, this.productsUrl)

  }
  ngOnInit(): void {
    this.isHalfNdHalf = this.pgCustomizer.checkProductCustom()
  }
  changeFamily(f: MenuFamilyInterface): void {
    this.pgCustomizer.changeFamily(f.id)
    this.current.family = this.pgCustomizer.getCurrentFamily()
    this.current.product = this.pgCustomizer.getCurrentProduct()
    this.onFamilyChange.emit(this.current)
  }
  setSizes(): void {
    this.pgCustomizer = new PgCustomizer(this.current.family, this.current.product, this.current.menu, this.productsUrl, this.current.stockToppings)
   console.log(this.pgCustomizer )
    if (this.current.forceUpdate === true) {
      this.pgCustomizer.updateDoughs(this.current.doughs)
      this.pgCustomizer.updateRecipeToppings(this.current.recipeToppings)
      this.pgCustomizer.updateToppings(this.current.toppings)
      this.current.forceUpdate = false
    }
    // this.pgCustomizer.setDoughsByDefault()
    this.isPizza = this.pgCustomizer.isPizza()
    this.sizes = this.pgCustomizer.getAvailableFamilies()
  }
}
