import { APP_INITIALIZER, Injector, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { environment } from '@environments/environment';
import { SharedModule } from '@core/shared.module';
import { WebpackTranslateLoader } from '@services/webpack-translate-loader';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FirebaseAuthService } from '@services/firebase-auth.service';

import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";

import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Appsflyer } from "@ionic-native/appsflyer/ngx";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { ApiTokenInterceptor } from './core/interceptors/api-token.interceptor';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AppConfigService } from '@services/app-config.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ApplePay } from '@ionic-native/apple-pay/ngx';
import { PreviousRouteService } from '@services/previous-route.service';
import { isPlatformServer } from '@angular/common';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { DatePipe } from '@angular/common';
import { AnimationController } from '@ionic/angular';

const fadeInAnimation = (baseEl: HTMLElement, opts?: any) => {
  const animationCtrl = new AnimationController();
  return animationCtrl.create()
    .addElement(opts.enteringEl)
    .duration(200)        // Duración de la animación (200 ms)
    .fromTo('opacity', '0', '1');  // Cambia la opacidad de 0 (invisible) a 1 (visible)
};

const fadeOutAnimation = (baseEl: HTMLElement, opts?: any) => {
  const animationCtrl = new AnimationController();
  return animationCtrl.create()
    .addElement(opts.leavingEl)
    .duration(200)        // Duración de la animación (200 ms)
    .fromTo('opacity', '1', '0');  // Cambia la opacidad de 1 (visible) a 0 (invisible)
};

/**
 * Factory method to override the console methods
 * during app initialization
 */
function silenceConsoleMethods(injector: Injector) {
  return () => {
    const platformId = injector.get(PLATFORM_ID);
    if (environment.production) {
       console.log = console.debug = console.warn = console.error = () => { };
    }
  };
}

export function appInitOrder(appConfigService: AppConfigService) {
  return async () => {
    return new Promise(
      (resolve, reject) => {
        appConfigService.initializeOrder().then(
          (result) => {
            resolve(true)
          },
          (error) => {
            resolve(true)
          }
        )
      }
    )
  }
}

export function appInitWebSocket(appConfigService: AppConfigService) {
  return async () => {
    return new Promise(
      resolve => {
        appConfigService.connectNdGetWebSocketMessages().subscribe(
          result => {

          },
          (err: any) => {
          }
        )
        resolve(true)
      }
    )
  }
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [

    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    IonicModule.forRoot({
      mode: 'md',                   
      animated: false,  
      swipeBackEnabled: false,
      navAnimation: fadeInAnimation 
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    IonicStorageModule.forRoot({
      name: 'maindb',
      // driverOrder: ['sqlite', 'indexeddb', 'localstorage'],
      driverOrder: ['localstorage', 'indexeddb'],
      storeName: 'maestro'
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: silenceConsoleMethods,
      deps: [Injector],
      multi: true
    },
    StatusBar,
    SplashScreen,
    FirebaseAuthService,
    SignInWithApple,
    FirebaseAnalytics,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Geolocation,
    AndroidPermissions,
    LocationAccuracy,
    Diagnostic,
    Appsflyer,
    SocialSharing,
    FingerprintAIO,
    ScreenOrientation,
    AppConfigService,
    InAppBrowser,
    OpenNativeSettings,
    ApplePay,
    PreviousRouteService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitOrder,
      multi: true,
      deps: [
        AppConfigService
      ]
    }/*,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitWebSocket,
      multi: true,
      deps: [
        AppConfigService
      ]
    }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
