import { Component, HostListener, OnDestroy, OnInit, NgZone, ViewChild, AfterViewInit, EventEmitter, Input } from '@angular/core';
import { IonSlides, MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DefaultLangChangeEvent, LangChangeEvent } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LangConfigInterface } from '@app/app.interface';
import { branchByDefault, RoutesMenu, StorageVariables, kiosk } from '@core/constants';
import { LangService } from '@services/lang.service';
import { DomService } from '@services/dom.service';
import { environment } from '@environments/environment';
import { PlatformService } from '@services/platform.service';
import { ApiService } from '@services/api.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { StorageService } from '@services/storage.service'
import { PushnotifService } from '@services/pushnotif.service';
import { UserDataService } from '@services/user-data.service';
import { Plugins } from '@capacitor/core';
const { myCustomPlugin } = Plugins;
import { FaeventsService } from '@services/faevents.service'
import { SwupdateService } from '@services/swupdate.service';
import { ObservableType, RxjsService } from '@services/rxjs.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { MenuInterface, MenuOutOfStock, PromoInterface } from '@lib/promo-engine';
import { CreateOrderInterface, PgMenu, PgOrder, PgOrderLinesType, PgOrderPromoLine } from '@lib/utils'; //Cart Implementations
import { DeliveryService } from '@services/delivery.service';
import { PreviousRouteService } from '@services/previous-route.service';
import { DataService } from '@services/data.service';
import { UtilsService } from '@services/utils.service';
import { ModalService } from '@services/modal.service';
import { LoaderService } from '@services/loader.service';
import { BranchesService } from '@services/branches.service';
import { CustomBrowserService } from '@services/custom-browser.service';
import { KioskService } from '@services/kiosk.service';
import { AddInsiderItemInterface } from '@core/interfaces/services';
import { isGroundr, isMaestro } from '@core/functions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss','app.groundr.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  //Inactividad
  public showInactivityScreen: boolean = false;
  public countdown: number = 30; //Contador inactividad
  private inactivityTime: number = 30000; // Tiempo de inactividad en ms (30 segundos)
  private timeoutId: any;
  private warningTimeoutId: any;

  langConfig: LangConfigInterface = {
    dir: 'ltr',
    lang: 'en',
    side: 'end'
  }

  isGroundr: boolean = isGroundr()
  isMaestro: boolean = isMaestro()
  private unsubscribe = new Subject<void>();
  routesList = RoutesMenu
  windowWidth: number;
  assets: string = environment.assetsUrl;
  showLogin: boolean;
  user: any;
  points: number
  userPromotions: PromoInterface[]
  haveUserPromotions: boolean = false
  updateSliderPromos: boolean = false;
  showDetailPromo: boolean = false;
  isDesktop: boolean
  totalUnreaded: number = 0;
  onUpdateNotifications: any;
  totalItems: number = 0;
  isIos: boolean;
  imgLanguage: string
  showScreenSaver: any
  productsUrl: string = environment.productsUrl
  orientation: string;
  @ViewChild('slides') sliderElement: IonSlides;
  isKiosk: boolean = kiosk
  kioskBranch: any
  slideOpts = {
    speed: 2000,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false
    },
    slidesPerView: 1.15,
    spaceBetween: 10,
    centeredSlides: true,
    coverflowEffect: {
      rotate: 30,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },
    on: {
      beforeInit() {
        const swiper = this;

        swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

        swiper.params.watchSlidesProgress = true;
        swiper.originalParams.watchSlidesProgress = true;
      },
      setTranslate() {
        const swiper = this;
        const {
          width: swiperWidth, height: swiperHeight, slides, $wrapperEl, slidesSizesGrid, $
        } = swiper;
        const params = swiper.params.coverflowEffect;
        const isHorizontal = swiper.isHorizontal();
        const transform$$1 = swiper.translate;
        const center = isHorizontal ? -transform$$1 + (swiperWidth / 2) : -transform$$1 + (swiperHeight / 2);
        const rotate = isHorizontal ? params.rotate : -params.rotate;
        const translate = params.depth;
        // Each slide offset from center
        for (let i = 0, length = slides.length; i < length; i += 1) {
          const $slideEl = slides.eq(i);
          const slideSize = slidesSizesGrid[i];
          const slideOffset = $slideEl[0].swiperSlideOffset;
          const offsetMultiplier = ((center - slideOffset - (slideSize / 2)) / slideSize) * params.modifier;

          let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
          let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
          // var rotateZ = 0
          let translateZ = -translate * Math.abs(offsetMultiplier);

          let translateY = isHorizontal ? 0 : params.stretch * (offsetMultiplier);
          let translateX = isHorizontal ? params.stretch * (offsetMultiplier) : 0;

          // Fix for ultra small values
          if (Math.abs(translateX) < 0.001) translateX = 0;
          if (Math.abs(translateY) < 0.001) translateY = 0;
          if (Math.abs(translateZ) < 0.001) translateZ = 0;
          if (Math.abs(rotateY) < 0.001) rotateY = 0;
          if (Math.abs(rotateX) < 0.001) rotateX = 0;

          const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

          $slideEl.transform(slideTransform);
          $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
          if (params.slideShadows) {
            // Set shadows
            let $shadowBeforeEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
            let $shadowAfterEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
            if ($shadowBeforeEl.length === 0) {
              $shadowBeforeEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`);
              $slideEl.append($shadowBeforeEl);
            }
            if ($shadowAfterEl.length === 0) {
              $shadowAfterEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`);
              $slideEl.append($shadowAfterEl);
            }
            if ($shadowBeforeEl.length) $shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
            if ($shadowAfterEl.length) $shadowAfterEl[0].style.opacity = (-offsetMultiplier) > 0 ? -offsetMultiplier : 0;
          }
        }

        // Set correct perspective for IE10
        if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
          const ws = $wrapperEl[0].style;
          ws.perspectiveOrigin = `${center}px 50%`;
        }
      },
      setTransition(duration) {
        const swiper = this;
        swiper.slides
          .transition(duration)
          .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
          .transition(duration);
      }
    }

  }

  insiderItems: AddInsiderItemInterface[] = []
  isAppReady: boolean = false
  isAppReadyEmitter = new EventEmitter<boolean>()
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public menuCtrl: MenuController,
    public langService: LangService,
    private domService: DomService,
    private platformService: PlatformService,
    private readonly _api: ApiService,
    public readonly _router: Router,
    public angularFireAuth: AngularFireAuth,
    private notificationsPush: PushnotifService,
    private faevents: FaeventsService,
    private userDataService: UserDataService,
    private ngZone: NgZone,
    private readonly swupdate: SwupdateService,
    private readonly rxJsService: RxjsService,
    private readonly storageService: StorageService,
    private screenOrientation: ScreenOrientation,
    private nds: DeliveryService,
    private prs: PreviousRouteService,
    private utils: UtilsService,
    private readonly dataService: DataService,
    private readonly modalService: ModalService,
    private loader: LoaderService,
    private readonly branchesService: BranchesService,
    private cbs: CustomBrowserService,
    private kioskService: KioskService,
    private readonly router: Router,
    public uds: UserDataService,
  ) {

    if (!this.platformService.isServer()) {
      // this.domService.nativeWindow.addItemFromInsider = this.addItemFromInsider.bind(this)
      this.isDesktop = this.platformService.isWeb()
      if (!this.platformService.isWeb()) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }

      //   this.faevents.enableAppsFlyer(true);
      //   this.faevents.enableAnalytics(true);

      this._router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          //       this.faevents.pageView(event);
        }
      });
    }
    this.platform.ready().then(
      () => {
        if (!this.platformService.isWeb() && !this.platformService.isServer()) {
          this.statusBar.styleDefault()
          this.splashScreen.hide()
        }
        this.swupdate.checkForUpdates();
      }
    )
    this.langService.onDefaultLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: DefaultLangChangeEvent) => {
        this.updateConfig()
      }
    )
    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {
        this.nds.branchPickup = false;
        this.branchesService.branches = [];
        this.imgLanguage = result.lang + '.svg'
        this.updateConfig()
        this.initializeMenu(true, true)
      }
    )
  }
  ngAfterViewInit(): void {
    if (this.platformService.isWeb() && !this.platformService.isServer()) {
      let refLoader = this.loader;
      var printToConsoleBtn = document.querySelector("#allcontent");
      printToConsoleBtn.addEventListener('touchstart', (e) => {
        if (refLoader.getStatus()) {
          e.preventDefault();
        }
      });
    }
  }

  ngOnInit(): void {
    this.initializeApp()
    if (!this.platformService.isServer()) {
      this.windowWidth = window.innerWidth;
      if (!this.platformService.isWeb()) {

        this.setTotalUnread();
        this.listenerUpdateNotifications();
        this.notificationsPush.requestNotifPermission();

        myCustomPlugin.addListener('deepLinkOpen', (data: { url: string }) => {

          const params = new URLSearchParams(data.url)

          let oobCode = params.get('oobCode') ? params.get('oobCode') : false;
          let mode = params.get('mode') ? params.get('mode') : false;

          if (oobCode && mode && mode == 'signIn' && this.angularFireAuth.isSignInWithEmailLink(data.url)) { // validation email
            this.ngZone.run(() => {
              this._router.navigate(['/login'], { queryParams: { oobCode: oobCode, mode: mode } })
            });
          } else {
            let deep_link_id = this.utils.getParameterByName('deep_link_id', data.url);
            if (deep_link_id) {
              if (deep_link_id.startsWith('https://maestropizza.com/referal/')) {  //iOS, when app is not installed and got to appStore to install it. 
                this.storageService.setItem(StorageVariables.referalToapply, deep_link_id);
              }
            } else {
              let deep_link_id = data.url;
              if (deep_link_id && deep_link_id != null && deep_link_id != '') { //iOS when app is installed or Android when app is installed or not
                if (deep_link_id.startsWith('https://maestropizza.com/referal/')) {
                  this.storageService.setItem(StorageVariables.referalToapply, deep_link_id);
                }
              }
            }
          }
        })
      }
      this.isIos = this.platformService.isIos()
      this.checkLangConfiguration()
      this.setIsLoged();
      this.userDataService.notifyUserStatus();
      this.nds.onUpdateBranches().pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
        async result => {
          if (result && 'delivery' in result && 'pickup' in result/* && result.delivery && result.pickup*/) {
            if (result.evtName2) {
              this.faevents.preLogEvent(result.evtName2, {})
            }
            if (result.evtName) {
              this.faevents.preLogEvent(result.evtName, {})
            }
            try {
              let order = await this.dataService.getOrder()
              let currentBranchDeliveryID: number = (result.delivery && 'branch' in result.delivery && 'id' in result.delivery.branch) ? result.delivery.branch.id : branchByDefault
              let currentBranchPickUpID: number = (result.pickup && 'id' in result.pickup) ? result.pickup.id : branchByDefault
              let updateOrder: boolean = false
              if (order.branchDeliveryID !== currentBranchDeliveryID) {
                order.branchDeliveryID = currentBranchDeliveryID
                updateOrder = true
              }
              if (order.branchPickUpID !== currentBranchPickUpID) {
                updateOrder = true
                order.branchPickUpID = currentBranchPickUpID
              }
              if (this.isKiosk) {
                this.kioskBranch = currentBranchPickUpID
              }
              if (updateOrder) {
                await this.storageService.setItem(StorageVariables.order, order).toPromise()
                // console.log('On Update Branches')
                this._api.setData('onUpdateBranchesReady')
                this.initializeMenu(true)
              } else {
                this._api.setData('onUpdateBranchesReady')
                this.initializeMenu(true)
              }
            } catch (e) {
              this._api.setData('onUpdateBranchesReady')
              this.initializeMenu(true)
            }
          }
        }
      )
    }

    this.nds.getScreenValue.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(value => {
      this.showScreenSaver = value;

      if (!this.showScreenSaver) {
        setTimeout(() => {
          this._api.setDataForms({
            function: "closeKeyBoard",
          }
          )

          this.nds.setScreenSaver(true)
        }, 750000);
      }

    });

  }

  ngOnDestroy(): void {
    this.stopMonitoring();
    // console.log('Destroyed')
    if (!this.platformService.isServer()) {
      this.unsubscribe.next()
      this.unsubscribe.complete()
      this.onUpdateNotifications.unsubscribe();
    }
  }

  // Insider
  addItemFromInsider(event: AddInsiderItemInterface): Promise<boolean> {
    return new Promise(
      async (resolve, reject) => {
        if (event && typeof event === 'object' && 'eventType' in event && 'saleItemID' in event) {
          this.insiderItems.push(event)
          if (!this.isAppReady) {
            await this.rxJsService.stopObservablePropagation(this.isAppReadyEmitter).toPromise()
          }
          if (this.isAppReady) {
            const that: this = this
            const promises: Promise<boolean>[] = this.insiderItems.filter(
              event => event.eventType === 'add_product' || event.eventType === 'add_promo'
            ).map(
              event => event.eventType === 'add_promo' ? that.dataService.addPromotionInsider(event.saleItemID) : that.dataService.addProductInsider(event.saleItemID)
            )
            if (promises.length > 0) {
              Promise.all(promises).then(
                result => {
                  this.insiderItems = []
                  this.ngZone.run(
                    () => {
                      this._api.setData('showCart')
                      if (!this.platformService.isDesktop()) {
                        this._router.navigate(['/cart'])
                      }
                    }
                  )
                  resolve(true)
                },
                error => {
                  this.insiderItems = []
                  reject(false)
                }
              )
            } else {
              reject(false)
            }
          } else {
            reject(false)
          }
        } else {
          reject(false)
        }
      }
    )
  }

  changeLanguage(): void {
    this.menuCtrl.close().then(
      () => {
        this.langService.changeLanguage()
      }
    )
  }

  checkLangConfiguration(): void { }

  initializeApp(): void {
    if (!this.platformService.isServer()) {
      this.updateConfig()
      if (this.isKiosk) {
        this.storageService.getItem(StorageVariables.kioskBranch).subscribe(kioskBranch => {
          this.nds.setKioskBranch(kioskBranch)
          this.init_B_PDZ(false, false)
        },
          error => {
            console.log(error)
          })
      } else {
        this.init_B_PDZ(false, true);
      }
    }
  }

  updateConfig(): void {
    if (this.platformService.isBrowser()) {
      this.langConfig = this.langService.getLangConfig()
      this.imgLanguage = this.langConfig.lang + '.svg'
      // console.log(this.langConfig)
      this.domService.setDirection(this.langConfig.dir)
      this.menuCtrl.enable(true, 'menu' + this.langConfig.dir)
      this.storageService.setItem(StorageVariables.lang, this.langConfig.lang).subscribe()
      this.orientation = this.langConfig.dir
    }
  }

  async initializeMenu(forceUpdate: boolean, fromUpdateLangEvent: boolean = false): Promise<void> {
    let requestList: ObservableType<any>[] = []
    if (this.isKiosk) {
      let kioskBranch: number = this.kioskBranch ? this.kioskBranch : branchByDefault
      let menuOutOfStock: MenuOutOfStock = null
      try {
        menuOutOfStock = await this._api.getOutOfStock(kioskBranch).toPromise()
      } catch (e) { }
      requestList = [
        this.rxJsService.createObservableFromPromise(this.dataService.getMenu(kioskBranch, true)),
        this.rxJsService.createObservableFromPromise(this.dataService.getPromotions(kioskBranch, true)),
        this.storageService.setItem(StorageVariables.menuOutOfStock, menuOutOfStock)
      ]
    } else if (forceUpdate) {
      console.log("force")
      try {
        const order: CreateOrderInterface = await this.dataService.getOrder()
        requestList = [
          this.rxJsService.createObservableFromPromise(this.dataService.getMenu(order.branchPickUpID, forceUpdate)),
          this.rxJsService.createObservableFromPromise(this.dataService.getPromotions(order.branchPickUpID, forceUpdate))
        ]
      } catch (e) {
        requestList = [
          this.rxJsService.createObservableFromPromise(this.dataService.getMenu()),
          this.rxJsService.createObservableFromPromise(this.dataService.getPromotions())
        ]
      }
    } else {
      console.log("else force")
      requestList = [
        this.rxJsService.createObservableFromPromise(this.dataService.getMenu()),
        this.rxJsService.createObservableFromPromise(this.dataService.getPromotions())
      ]
    }
    this.rxJsService.createObservableFromMultipleRequests(requestList).subscribe(
      (result: [MenuInterface, PromoInterface[]]) => {
        this._api.setData(fromUpdateLangEvent ? 'OnUpdateLangEvent' : 'OnUpdateData')
        if (!fromUpdateLangEvent) {
          this.isAppReady = true
          this.isAppReadyEmitter.emit(this.isAppReady)
        }
      }
    )
  }

  goTo(page) {
    // console.log(this.windowWidth);
    if (page !== '/cart') {
      this._router.navigate([page]);
    } else {
      if (this.windowWidth <= 576) {
        this._router.navigate(['/cart'])
      } else {
        this.showCart()
      }
    }

  }

  goToEditProfile() {
    this._api.setData('goToEditProfile')
    this.menuCtrl.close()
    this._router.navigate(['/account'])
  }


  showCart() {
    this._api.setData("showCart");
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (!this.platformService.isServer()) {
      this.windowWidth = window.innerWidth;
    }
  }

  setIsLoged(): void {
    this.userDataService.getIsLoged().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe( // detectar los cambios
      (user) => {
        //  alert("getIsLoged");
        this.points = 0;
        this.showLogin = !user;
        if (!this.showLogin) {
          // console.log(user);
          this.userDataService.isLoged = true;
          this.user = JSON.parse(user);

          if (this.user.name.length > 0) {
            this.points = this.points + 1;
          }

          if (this.user.birthDate != null) {
            this.points = this.points + 1;
          }


          if (this.user.hasOwnProperty('profiles')) {
            this.user.profiles.forEach(
              value => {
                // console.log(value.source)
                switch (value.source) {
                  case 'phone':
                    this.user.phone = value.uid;
                    break
                  case 'email':
                    if (value.validated == 'S') {
                      this.points = this.points + 1;
                    }
                    break
                }
              })
          }

          this.getUserPromotions()

        } else {
          this.userDataService.isLoged = false;
        }

      }, error => {
        this.userDataService.isLoged = false;
        console.log(error);
      });


  }

  logout() {
    this.userDataService.logout();
    this._router.navigate(['/login'])
  }

  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.assets + "images/productnotfound.png") {
        event.target.src = this.assets + "images/promonotfound.png"
      }
    }
  }

  menuOpened() {
    if (!this.platformService.isServer()) {
      this.updateSliderPromos = true;
      this.getUserPromotions()
      setTimeout(() => {
        this.updateSliderPromos = false;
        this.setTotalUnread();
        this.setTotalItems();
      }, 100);
    }
  }

  showPromoDetails() {
    this.showDetailPromo = true;
    this.menuCtrl.close()
  }

  closePromoDetails() {
    this.showDetailPromo = false;
  }


  addSelectedPromotion(promo: PromoInterface): void {
    this.dataService.addPromotionToOrder(promo).then(
      () => {
        this.dataService.setTypeLineAdded("Promo")
        this.closePromoDetails()
        this.getUserPromotions()
        this.goTo("/cart")
      },
      async (errors: any[]) => {
        let errorMsg: string = await this.langService.getCustomMsgFromPromoEngineResult(errors)
        await this.modalService.openModalWithCustomMsg(errorMsg)
      }
    )
  }

  private setTotalItems() {
    this.totalItems = 0;
    this.storageService.getItem(StorageVariables.order).subscribe(order => {
      let linesItems = order.lineItems;
      let promoItems = order.promoLineItems;
      if (linesItems) {
        linesItems.forEach(
          value => {
            this.totalItems += value.quantity
          }
        )
      }
      if (promoItems) {
        promoItems.forEach(
          value => {
            this.totalItems += value.quantity
          }
        )
      }

    }, error => { })
  }
  private setTotalUnread() {
    this.storageService.getItem(StorageVariables.push).subscribe(push => {
      let c = 0;
      push.forEach(element => {
        if (element.unreaded) {
          c++;
        }
      });
      this.ngZone.run(() => {
        this.totalUnreaded = c;
      })

    }, noPush => {
      this.ngZone.run(() => {
        this.totalUnreaded = 0;
      })
    });
  }

  private listenerUpdateNotifications() {
    let that = this;
    this.onUpdateNotifications = this.notificationsPush.onUpdateNotifications().subscribe(
      notifications => { that.setTotalUnread(); });
  }

  private init_B_PDZ(fromGetPosition: boolean, locateMe: boolean) {
    this.storageService.getItem(StorageVariables.allowLocation).subscribe(res => {
      this.nds.init_B_PDZ(fromGetPosition, locateMe);
    }, error => {
      if (!(this.prs.getCurrentUrl() == '/home' || this.prs.getCurrentUrl() == '/')) {
        this.nds.init_B_PDZ(fromGetPosition, locateMe);
      }
    })
  }



  getUserPromotions() {
    this.rxJsService.createObservableFromMultipleRequests([
      this.rxJsService.createObservableFromPromise(this.dataService.getMenu()),
      this.rxJsService.createObservableFromPromise(this.dataService.getOrder()),
      this.rxJsService.createObservableFromPromise(this.dataService.getPromotions())
    ]).subscribe(
      async (result: [MenuInterface, CreateOrderInterface, PromoInterface[]]) => {
        const menu: MenuInterface = result[0]
        const order: CreateOrderInterface = result[1]
        const promotions: PromoInterface[] = result[2]
        let availablePromos = await this.userDataService.getPromoUserAvailables(menu, order, promotions);
        this.userPromotions = availablePromos
        console.log(this.userPromotions)

        if (this.userPromotions.length > 0) {
          this.haveUserPromotions = true
        } else {
          this.haveUserPromotions = false
        }

      })
  }
  updateCartCounter(): void {
    if (this._router.url == '/cart') {
      this._api.setData({})
    }
  }

  async hideScreenSaver() {
    this.storageService.setItem(StorageVariables.order, await this.dataService.getEmptyOrder()).subscribe(res => {
      this.showCart()
      this.nds.setScreenSaver(false);
      this.showScreenSaver = false;
      this.startMonitoring(); //Una vez eliminada el screenSaver empieza el conteo
      this.storageService.getItem(StorageVariables.kioskBranch).subscribe(res => {
        this.nds.init_B_PDZ(false, false)
        this._router.navigate(['/menu'])
      }, error => {
        this._router.navigate(['/login'])
      })
    }, error => { }
    )
  }

  // Detiene el monitoreo de inactividad
  private startMonitoring(): void {
    if (!this.showScreenSaver) {
      this.setupEventListeners(true);
      this.resetTimer();
    }
  }

  // Configura los escuchadores de eventos
  private stopMonitoring(): void {
    this.setupEventListeners(false);
    this.clearTimeouts();
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    // console.log('Mouse position:', event.clientX, event.clientY);
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    // console.log('Window scrolled');
  }
  
  //Listener para los eventos
  private setupEventListeners(shouldAdd: boolean): void {
    const method = shouldAdd ? 'addEventListener' : 'removeEventListener';
    window[method]('mousemove', this.resetTimer.bind(this));
    window[method]('keypress', this.resetTimer.bind(this));
    window[method]('click', this.resetTimer.bind(this));
    window[method]('scroll', this.resetTimer.bind(this));
  }

  // Reinicia el temporizador de inactividad
  private resetTimer(): void {
    if (this.showScreenSaver || this.showInactivityScreen) {
      return; // No hacer nada si el screensaver está activo
    }
    this.clearTimeouts();
    this.timeoutId = setTimeout(() => {
      this.ngZone.run(() => {
        if(this.router.url.includes('paymentvalidation')){
          window.location.reload();
        }else{
          this.showInactivityScreen = true;
          this.startWarningCountdown();
        }

      });
    }, this.inactivityTime);
  }

  //Empieza el segundo contador
  private startWarningCountdown(): void {
    let timeLeft = this.countdown;
    this.warningTimeoutId = setInterval(() => {
      timeLeft--;
      if (timeLeft > 0) {
        this.ngZone.run(() => {
          this.countdown = timeLeft;
        });
      } else {
        this.ngZone.run(() => {
          this.clearWarningTimeout();
          this.handleInactivityTimeout();
        });
      }
    }, 1000);
  }

  //Limpia los timeouts
  private clearTimeouts(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.clearWarningTimeout();
  }
  private clearWarningTimeout(): void {
    if (this.warningTimeoutId) {
      clearInterval(this.warningTimeoutId);
    }
  }

  //Reinicia la app tras 30s después de la pantal
  private handleInactivityTimeout(): void {
    window.location.reload();
  }

  public resetInactivity(): void {
    this.showInactivityScreen = false;
    this.countdown = 30;
    this.resetTimer();
  }

  //Botón Resume
  public resumeActivity(): void {
    this.resetInactivity();
  }

  //Botón Cancel
  async cancelOrder(){
    this.resetInactivity();
    this.resetTimer();
    this.storageService.removeItem(StorageVariables.userSelectBranch);
    this.storageService.setItem(StorageVariables.order, await this.dataService.getEmptyOrder());
    this.storageService.removeItem(StorageVariables.payWithWallet);
    this.uds.setPaymentMethod(false)
    this._api.setData("showCart");
    //this.router.navigate(['/menu']);
    this.showInactivityScreen = false;
    //por defecto mostrara el screensaver y volvera al menu en su estado inicial (showCartSummary = false)
    window.location.reload();
  }

}
