import { Component, OnInit, EventEmitter, Output, Input, HostListener, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  CounterInterface,
  CreateOrderInterface,
  CreateOrderLineItemsInterface,
  CreateOrderPromotionLineItemsInterface,
  MenuGroupInterface,
  MenuSubGroupInterface,
  PgCommon
} from '@lib/utils';
import { PlatformService } from '@services/platform.service';
import { DataService } from '@services/data.service';
import { FormMetadata } from '@core/modules/angular-ionic-forms/common/metadata';
import { FormMetadataFieldOptionDataInterface } from '@core/modules/angular-ionic-forms/common/interfaces';
import { environment } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { MenuFamilyInterface, MenuFamilyProductInterface, MenuFamilyToppingInterface, PromoInterface } from '@lib/promo-engine';
import { CustomizerSelectedDataInterface } from '@core/interfaces/services';
import { ShowCustomizerEvent } from '@core/components/menu-list/menu-list.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LangService } from '@services/lang.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { FaeventsService } from '@services/faevents.service'
import { filter } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
})
export class SubmenuComponent implements OnInit, OnDestroy {
  @Input() set checkCart(value: boolean) {
    this.checkCartQty = value ? value : false
    if (this.checkCartQty) {
      this.fillCart()
    }
  }
  @Input() set position(value: number) {
    if (value !== undefined) {
      if (this.windowWidth <= 575) {
        this.headerPosition = (275 - value) > 44 ? (275 - value) : 44
      } else {
        this.headerPosition = (345 - value) > 44 ? (365 - value) : 64
      }
    }
  }
  @Input() set currentGroupID(value: number) {
    if (value !== null && !isNaN(value)) {
      const pgCommon: PgCommon = new PgCommon()
      this.currentGroup = value
      const groupPosition: number = pgCommon.findIndexInArray(this.groups, 'id', this.currentGroup)
      if (groupPosition >= 0) {
        const subgroup: MenuSubGroupInterface = pgCommon.copy(this.groups[groupPosition].subGroups[0])
        this.currentSubGroup = subgroup.id
        this.evt();
      }
    }
  }
  @Input() set currentSubGroupID(value: number) {
    if (value !== null && !isNaN(value)) {
      this.currentSubGroup = value
      this.evt();
    }
  }
  @Input() set majorGroups(value: MenuGroupInterface[]) {
    this.groups = Array.isArray(value) ? value : []
    if (this.groups.length > 0) {
      if (!this.currentGroup) {
        this.currentGroup = this.groups[0].id
      }
      if (!this.currentSubGroup) {
        this.currentSubGroup = this.groups[0].subGroups[0].id
      }
      this.updateFormMetadata()
    }
  }
  @Input() showToolbar: boolean
  @Output() addPromotion = new EventEmitter<PromoInterface>()
  @Output() closeCart = new EventEmitter()
  @Output() formResults = new EventEmitter<FormMetadataFieldOptionDataInterface[]>()
  @Output() onMenuChange: EventEmitter<string> = new EventEmitter()
  @Output() showCustomizer = new EventEmitter<ShowCustomizerEvent>()
  assets: string = environment.assetsUrl
  headerPosition: number
  checkCartQty: boolean
  currentGroup: number
  currentPlatform: string
  currentSubGroup: number
  groups: MenuGroupInterface[]
  productsCounter: number
  windowWidth: number
  @Input() fixedPosition
  formMetadata: FormMetadata = null
  public pgCommon: PgCommon = new PgCommon()
  showForm: boolean = false
  searchBarResults: FormMetadataFieldOptionDataInterface[] = null
  searchBarResultsWithoutFilters: FormMetadataFieldOptionDataInterface[] = null
  allergensList: FormMetadataFieldOptionDataInterface[] = [
    {
      label: '0',
      value: 'LANG_LUPIN'
    },
    {
      label: '1',
      value: 'LANG_CELERY'
    },
    {
      label: '2',
      value: 'LANG_PEANUTS'
    },
    {
      label: '3',
      value: 'LANG_CRUSTACEAN'
    },
    {
      label: '4',
      value: 'LANG_TREE_NUTS'
    },
    {
      label: '5',
      value: 'LANG_GLUTEN'
    },
    {
      label: '6',
      value: 'LANG_EGGS'
    },
    {
      label: '7',
      value: 'LANG_DAIRY'
    },
    {
      label: '8',
      value: 'LANG_MOLLUSKS'
    },
    {
      label: '9',
      value: 'LANG_MUSTARD'
    },
    {
      label: '10',
      value: 'LANG_FISH'
    },
    {
      label: '11',
      value: 'LANG_SESAME'
    },
    {
      label: '12',
      value: 'LANG_SOYA'
    },
    {
      label: '13',
      value: 'LANG_SULPHITES'
    }
  ]
  allergensLoaded: boolean = false
  allergensStatus: CounterInterface<boolean> = null
  allergensQuantity: number = 0
  dishTypeList: FormMetadataFieldOptionDataInterface[] = [
    // {
    //   label: 'B',
    //   value: 'LANG_BAKE'
    // },
    {
      label: 'H',
      value: 'LANG_HOT'
    },
    {
      label: 'N',
      value: 'LANG_NEW'
    },
    {
      label: 'V',
      value: 'LANG_VEGETARIAN'
    },
    {
      label: 'T',
      value: 'LANG_TOP_SELLER'
    }
  ]
  dishTypeLoaded: boolean = false
  dishTypeQuantity: number = 0
  dishTypeStatus: CounterInterface<boolean> = null
  private unsubscribe = new Subject<void>()
  loadAllergens: boolean
  list: FormMetadataFieldOptionDataInterface[]
  @ViewChild('subMenuFilters') subMenuFilters
  @ViewChild('subMenuDishType') subMenuDishType
  @ViewChild('subMenuAllergens') subMenuAllergens
  subMenuFiltersWidth: number
  subMenuDishTypeWidth: number
  subMenuAllergensWidth: number
  isIos: boolean
  isSafari: boolean
  currentRoute: string;
  searchingData: any
  navSubscription: any

  @ViewChild('subMenuAllergensList') subMenuAllergensList;
  @ViewChild('subMenuDishTypeList') subMenuDishTypeList;

  constructor(
    private readonly platformService: PlatformService,
    private readonly router: Router,
    private readonly dataService: DataService,
    private readonly apiService: ApiService,
    private readonly langService: LangService,
    private faevents: FaeventsService,
    private elemRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    // console.log(this.fixedPosition)
    this.checkCartQty = false
    this.groups = []
    this.productsCounter = 0

    if (!this.platformService.isServer()) {
      this.navSubscription = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.currentGroup = this.groups[0].id
      })
    }
  }

  ngOnInit(): void {
    this.fillCart()
    if (!this.platformService.isServer()) {
      this.windowWidth = window.innerWidth
      if (this.windowWidth > 575) {
        this.headerPosition = 365
      } else {
        this.headerPosition = 275
      }
      this.updateFormMetadata()
      this.apiService.getNewValue.pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
        (data: any) => {
          if (data && data.hasOwnProperty('loadAllergens') && data.hasOwnProperty('status')) {
            if (data.loadAllergens) {
              this.allergensStatus = data.status
            } else {
              this.dishTypeStatus = data.status
            }
            this.updateFormResultsWithFilters()
          }
        }
      )


      this.langService.onLangChange().pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
        (result: LangChangeEvent) => {
          this.showForm = false;
          this.searchBarResults = null;
          this.dishTypeLoaded = false;
          this.allergensLoaded = false;
        }
      )

      this.currentRoute = this.router.url;
        console.log(this.currentRoute);

    }
    if (this.platformService.isIos()) {
      this.isIos = true;
      if (window.innerHeight < 812 ) {
        this.currentPlatform = 'ios2';
      } else if (window.innerHeight >= 812 && window.innerHeight < 852 ) {
        this.currentPlatform = 'ios';
      } else{
        this.currentPlatform = 'ios3';
      }
    } else {
      this.isIos = false;
      if (this.platformService.isAndroid()) {
        this.currentPlatform = 'android';
      } else {
        this.currentPlatform = 'browser';
      }
    }

    if (this.platformService.isSafari()) {
      this.isSafari = true;
    }

  }
  ngOnDestroy(): void {
    console.log('Destroyed')
    this.unsubscribe.next()
    this.unsubscribe.complete()
    this.navSubscription.unsubscribe();
  }
  // Cart Counter
  fillCart(): void {
    this.productsCounter = 0
    this.dataService.getOrder().then(
      (result: CreateOrderInterface) => {
        const lines: (CreateOrderLineItemsInterface | CreateOrderPromotionLineItemsInterface)[] = [
          ...result.lineItems,
          ...result.promoLineItems
        ]
        lines.forEach(
          value => {
            this.productsCounter += value.quantity
          }
        )
        this.checkCartQty = false
      },
      err => {
        this.checkCartQty = false
      }
    )
  }
  goToCart(): void {
    if (this.windowWidth <= 576) {
      this.router.navigate(['/cart'])
    } else {
      this.closeCart.emit(true)
    }
  }
  onGroupChange(group: MenuGroupInterface): void {
    console.log("onGroupChange", group)
    this.currentGroup = group.id
    this.onSubGroupChange(group.subGroups[0])
  }
  onSubGroupChange(subgroup: MenuSubGroupInterface): void {
    this.currentSubGroup = subgroup.id
    console.log("onSubGroupChange", subgroup.products[0].id)
    this.onMenuChange.emit(subgroup.promotions.length > 0 ? subgroup.promotions[0].id : subgroup.products[0].id)
    this.cdr.detectChanges(); // Forzar el re-renderizado
  }

  toggleShowForm(): void {
    this.showForm = !this.showForm
    this.searchBarResults = null;
    this.dishTypeLoaded = false;
    this.allergensLoaded = false;

    if (this.showForm) {



      setTimeout(() => {
        if (this.subMenuFilters) {
          this.subMenuFiltersWidth = this.subMenuFilters.el.offsetWidth
        }

        if (this.subMenuDishType) {
          this.subMenuDishTypeWidth = this.subMenuDishType.el.offsetWidth
        }

        if (this.subMenuAllergens) {
          this.subMenuAllergensWidth = this.subMenuAllergens.el.offsetWidth
        }
      }, 500);


    } else {
      this.formResults.emit(this.searchBarResults)
    }
  }
  // Search Bar
  getFormResults(event: FormMetadataFieldOptionDataInterface[]): void {
    this.searchBarResultsWithoutFilters = event ? this.pgCommon.deepCopy(event) : event
    console.log("lo que recibimos del form", this.searchBarResultsWithoutFilters )
    this.updateFormResultsWithFilters()

  }
  async loadList(loadAllergens: boolean, ev: any): Promise<void> {
    if (loadAllergens) {
      this.allergensLoaded = !this.allergensLoaded
      if (this.dishTypeLoaded) {
        this.dishTypeLoaded = !this.dishTypeLoaded
      }
    } else {
      this.dishTypeLoaded = !this.dishTypeLoaded
      if (this.allergensLoaded) {
        this.allergensLoaded = !this.allergensLoaded
      }
    }
    this.loadAllergens = loadAllergens
  }

  toggleItem(item: FormMetadataFieldOptionDataInterface): void {

    if (this.groups.length > 0) {
      this.searchingData = {"detail" : { "value": null }, "options": { "data": null }}
      this.searchingData.detail.value = " ";
      this.searchingData.options.data = this.groups.reduce(
          (result: FormMetadataFieldOptionDataInterface[], value) => {
            result.push(
              ...value.subGroups.reduce(
                (resp: FormMetadataFieldOptionDataInterface[], val) => {
                  if (val.promotions.length > 0) {
                    resp.push(
                      ...val.promotions.map(
                        v => {
                          return {
                            label: v.id,
                            value: v.description,
                            groupID: value.id,
                            search: [
                              v.description.toLowerCase()
                            ],
                            subgroupID: val.id
                          }
                        }
                      )
                    )
                  } else {
                    resp.push(
                      ...val.products.map(
                        v => {
                          let family: MenuFamilyInterface = val.families.find(f => f.id === v.familyGroupID)
                          let options: FormMetadataFieldOptionDataInterface = {
                            label: v.id,
                            value: v.shortName,
                            groupID: value.id,
                            search: [
                              v.shortName.toLowerCase(),
                            ],
                            subgroupID: val.id
                          }
                          if (family) {
                            options.search.push(
                              family.description.toLowerCase(),
                              family.shortName.toLowerCase(),
                              ...v.recipe.reduce(
                                (r: string[], t) => {
                                  let topping: MenuFamilyToppingInterface = family.toppings.find(ft => ft.id === t.invSaleItemID)
                                  if (topping) {
                                    r.push(topping.description.toLowerCase())
                                  }
                                  return r
                                }, []
                              )
                            )
                          }
                          return options
                        }
                      )
                    )
                  }
                  return resp
                }, []
              )
            )
            return result
          }, []
        )
  
      }

    if (!this.loadAllergens) {
      this.dishTypeStatus[item.label] = !this.dishTypeStatus[item.label]
      this.apiService.setData({
        loadAllergens: this.loadAllergens,
        status: this.dishTypeStatus
      })
    } else {
      this.allergensStatus[item.label] = !this.allergensStatus[item.label]
      this.apiService.setData({
        loadAllergens: this.loadAllergens,
        status: this.allergensStatus
      })
    }


  }
  trackByFn(index: number, item: FormMetadataFieldOptionDataInterface) {
    return item.label
  }


  selectFormResultItem(item: FormMetadataFieldOptionDataInterface): void {
    let group: MenuGroupInterface = this.groups.find(v => v.id === item.groupID)
    let subgroup: MenuSubGroupInterface = group.subGroups.find(v => v.id === item.subgroupID)
    if (subgroup.promotions.length > 0) {
      let promotion: PromoInterface = subgroup.promotions.find(v => v.id === item.label)
      this.addPromotion.emit(promotion)
    } else {
      let product: MenuFamilyProductInterface = subgroup.products.find(v => v.id === item.label)
      let family: MenuFamilyInterface = subgroup.families.find(v => v.id === product.familyGroupID)
      const productData: CustomizerSelectedDataInterface = {
        product: product
      }
      this.apiService.setData(productData)
      this.showCustomizer.emit({
        family: family,
        status: true
      })
    }
  }
  toggleAllergensLoaded(): void {
    this.allergensLoaded = !this.allergensLoaded
  }
  toggleDishTypeLoaded(): void {
    this.dishTypeLoaded = !this.dishTypeLoaded
  }
  updateFormMetadata(): void {
    if (this.formMetadata === null) {
      this.formMetadata = new FormMetadata({
        buttons: [],
        commitMode: 'change',
        fields: [
          {
            class: 'transparentBackground searchBarMenu',
            disabled: false,
            fieldType: 'search-bar',
            group: '',
            id: 'searchbar',
            isReadOnly: false,
            label: '',
            name: 'searchbar',
            options: {
              data: [],
              label: {
                color: 'secondary',
                position: 'fixed'
              },
              size: {
                lg: '12',
                md: '12',
                sm: '12',
                xl: '12',
                xs: '12'
              }
            },
            placeholder: 'LANG_SEARCH',
            position: 1,
            validators: [],
            value: '',
            inputMode: 'search'
          }
        ],
        formName: 'Products',
        groups: [],
        isReadOnly: false,
        focusOnFirstElement: false
      })
    }
    if (this.groups.length > 0) {
      this.formMetadata.fields[0].options.data = this.groups.reduce(
        (result: FormMetadataFieldOptionDataInterface[], value) => {
          result.push(
            ...value.subGroups.reduce(
              (resp: FormMetadataFieldOptionDataInterface[], val) => {
                if (val.promotions.length > 0) {
                  resp.push(
                    ...val.promotions.map(
                      v => {
                        return {
                          label: v.id,
                          value: v.description,
                          groupID: value.id,
                          search: [
                            v.description.toLowerCase()
                          ],
                          subgroupID: val.id
                        }
                      }
                    )
                  )
                } else {
                  resp.push(
                    ...val.products.map(
                      v => {
                        let family: MenuFamilyInterface = val.families.find(f => f.id === v.familyGroupID)
                        let options: FormMetadataFieldOptionDataInterface = {
                          label: v.id,
                          value: v.shortName,
                          groupID: value.id,
                          search: [
                            v.shortName.toLowerCase(),
                          ],
                          subgroupID: val.id
                        }
                        if (family) {
                          options.search.push(
                            family.description.toLowerCase(),
                            family.shortName.toLowerCase(),
                            ...v.recipe.reduce(
                              (r: string[], t) => {
                                let topping: MenuFamilyToppingInterface = family.toppings.find(ft => ft.id === t.invSaleItemID)
                                if (topping) {
                                  r.push(topping.description.toLowerCase())
                                }
                                return r
                              }, []
                            )
                          )
                        }
                        return options
                      }
                    )
                  )
                }
                return resp
              }, []
            )
          )
          return result
        }, []
      )
    }

    if (this.allergensStatus === null) {
      this.allergensStatus = this.allergensList.reduce(
        (result, value) => {
          result[value.label] = true
          return result
        }, {}
      )
    }
    if (this.dishTypeStatus === null) {
      this.dishTypeStatus = this.dishTypeList.reduce(
        (result, value) => {
          result[value.label] = false
          return result
        }, {}
      )
    }
  }
  updateFormResultsWithFilters(): void {
    this.allergensQuantity = Object.keys(this.allergensStatus).reduce(
      (result: number, value) => {
        if (this.allergensStatus[value] === false) {
          result += 1
        }
        return result
      }, 0
    )
    this.dishTypeQuantity = Object.keys(this.dishTypeStatus).reduce(
      (result: number, value) => {
        if (this.dishTypeStatus[value] === true) {
          result += 1
        }
        return result
      }, 0
    )

    // Results

    this.searchBarResults = null
    if (this.searchBarResultsWithoutFilters && Array.isArray(this.searchBarResultsWithoutFilters)) {
      this.searchBarResults = this.searchBarResultsWithoutFilters.reduce(
        (result: FormMetadataFieldOptionDataInterface[], value) => {
          let canAdd: boolean = true
          if (this.allergensQuantity > 0 || this.dishTypeQuantity > 0) {
            // canAdd = false
            let group: MenuGroupInterface = this.groups.find(v => v.id === value.groupID)
            let subgroup: MenuSubGroupInterface = group.subGroups.find(v => v.id === value.subgroupID)

            if (subgroup.products.length > 0 && subgroup.promotions.length === 0) {
              let product: MenuFamilyProductInterface = subgroup.products.find(p => p.id === value.label)
              let productAllergens: string[] = product.allergens.split('')
              let productBadges: string[] = product.highlightWeb.split('')
              if (this.allergensQuantity > 0) {
                canAdd = Object.keys(this.allergensStatus).reduce(
                  (result: boolean, value) => {
                    if (this.allergensStatus[value] === false && productAllergens[value] === '1') {
                      result = false
                    }
                    return result
                  }, true
                )
              }
              if (this.dishTypeQuantity > 0 && canAdd) {
                canAdd = Object.keys(this.dishTypeStatus).reduce(
                  (result: boolean, value) => {
                    if (this.dishTypeStatus[value] === true && productBadges.indexOf(value) >= 0) {
                      result = true
                    }
                    return result
                  }, false
                )
                // console.log(`Badges check: ${canAdd}`)
              }
            }
          }
          if (canAdd) {
            result.push(value)
          }
          return result
        }, []
      )
    }
    console.log("this.searchBarResults", this.searchBarResults)
    this.formResults.emit(this.searchBarResults)
  }
  //
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.windowWidth = window.innerWidth
    if (this.subMenuFilters) {
      this.subMenuFiltersWidth = this.subMenuFilters.el.offsetWidth
    }

    if (this.subMenuDishType) {
      this.subMenuDishTypeWidth = this.subMenuDishType.el.offsetWidth
    }

    if (this.subMenuAllergens) {
      this.subMenuAllergensWidth = this.subMenuAllergens.el.offsetWidth
    }
  }

  @HostListener('click', ['$event.target'])
  onClickOutside(targetElement) {
    if (this.subMenuAllergensList != undefined) {
      if (this.subMenuAllergensList.el != undefined) {
        let target = this.subMenuAllergensList.el.className
        if (target != undefined) {
          let classClicked = target.split(" ", 1);
          let targetClassClicked = targetElement.className.split(" ", 1);
          if (classClicked[0] != targetClassClicked[0]) {
            this.allergensLoaded = false;
          }

        }
      }
    }

    if (this.subMenuDishTypeList != undefined) {
      if (this.subMenuDishTypeList.el != undefined) {
        let target = this.subMenuDishTypeList.el.className
        if (target != undefined) {
          let classClicked = target.split(" ", 1);
          let targetClassClicked = targetElement.className.split(" ", 1);
          if (classClicked[0] != targetClassClicked[0]) {
            this.dishTypeLoaded = false;
          }

        }
      }
    }

  }

  goToPromos(){
    this.router.navigate(['/promotions'])
  }

  clearFilterDishType() {
    this.dishTypeQuantity= 0
    this.dishTypeStatus = {B: false, H: false, N: false, V: false}
    
  
    this.apiService.setData({
      loadAllergens: this.loadAllergens,
      status: this.dishTypeStatus
    })
    
    this.dishTypeLoaded = !this.dishTypeLoaded
  }

  clearFilterAllergens() {
    this.allergensQuantity= 0
    this.allergensStatus = {0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true, 9: true, 10: true, 11: true, 12: true, 13: true}
    
    this.apiService.setData({
      loadAllergens: this.loadAllergens,
      status: this.allergensStatus
    })

    this.allergensLoaded = !this.allergensLoaded
  }


  

  private evt() {
    try {
      let payload = { item_category: "", id: "" }
      let find = false;
      for (let x = 0; x < this.groups.length; x++) {
        const g = this.groups[x];
        if (g.id == this.currentGroup) {
          payload.item_category = g.sourceName;
          payload.id = g.sourceName;
          if (g.subGroups.length > 1) {
            for (let y = 0; y < g.subGroups.length; y++) {
              const subg = g.subGroups[y];
              if (subg.id == this.currentSubGroup) {
                payload.item_category = subg.name;
                payload.id = subg.name;
                find = true;
                break;
              }
            }
          }
          break;
        }
        if (find) {
          break;
        }
      }
      this.faevents.preLogEvent("view_item_list", payload)
    } catch (e) {
      this.apiService.logger("error", "", e, "view_item_list")
    }
  }

  adjustWidthToCharactersGroups(groupName: string, anchoDeseadoInput: number): string {
    const numeroCaracteres = groupName.length;
    const anchoDeseado = anchoDeseadoInput + (numeroCaracteres * 8);
    return anchoDeseado + 'px';
  }


scrollToSelectedGroup(value) {

  if (window.innerWidth < 768) {
  if(value == 'family'){
  // Obtén la referencia al elemento del grupo seleccionado
  const selectedGroupElement = document.getElementById(`segment-button-${this.currentGroup}`);

  // Hacer scroll horizontal hasta el grupo seleccionado
  if (selectedGroupElement) {
    selectedGroupElement.scrollIntoView({ behavior: 'auto', inline: 'center' });
    this.cdr.detectChanges();
  }
  }

  if(value == 'subfamily'){
    // Obtén la referencia al elemento del grupo seleccionado
    console.log("entro", this.currentSubGroup);
    const selectedSubGroupElement = document.getElementById(`subsegment-button-${this.currentSubGroup}`);
  
    // Hacer scroll horizontal hasta el grupo seleccionado
    if (selectedSubGroupElement) {
      selectedSubGroupElement.scrollIntoView({ behavior: 'auto', inline: 'center' });
      this.cdr.detectChanges();
    }

    }
  }


}
  
}
