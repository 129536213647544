import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StorageVariables, branchByDefault, timezoneName, kiosk } from '@core/constants';
import { setImageIDtoMenu } from '@core/functions';
import {
  MenuInterface,
  OrderInterface,
  OrderLineTypes,
  OrderTypes,
  PromoInterface,
  PromoTypes,
  MenuOutOfStock,
  MenuFamilyProductInterface,
  MenuFamilyProductToppingInterface,
  MenuFamilyToppingInterface,
  ProductOptions,
  PromoOptions,
  EngineError,
  EngineExpectionTypes,
  MenuFamilyInterface,
  ProductCalculatedResponse,
  PromotionCalculatedResponse,
  PromoUserInterface,
  ZoneInterface
} from '@lib/promo-engine';
import { staticMenu, staticPromotions } from '@core/static-data';
import {
  AddPromotionResponseInterface,
  CounterInterface,
  CreateOrderInterface,
  CreateOrderPromotionLineItemsInterface,
  PgOrder,
  PgWizard,
  PgCommon,
  PgOrderPromoLine,
  PgMenu,
  PgCustomizer,
  CreateOrderLineItemsInterface,
  PgOrderLine,
  PgOrderLinesType,
  CalculateLineErrorInterface,
  CalculateLinesResponseInterface
} from '@lib/utils';
import { ApiService } from './api.service';
import { LangService } from './lang.service';
import { PlatformService } from './platform.service';
import { StorageService } from './storage.service';
import { MenuOutOfStockResultInterface, CustomerLoyaltyInfoInterface } from '@core/interfaces/services';
import { CalculateCartInterface } from '@pages/cart/cart.page';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private isServer: boolean
  sizeFooter: number
  lineType: string
  idPromoToScroll: string
  requestUser: boolean = false
  defaultMethod: any = false
  isKiosk: boolean = kiosk
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private readonly storageService: StorageService,
    private readonly apiService: ApiService,
    private readonly langService: LangService,
    private readonly platformService: PlatformService
  ) {
    this.isServer = isPlatformServer(this.platformId)
  }
  // Static Data
  public getStaticMenu(): MenuInterface {
    return setImageIDtoMenu(staticMenu)
  }
  public getStaticPromotions(): PromoInterface[] {
    return staticPromotions
  }
  // Menu Nd Promotions
  public getMenu(branchID: number = branchByDefault, forceUpdate: boolean = false): Promise<MenuInterface> {
    return new Promise(
      async resolve => {
        if (this.isServer) {
          resolve(this.getStaticMenu())
        } else {
          let lang: string = this.langService.getCurrentLang()
          let menu: MenuInterface = null
          if (!forceUpdate) {
            try {
              menu = await this.storageService.getItem(`${StorageVariables.menu}-${lang}`).toPromise()
              // console.log('Menu del LocalStorage', menu)
            } catch (e) {
              // menu = this.getStaticMenu()
              // console.log('Menu estatico 1')
            }
          }
          if (menu) {
            resolve(menu)
          } else {
            try {
              menu = await this.apiService.getMenu(branchID, `${lang}&loading=false`).toPromise()
              // console.log('Menu del servidor')
              menu.ts = new Date().toISOString()
              await this.storageService.setItem(`${StorageVariables.menu}-${lang}`, menu).toPromise()
              resolve(menu)
            } catch (e) {
              resolve(this.getStaticMenu())
              // console.log('Menu estatico 2')
            }
          }
        }
      }
    )
  }
  public getPromotions(branchID: number = branchByDefault, forceUpdate: boolean = false): Promise<PromoInterface[]> {
    return new Promise(
      async resolve => {
        if (this.isServer) {
          resolve(this.getStaticPromotions())
        } else {
          let lang: string = this.langService.getCurrentLang()
          let promotions: PromoInterface[] = null
          if (!forceUpdate) {
            try {
              promotions = await this.storageService.getItem(`${StorageVariables.promos}-${lang}`).toPromise()
            } catch (e) {
              promotions = []// this.getStaticPromotions()
            }
          }
          if (promotions && Array.isArray(promotions) && promotions.length > 0) {
            resolve(promotions)
          } else {
            try {
              promotions = await this.apiService.getPromotionsByBranch(branchID, `${lang}&loading=false`).toPromise()
              if (promotions && promotions.length > 0) {
                promotions[0].ts = new Date().toISOString()
                await this.storageService.setItem(`${StorageVariables.promos}-${lang}`, promotions).toPromise()
                resolve(promotions)
              } else {
                // resolve(this.getStaticPromotions())
                resolve([])
              }
            } catch (e) {
              // resolve(this.getStaticPromotions())
              resolve([])
            }
          }
        }
      }
    )
  }
  // Insider
  public addProductInsider(productID: string): Promise<boolean> {
    return new Promise(
      async (resolve, reject) => {
        const menu: MenuInterface = await this.getMenu()
        const order: CreateOrderInterface = await this.getOrder()
        const promotions: PromoInterface[] = await this.getPromotions()
        const pgMenu: PgMenu = new PgMenu(menu, promotions)
        const product: MenuFamilyProductInterface = pgMenu.getProduct(productID)
        if (product && 'id' in product) {
          const family: MenuFamilyInterface = pgMenu.getFamily(product.familyGroupID)
          const pgCustomizer: PgCustomizer = new PgCustomizer(family, product, menu, '', null, false)
          const items: CreateOrderLineItemsInterface = pgCustomizer.getItemsToCreateOrderLine(1)
          this.addProductToOrder(true, items, menu, order, null, null, promotions).then(
            () => {
              resolve(true)
            },
            () => {
              reject(false)
            }
          )
        } else {
          reject(false)
        }
      }
    )
  }
  public addPromotionInsider(promoID: string): Promise<boolean> {
    return new Promise(
      async (resolve, reject) => {
        let promotions: PromoInterface[] = await this.getPromotions()
        if (promotions && Array.isArray(promotions) && promotions.length > 0) {
          let promo: PromoInterface = promotions.find(p => p.id === promoID)
          if (promo && 'id' in promo) {
            this.addPromotionToOrder(promo, null, null, promotions).then(
              () => {
                this.setTypeLineAdded('Promo')
                resolve(true)
              },
              (errors: any[]) => {
                reject(false)
              }
            )
          } else {
            reject(false)
          }
        } else {
          reject(false)
        }
      }
    )
  }
  // Order
  public addProductToOrder(mustAddLine: boolean, items: CreateOrderLineItemsInterface, menu: MenuInterface = null, order: CreateOrderInterface = null, orderLine: PgOrderLine = null, pgOrder: PgOrder, promotions: PromoInterface[] = null): Promise<boolean> {
    return new Promise(
      async (resolve, reject) => {
        let added: boolean = true
        if (!pgOrder) {
          if (!menu) {
            menu = await this.getMenu()
          }
          if (!order) {
            order = await this.getOrder()
          }
          if (!promotions) {
            promotions = await this.getPromotions()
          }
          pgOrder = new PgOrder(order, menu, promotions)
        }
        if (mustAddLine) {
          if (!orderLine) {
            orderLine = pgOrder.createLine(items)
          }
          added = pgOrder.addLine(orderLine)
        }
        // console.log(added)
        if (added) {
          const orderLines = pgOrder.getFullLines().reduce(
            (result, value) => {
              const promoEngineResult = value.getPromoEngineResult()
              if (promoEngineResult) {
                result.push({
                  ...promoEngineResult,
                  customID: value.getID()
                })
              }
              return result
            }, [])
          // console.log(pgOrder.deepCopy(orderLines))
          pgOrder.updateEntireOrder(null, {
            complete: pgOrder.deepCopy(orderLines),
            discarded: [],
            errors: [],
            promo: null
          })
          if (pgOrder.isStartNewOrder()) {
            this.apiService.eventPg(pgOrder.getOrder().lineItems[0], "checkout_start");
          }
          await this.storageService.setItem(StorageVariables.order, pgOrder.getOrder()).toPromise()
          resolve(added)
        } else {
          reject(added)
        }
      }
    )
  }
  public addPromotionToOrder(
    promo: PromoInterface,
    menu: MenuInterface = null,
    order: CreateOrderInterface = null,
    promotions: PromoInterface[] = [],
    promoCode: string = null,
    wizard: PgWizard = null,
    promoLine: PgOrderPromoLine = null
  ): Promise<any[]> {
    return new Promise(
      async (resolve, reject) => {
        if (!menu) {
          menu = await this.getMenu()
        }
        if (!order) {
          order = await this.getOrder()
        }
        if (!promotions || promotions.length === 0) {
          promotions = await this.getPromotions()
        }
        let hasPromoCode: boolean = promoCode !== null
        let pgOrder: PgOrder = new PgOrder(order, menu, promotions)
        if (promo.promoType === PromoTypes.Delivery) {
          if (hasPromoCode) {
            pgOrder.updateDeliveryFeePromoCode(promoCode)
            this.storageService.setItem(StorageVariables.order, pgOrder.getOrder()).subscribe(
              () => {
                resolve([])
              },
              () => {
                resolve([])
              }
            )
          } else {
            if (promo && promo.hasOwnProperty('promoUserID') && promo.promoUserID !== null && promo.promoUserID !== undefined && promo.promoUserID !== '') {
              try {
                let currentZone = await this.storageService.getItem(StorageVariables.currentZone).toPromise()
                let customerStatus = await this.apiService.getCustomerStatus().toPromise()
                let customerPromos: PromoUserInterface[] = [
                  {
                    'ID': parseInt(promo.promoUserID),
                    'promoID': promo.id,
                    'customerID': '',
                    'endingDate': '',
                    'pgCustomerID': 0,
                    'phoneNumber': 0,
                    'redeemed': 'N',
                    'startingDate': ''
                  }
                ]
                let newOrder: OrderInterface = pgOrder.prepareOrderToSaveTicket(false)
                let deliveryOptions = pgOrder.getDeliveryPromoEngineOptions()
                console.log(deliveryOptions)
                console.log(currentZone)
                console.log(menu)
                console.log(promotions)
                let deliveryFeeCalculated: (ProductCalculatedResponse | PromotionCalculatedResponse) = pgOrder.calculateDeliveryFee(
                  deliveryOptions,
                  menu,
                  promotions,
                  pgOrder.deepCopy(newOrder.lines),
                  currentZone,
                  customerStatus,
                  pgOrder.getDeliveryFeePromoCode(),
                  customerPromos
                )
                console.log(pgOrder.deepCopy(deliveryFeeCalculated))
                if (deliveryFeeCalculated && 'saleItemID' in deliveryFeeCalculated) {
                  if (deliveryFeeCalculated.type === OrderLineTypes.promotion) {
                    let promoClientID = (deliveryFeeCalculated as PromotionCalculatedResponse).promoClientID
                    if (parseInt(promo.promoUserID) == promoClientID) {
                      resolve([])
                    } else {
                      reject([{ code: 13 }])
                    }
                  } else {
                    reject([{ code: 13 }])
                  }
                } else {
                  reject([{ code: 13 }])
                }
                reject([])
              } catch (e) {
                console.log(e)
                reject([{ code: 13 }])
              }
            } else {
              reject([])
            }
          }
        } else {
          let isDescuento: boolean = (promo.promoType === PromoTypes.Descuento)
          let addAsAutomaticPromotion: boolean = (hasPromoCode && !isDescuento) || isDescuento
          if (addAsAutomaticPromotion) {
            if (!pgOrder.checkPromoCodeInPromoCodes((hasPromoCode ? promoCode : null), promo.id)) {
              pgOrder.updatePromoCodes((hasPromoCode ? promoCode : null), promo)
            }
            this.setIdPromoToScroll(undefined)
            this.storageService.setItem(StorageVariables.order, pgOrder.getOrder()).subscribe(
              () => {
                resolve([])
              },
              () => {
                resolve([])
              }
            )
          } else {
            let pgWizard: PgWizard = (wizard !== null) ? wizard : new PgWizard(menu, promo, promotions, pgOrder.getPromoEngineOptions())
            if (pgWizard.isValid()) {
              let discountTypeCheckValid: boolean = true
              if (promo.promoType === PromoTypes.Descuento) {
                discountTypeCheckValid = pgOrder.getPromotionLines().reduce(
                  (result: boolean, value) => {
                    if (value.getCurrentPromotion().promoType === PromoTypes.Descuento) {
                      result = false
                    }
                    return result
                  }, true
                )
              }
              if (discountTypeCheckValid) {
                // Checking order type before add
                let checkPromoOrderType: CounterInterface<boolean> = pgOrder.checkPromotionOrderType(promo)
                let delivery: OrderTypes = OrderTypes.Domicilio
                let pickUp: OrderTypes = OrderTypes.Recoger
                let orderOnlyDelivery: boolean = pgOrder.getExcludedOrderType() === pickUp
                let orderOnlyPickUp: boolean = pgOrder.getExcludedOrderType() === delivery
                let promotionOnlyDelivery: boolean = checkPromoOrderType[delivery] === true && !checkPromoOrderType[pickUp]
                let promotionOnlyPickUp: boolean = checkPromoOrderType[pickUp] === true && !checkPromoOrderType[delivery]
                let canAdd: boolean = (orderOnlyDelivery && promotionOnlyPickUp) ? false : ((orderOnlyPickUp && promotionOnlyDelivery) ? false : true)
                if (canAdd) {
                  if (promotionOnlyDelivery) {
                    pgOrder.updateExcludedOrderType(pickUp)
                    pgOrder.updateOrderType(delivery)
                  } else if (promotionOnlyPickUp) {
                    pgOrder.updateExcludedOrderType(delivery)
                    pgOrder.updateOrderType(pickUp)
                  }
                  let items: CreateOrderPromotionLineItemsInterface = pgWizard.getItemsToCreateOrderPromotionLine()
                  if (hasPromoCode) {
                    items.promoCode = promoCode
                  }
                  let addPromoResponse: AddPromotionResponseInterface = pgOrder.addPromotion(items, pgWizard.isCompleted(), promoLine)
                  let isAdded: boolean = addPromoResponse.status
                  if (isAdded) {
                    this.apiService.eventPg(promo, "select_promotion");
                    this.setIdPromoToScroll(undefined)
                    let order: CreateOrderInterface = pgOrder.getOrder()
                    await this.storageService.setItem(StorageVariables.order, order).toPromise()
                    resolve([])
                  } else {
                    reject(addPromoResponse.errors)
                  }
                } else {
                  reject((promotionOnlyDelivery || promotionOnlyPickUp) ? [{ code: (promotionOnlyDelivery ? 1.2 : 1.1) }] : [])
                }
              } else {
                reject([{ code: 1.3 }])
              }
            } else {
              reject([])
            }
          }
        }
      }
    )
  }
  public checkOrderLineErrors(order: OrderInterface, options: ProductOptions | PromoOptions): Promise<any[]> {
    return new Promise(
      async (resolve, reject) => {
        let errors: any[] = []
        let orderLinesToFilter: number[] = []
        let productProperties: string[] = [
          'saleItemID',
          'toppings'
        ]
        let composedTypes: OrderLineTypes[] = [
          OrderLineTypes.composed,
        ]
        let productTypes: OrderLineTypes[] = [
          OrderLineTypes.simple
        ]
        let promotionProperties: string[] = [
          'saleItemID'
        ]
        let promotionTypes: OrderLineTypes[] = [
          OrderLineTypes.crossSales,
          OrderLineTypes.promotion,
          ...composedTypes
        ]
        order.lines.forEach(
          (value, index) => {
            let error: boolean = false
            let line = value
            if (line.hasOwnProperty('type')) {
              let lineType: OrderLineTypes = line.type
              if (productTypes.indexOf(lineType) >= 0) {
                error = productProperties.reduce(
                  (result: boolean, v) => {
                    if (!line.hasOwnProperty(v)) {
                      result = true
                    }
                    return result
                  }, false
                )
              } else if (promotionTypes.indexOf(lineType) >= 0) {
                if (line.hasOwnProperty('sublines')) {
                  error = promotionProperties.reduce(
                    (result: boolean, v) => {
                      if (!line.hasOwnProperty(v)) {
                        result = true
                      }
                      return result
                    }, false
                  )
                  line.sublines.forEach(
                    val => {
                      if (!error) {
                        const propertiesToCheck: string[] = (composedTypes.includes(val.type) ? promotionProperties : productProperties)
                        error = propertiesToCheck.reduce(
                          (result: boolean, v) => {
                            if (!val.hasOwnProperty(v)) {
                              result = true
                            }
                            return result
                          }, false
                        )
                      }
                    }
                  )
                } else {
                  error = true
                }
              }
            } else {
              error = true
            }
            if (error) {
              orderLinesToFilter.push(index)
            }
          }
        )
        if (orderLinesToFilter.length > 0) {
          errors = orderLinesToFilter.reduce(
            (result, value, index) => {
              result.push(
                new EngineError(
                  'The order line is not complete',
                  EngineExpectionTypes.Error
                )
              )
              return result
            }, []
          )
        } else {
          let pgCommon: PgCommon = new PgCommon()
          let menu = await this.getMenu(order.branchID, true)
          let promotions = await this.getPromotions(order.branchID, true)
          let loyaltyPoints = await this.calculateLoyaltyPoints()
          errors = pgCommon.checkErrorLines(
            options,
            menu,
            promotions,
            order.lines,
            null,
            loyaltyPoints
          )
        }
        if (errors.length > 0) {
          reject(errors)
        } else {
          resolve(errors)
        }
      }
    )
  }
  public getOrder(): Promise<CreateOrderInterface> {
    return new Promise(
      async resolve => {
        if (this.isServer) {
          resolve(await this.getEmptyOrder())
        } else {
          // Checking Order
          let order = null
          try {
            order = await this.storageService.getItem(StorageVariables.order).toPromise()
          } catch (e) { }
          if (order) {
            resolve(order)
          } else {
            let emptyOrder = await this.getEmptyOrder()
            // this.storageService.removeItem(StorageVariables.userSelectBranch);
            await Promise.all([
              this.storageService.setItem(StorageVariables.order, emptyOrder).toPromise(),
              // this.storageService.removeItem(StorageVariables.userSelectBranch).toPromise()
            ])
            resolve(emptyOrder)
          }
        }
      }
    )
  }
  public getEmptyOrder(): Promise<CreateOrderInterface> {
    return new Promise(
      async resolve => {
        let pgOrder: PgOrder = new PgOrder(null, null, null)
        let emptyOrder = pgOrder.getEmptyOrder(branchByDefault)
        if (!this.platformService.isServer()) {
          emptyOrder.device = this.platformService.getCurrentDevice()
          emptyOrder.language = this.langService.getCurrentLang()
          emptyOrder.source = this.platformService.getSource()
        }
        resolve(emptyOrder)
      })
  }
  public async calculateLoyaltyPoints(): Promise<number> {
    return new Promise(
      async resolve => {
        let loyaltyInfo: CustomerLoyaltyInfoInterface[] = []
        let loyaltyPoints: number = 0
        try {
          loyaltyInfo = await this.storageService.getItem(StorageVariables.customerLoyaltyPoints).toPromise()
        } catch (e) { }
        if (loyaltyInfo.length > 0) {
          loyaltyPoints = loyaltyInfo.reduce(
            (result: number, value) => {
              result += value.amount
              return result
            }, 0
          )
        }
        resolve(loyaltyPoints)
      }
    )
  }
  public calculateCart(
    currentZone: ZoneInterface,
    customerPromos: PromoUserInterface[],
    excludedPromotionsFromUser: string[],
    loyaltyPoints: number,
    menu: MenuInterface,
    pgOrder: PgOrder,
    promotions: PromoInterface[]
  ): Promise<CalculateCartInterface> {
    return new Promise(
      (resolve, reject) => {
        let deliveryFeeCalculated: (ProductCalculatedResponse | PromotionCalculatedResponse) = null
        // PE options
        let deliveryOptions = pgOrder.getDeliveryPromoEngineOptions()
        let pickupOptions = pgOrder.getPickUpPromoEngineOptions()
        // Lines
        let deliveryLines: PgOrderLinesType[] = []
        let pickupLines: PgOrderLinesType[] = []
        let currentErrorLines: CalculateLineErrorInterface[] = []
        let currentLines: PgOrderLinesType[] = []
        if (pgOrder.getLinesLength() > 0) {
          // let deliveryCalculatedLines: CalculateLinesResponseInterface = {
          //   'errors': [],
          //   'lines': []
          // }
          let pickupCalculatedLines: CalculateLinesResponseInterface = pgOrder.updateCalculatedLines(excludedPromotionsFromUser, pickupOptions, loyaltyPoints, true)
          // deliveryLines = deliveryCalculatedLines.lines
          pickupLines = pickupCalculatedLines.lines
          currentErrorLines = pickupCalculatedLines.errors
          currentLines = pickupLines
        }
        pgOrder.setCalculatedLines(currentLines.map(value => value.getPromoEngineResult()))
        // Prices
        let deliveryFeeLine: PgOrderPromoLine = null
        let deliveryFeePrice: number = 0
        let deliveryPrice: number = 0
        let pickUpPrice: number = 0
        let productsCounter: number = 0
        let total: number = 0
        let totalDiscount: number = 0
        currentLines.forEach(
          value => {
            productsCounter += value.getQuantity()
            total += (value.getUnitPrice() * value.getQuantity())
            if (value instanceof PgOrderPromoLine) {
              totalDiscount += (value.getDiscount() * value.getQuantity())
            }
          }
        )
        if (pickupLines.length > 0) {
          pickUpPrice = pickupLines.reduce((result: number, value) => result += (value.getUnitPrice() * value.getQuantity()), 0)
        }
        resolve(
          {
            currentErrorLines,
            currentLines,
            deliveryFeeLine,
            deliveryFeePrice,
            deliveryLines,
            deliveryPrice,
            pickupLines,
            pickUpPrice,
            productsCounter,
            total,
            totalDiscount
          }
        )
      }
    )
  }
  public startOverOrder(): Promise<boolean> {
    return new Promise(
      (resolve, reject) => {
        this.getOrder().then(
          order => {
            order.addedCrossSales = []
            order.calculatedLines = []
            order.deliveryFeeLine = null
            order.deliveryFeePromoCode = null
            order.lineItems = []
            order.promisedTime = ''
            order.promoCodes = []
            order.promoLineItems = []
            order.ts = (new Date()).toISOString()
            this.storageService.setItem(StorageVariables.order, order).toPromise().finally(
              () => {
                resolve(true)
              }
            )
          }
        )
      }
    )
  }
  //
  public setSizeFooter(value: number) {
    this.sizeFooter = value;
  }
  public getSizeFooter() {
    return this.sizeFooter;
  }
  public setTypeLineAdded(value: string) {
    this.lineType = value;
  }
  public getTypeLineAdded() {
    return this.lineType;
  }

  public setIdPromoToScroll(value: string) {
    this.idPromoToScroll = value;
  }

  public getIdPromoToScroll() {
    return this.idPromoToScroll;
  }

  public setNeedRequestUser(value: boolean) {
    this.requestUser = value;
  }

  public getNeedRequestUser() {
    return this.requestUser;
  }


  async getDefaultPaymentMethod(branchID, type): Promise<any> {

    let methods: any[] = await this.apiService.getPaymentMethods(branchID, type).toPromise()
    let defaultMethod;
    methods.sort((a, b) => (a.order < b.order) ? -1 : 1);

    let result = methods.filter(m => m.id == 11);

    if (result.length > 0) {
      defaultMethod = result[0].id
      this.defaultMethod = result[0];
    } else {
      let result1 = methods.filter(m => m.id == 1);
      if (result1.length > 0) {
        defaultMethod = result1[0].id
        this.defaultMethod = result[0];
      } else {
        let result2 = methods.filter(m => m.paymentGateway == 'N');
        if (result2.length > 0) {
          defaultMethod = result2[0].id
          this.defaultMethod = result[0];
        }
      }
    }

    return defaultMethod
  }

  public checkStockProduct(menuOutOfStock: MenuOutOfStock, product: MenuFamilyProductInterface): MenuOutOfStockResultInterface {
    let menuOutOfStockResult: MenuOutOfStockResultInterface = {}
    if (this.isKiosk && menuOutOfStock !== null) {
      let requiredToppings: MenuFamilyProductToppingInterface[] = product.recipe.filter(t => t.required === 'S')
      let toppings: MenuFamilyProductToppingInterface[] = product.recipe.filter(t => t.required !== 'S')
      let ignoreChecks: boolean = product.stockControlType !== 'S'
      menuOutOfStockResult.product = ignoreChecks || (menuOutOfStock.products.indexOf(product.erpID) < 0 && menuOutOfStock.products.indexOf(product.id) < 0)
      menuOutOfStockResult.requiredToppings = ignoreChecks || this.checkStockToppingsProduct(menuOutOfStock, requiredToppings)
      menuOutOfStockResult.toppings = ignoreChecks || this.checkStockToppingsProduct(menuOutOfStock, toppings)
    } else {
      menuOutOfStockResult = {
        product: true,
        requiredToppings: true,
        toppings: true
      }
    }
    return menuOutOfStockResult
  }
  public checkStockTopping(menuOutOfStock: MenuOutOfStock, toppingID: string): boolean {
    return (this.isKiosk && menuOutOfStock !== null) ? menuOutOfStock.toppings.indexOf(toppingID) < 0 : true
  }
  public checkStockToppingsFamily(menuOutOfStock: MenuOutOfStock, toppings: MenuFamilyToppingInterface[]): CounterInterface<boolean> {
    let that: this = this
    let result: CounterInterface<boolean> = toppings.reduce(
      (resp: CounterInterface<boolean>, value) => {
        resp[value.id] = that.checkStockTopping(menuOutOfStock, value.id)
        return resp
      }, {}
    )
    return result
  }
  private checkStockToppingsProduct(menuOutOfStock: MenuOutOfStock, toppings: MenuFamilyProductToppingInterface[]): boolean {
    let that: this = this
    return toppings.reduce(
      (result: boolean, value) => {
        let stockTopping: boolean = that.checkStockTopping(menuOutOfStock, value.invSaleItemID)
        if (!stockTopping) {
          result = false
        }
        return result
      }, true
    )
  }

  public getErrorInfoSaveTicket(data) {
    let info = {
      'id': '',
      'type': ''
    }
    if (Array.isArray(data.error.message)) {
      let errorInfo = data.error.message[0]
      switch (errorInfo.type) {
        case 'OutOfStock':
          info.id = errorInfo.cause
          info.type = errorInfo.type
          break
        case 'CheckLines':
          info.id = errorInfo.cause.errors[0].type
          info.type = errorInfo.type
          break
        case 'PricesNotMatch':
        case 'PromiseTimeOutOfBounds':
        case 'CustomerNotFound':
        case 'PaymentMethodNotValid':
        case 'EmptyLines':
        case 'AddressNotFound':
        case 'DeliveryFeeNotMatch':
          info.id = errorInfo.type
          break
        case 'NoPhoneValidated':
          info.id = errorInfo.type
          info.type = errorInfo.type
          break
        case 'NotSaved':
          info.id = errorInfo.description.response.message[0].type
          break
        case 'PromoOnlyFirstOrder':
          info.id = errorInfo.cause.id
          info.type = errorInfo.type
          break
        default:
          info.id = errorInfo.code
          info.type = errorInfo.type
          break
      }
      // if (data.error.message[0].type == 'OutOfStock') {
      //   info = { id: data.error.message[0].cause, type: data.error.message[0].type }
      // } else if (data.error.message[0].type == 'CheckLines') {
      //   info = { id: data.error.message[0].cause.errors[0].type, type: data.error.message[0].type }
      // } else if (data.error.message[0].type == 'PricesNotMatch') {
      //   info = { id: data.error.message[0].type, type: "" }
      // } else if (data.error.message[0].type == 'PromiseTimeOutOfBounds') {
      //   info = { id: data.error.message[0].type, type: "" }
      // } else if (data.error.message[0].type == 'CustomerNotFound') {
      //   info = { id: data.error.message[0].type, type: "" }
      // } else if (data.error.message[0].type == 'PaymentMethodNotValid') {
      //   info = { id: data.error.message[0].type, type: "" }
      // } else if (data.error.message[0].type == 'EmptyLines') {
      //   info = { id: data.error.message[0].type, type: "" }
      // } else if (data.error.message[0].type == 'AddressNotFound') {
      //   info = { id: data.error.message[0].type, type: "" }
      // } else if (data.error.message[0].type == 'NoPhoneValidated') {
      //   info = { id: data.error.message[0].type, type: data.error.message[0].type }
      // } else if (data.error.message[0].type == 'DeliveryFeeNotMatch') {
      //   info = { id: data.error.message[0].type, type: "" }
      // } else if (data.error.message[0].type == 'NotSaved') {
      //   info = { id: data.error.message[0].description.response.message[0].type, type: "" }
      // } else if (data.error.message[0].type == 'PromoOnlyFirstOrder') {
      //   info = { id: data.error.message[0].id, type: data.error.message[0].cause.type }
      // } else {
      //   info = { id: data.error.message[0].code, type: data.error.message[0].type }
      // }
    } else {
      info = { id: data.error.message, type: "" }
    }
    return info;
  }

  public filterPromotionsByHourlyRestriction(promotions: PromoInterface[]): PromoInterface[] {
    let currentDate: string = new Date().toLocaleString('en-GB', { 'timeZone': timezoneName })
    let pgCommon: PgCommon = new PgCommon()
    return promotions.filter(p => pgCommon.calculatePromotionHourAvailability(currentDate, pgCommon.deepCopy(p)))
  }
}
