// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  // pro test branch "https://dev.api.maestropizza.pizzagest.com/api"
  // pro "https://ksa.api.maestropizza.com/api"
  backendUrl: "https://dev.api.maestropizza.pizzagest.com/api",
  socketUrl: "wss://agfgh3jn07.execute-api.me-south-1.amazonaws.com/Prod",
  productsUrl: "https://mponlineassets.s3.me-south-1.amazonaws.com/ksa/assets/rebranding/",
  assetsUrl: "https://mponlineassets.s3.me-south-1.amazonaws.com/ksa/assets/rebranding/",
  firebase: {
    apiKey: "AIzaSyD1y_gT5W8OozS1KtVDzu4_CFmlrrXDvDY",
    authDomain: "poject-pizza-maestro.firebaseapp.com",
    databaseURL: "https://poject-pizza-maestro.firebaseio.com",
    projectId: "poject-pizza-maestro",
    storageBucket: "poject-pizza-maestro.appspot.com",
    messagingSenderId: "879555548644",
    appId: "1:879555548644:web:5693689b83375530f2e3f1",
    measurementId: "G-S8J8ME0DQG"
  },
  // firebase: { // only for develop login facebook
  //   apiKey: "AIzaSyC6pfVFjs41EzXeGZrhGuafoznCmSWNvhE",
  //   authDomain: "pizzagest-hub.firebaseapp.com",
  //   databaseURL: "https://pizzagest-hub.firebaseio.com",
  //   projectId: "pizzagest-hub",
  //   storageBucket: "pizzagest-hub.appspot.com",
  //   messagingSenderId: "1016739080153",
  //   appId: "1:1016739080153:web:b4a697176c20097da71bc8"
  // },
  appsFlyer: {
    iOS: {
      devKey: 'utj3P72jCNri6t2rbpECDA',
      isDebug: true,
      appId: 'id1115579755'
    },
    android: {
      devKey: 'utj3P72jCNri6t2rbpECDA',
      isDebug: true
    },
    web : '6625d3c0-9bab-44ac-a580-903d6aeb553c'
  },
  maginLinks : {
    urlCallback : "http://localhost:8100/login"
  },
  hereMaps : 
  {
    apiKey : 'GL5Yb9AWJ2oeNotw4Z7ixF1G81BAeC8RDe-x43h5ZoI',
    centerMap : {lat:24.744227,lng:46.703088},
    zoom : 16,
    zoomFitBound : 6,
    zoomBranchSelected : 12,
    discover : 'https://discover.search.hereapi.com/v1/discover',
    reverseGeocode : 'https://revgeocode.search.hereapi.com/v1/revgeocode'

  },
  gateWays : {
    applePay : {
      checkout : {
        url : 'https://s01.pizzagest.info/pay/checkout/applepay/index.php',
        checkoutIDUrl : '',
        paymentResultUrl : '',
        letter : 'U',
        merchantIdentifier : 'merchant.com.dailyfoods.maestropizzatest',
        name: 'Checkout',
        supportedNetworks : ['visa', 'masterCard', 'amex', 'mada'],
        currencyCode: 'SAR',
        countryCode: 'SA',
      },
      hyperPay : {
        url : '',
        checkoutIDUrl : 'https://s01.pizzagest.info/hyperpay/getcheckoutid2.php',
        paymentResultUrl : 'https://s01.pizzagest.info/hyperpay/getpaymentresult.php',
        letter : 'Y',
        merchantIdentifier : 'merchant.com.dailyfoods-sandbox.maestropizza',
        name : 'HyperPay',
        supportedNetworks : ['visa', 'masterCard', 'amex', 'mada'],
        currencyCode: 'SAR',
        countryCode: 'SA',
      }
    },
    invoice: {
      tap: {
        letter: 'S',
        url: 'https://s01.pizzagest.info/pay/tap/invoice/index.php'
      }
    },
    qitaf : {
      BranchId :10970001,
      TerminalId : 10970001
    },
    hyperPay : {
      letter : 'H',
      customer : 'maestropizza@dailyfoodsa.com',
      url : 'https://s01.pizzagest.info/hyperpay/index2.php'
    },
    checkout : {
      letter : 'K',
      url : 'https://s01.pizzagest.info/pay/',
      client : 488,
      requireCvv : ['amex','mada']
    },
    tap: {
      client: 488,
      letter: 'I',
      url : 'https://s01.pizzagest.info/pay/'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
