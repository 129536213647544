import { Component,  HostListener,  Input,  NgZone,  OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { DeliveryService } from '@services/delivery.service';
import { StorageService } from '@services/storage.service';
import Keyboard from "simple-keyboard";
import { LangService } from '@services/lang.service';
import { LangChangeEvent } from '@ngx-translate/core';
import layout from "simple-keyboard-layouts/build/layouts/arabic";
import { FormMetadataFieldInterface } from '@core/modules/angular-ionic-forms/common/interfaces';
import { Subject } from 'rxjs';
import { ApiService } from '@services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent implements OnInit {

  value: string = "";
  keyboard: Keyboard;
  inputName = "firstName";
  showKeyboard: boolean
  currentLanguage: string
  @Input() route : string
  inputs: any = {  };
  fields: FormMetadataFieldInterface[]
  private unsubscribe = new Subject<void>()

  constructor(
    private readonly ngZone: NgZone,
    private deliveryService : DeliveryService,
    private readonly storageService: StorageService,
    public langService: LangService,
    private readonly _api: ApiService,
    private router: Router
  ) { 


  }


  ngAfterViewInit(): void {

    if(this.currentLanguage == 'ar'){
      this.keyboard = new Keyboard({
        debug: true,
        inputName: this.inputName,
        onChange: input => this.onChangeKeyboard(input),
        onKeyPress: button => this.onKeyPressKeyboard(button),
        rtl:  true,
        ...layout,
        buttonTheme: [
          {
            class: "hg-enter hg-highlight",
            buttons: "{enter}"
          },
          {
            class: "",
            buttons: "{bksp}"
          },
        ],
        display: {
          '{bksp}': '⌫',
          '{btmu}': 'Back to menu',
          '{enter}': 'enter',
          '{space}': 'space',
          '{shift}': '⇧',
        }
      });
    }else{
      this.keyboard = new Keyboard({
        debug: true,
        inputName: this.inputName,
        onChange: input => this.onChangeKeyboard(input),
        onKeyPress: button => this.onKeyPressKeyboard(button),
        rtl:  false,
        layout: { 'default': [
          '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
          ' q w e r t y u i o p [ ] \\',
          ' a s d f g h j k l ; \' {enter}',
          ' z x c v b n m , . / ',
          '.com @ {space}'
        ]},
        buttonTheme: [
          {
            class: "hg-enter hg-highlight",
            buttons: "{enter}"
          },
          {
            class: "hg-button-back",
            buttons: "{bksp} {btmu}"
          },
          {
            class: "hg-button-backtomenu",    // Clase nueva personalizada para {btmu}
            buttons: "{btmu}"
          }
        ],
        display: {
          '{bksp}': '⌫',
          '{btmu}': 'Back to menu',
          '{enter}': 'submit',
          '{space}': 'space',
          '{shift}': '⇧',
        }
      });
    }

    this.keyboard.replaceInput(this.inputs);

  }

  onChangeKeyboard = (input: string) => {
    this.value = input;
    console.log("Input changed", input);

    this._api.setDataForms({
      function: "onChangeKeyboard", 
      event: input
    }
    )

  };

  onKeyPressKeyboard = (button: string) => {
    console.log("Button pressed", button);
    
    this._api.setDataForms({
      function: "onKeyPressKeyboard", 
      event: button
    }
    )

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();

    if (button === "{enter}") {
      this.showKeyboard = false; 
    }
    if (button === "{btmu}") {
      this.showKeyboard = false; 
      this._api.setData("showMenu");
      this.router.navigate(['/menu']);
    }

  };


  ngOnInit(): void {

    this._api.getNewDataForm.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (data: any) => {
        if(data == "closeKeyBoard") {
          this.keyboard.clearInput()
          this.showKeyboard = false;
        }

        if (data["function"] && data["function"] == 'onInputFocus') {
console.log(data)
console.log(data["event"])
console.log(data["event"].detail)
console.log(data["event"].detail.srcElement)
console.log(data["event"].detail.srcElement.inputMode)
          if (data["event"].detail.srcElement.inputMode == 'tel') {
            // lo forzamos a mostrarse con el layout default por si tenia el modo shift anteriormente
            this.keyboard.options.layoutName = "default";
            this.keyboard.setOptions({
              layout: {
                default: ["1 2 3", "4 5 6", "7 8 9", "{btmu} 0 {bksp}"],
                shift: ["! / #", "$ % ^", "& * (", "{shift} ) +"]
              },
              theme: "hg-theme-default hg-layout-numeric numeric-theme"
            })
          } else if (data["event"].detail.srcElement.inputMode == 'text' || data["event"].detail.srcElement.inputMode == 'email') {
            this.keyboard.setOptions({
              layout: {
                'default': [
                  '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                  'q w e r t y u i o p [ ] \\',
                  'a s d f g h j k l ; \' {enter}',
                  '{shift} z x c v b n m , . /',
                  '.com @ {space}'
                ],
                'shift': [
                  '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
                  'Q W E R T Y U I O P { } |',
                  'A S D F G H J K L : " {enter}',
                  '{shift} Z X C V B N M &lt; &gt; ?',
                  '.com @ {space}'
                ]
              },
              theme: "hg-theme-default theme-completed-keys"
            })
          }

          this.onInputFocus(data["event"])

        }

        if (data["function"] && data["function"] == 'closeKeyBoard') {
          this.showKeyboard = false;
        }

        if (data["function"] && data["function"] == 'clearInput') {
          this.showKeyboard = false;
          this.keyboard.clearInput()
        }




      }
    )

    

    

this.currentLanguage = this.langService.getCurrentLang();

    this.langService.onLangChange().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (result: LangChangeEvent) => {

        this.currentLanguage = result.lang


        if(this.currentLanguage == 'ar'){
          this.keyboard.setOptions({
            rtl: true,
            ...layout
          })
        }else{
          this.keyboard.setOptions({
            rtl: false,
            layout: null

          })
        }
      }
    )

    
   
  }


  onInputFocus = (event: any) => {

    this.inputName = event.target.id;
    this.showKeyboard = true;
    //this.clickInside()
    console.log("Focused input", this.inputName);

    this.keyboard.setOptions({
      inputName: event.target.id
    });

    this._api.setDataForms({
      keyboard: this.keyboard
    }
    )
  };

  setInputCaretPosition = (elem: any, pos: number) => {
    if (elem.setSelectionRange) {
      elem.focus();
      elem.setSelectionRange(pos, pos);
    }
  };

  onInputChange = (event: any) => {

    this.keyboard.setInput(event.target.value);

    this._api.setDataForms({
      function: "onInputChange", 
      event: event
    }
    )

  };

  handleShift = () => {
    let currentLayout = this.keyboard.options.layoutName;
    //let shiftToggle = currentLayout === "default" ? "shift" : "default";
    this.keyboard.setOptions({
      layoutName: currentLayout
    });
  };

  onChange = (input: string) => {
    this.inputs[this.inputName] = input;
    console.log("Input changed", input);


    this._api.setDataForms({
      function: "onChange", 
      event: input
    }
    )
    /**
     * Synchronizing input caret position
     * This part is optional and only relevant if using the option "preventMouseDownDefault: true"
     */

    if(this.keyboard){


    let caretPosition = this.keyboard.caretPosition;

    if (caretPosition !== null)
      this.setInputCaretPosition(
        document.querySelector(`#${this.inputName}`),
        caretPosition
      );

    console.log("caretPosition", caretPosition);
  }
  };


  isFocusInsideComponent = false;
isComponentClicked = false;

@HostListener('click')
clickInside() {
    this.isFocusInsideComponent = true;
    this.isComponentClicked = true;
    console.log("clickInside")
}

@HostListener('document:click')
clickout() {
  console.log("clickout")
    if ((!this.isFocusInsideComponent && this.isComponentClicked)) {
        // do the heavy process
        this.isComponentClicked = false;
    }
    this.isFocusInsideComponent = false;
}

}