import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@environments/environment';
import {
  CustomizerAllowedSides,
  CustomizerAllowedSizesInterface,
  CustomizerSides,
  MenuCustomToppingGroupInterface,
  MenuFamilyCustomToppingInterface,
  PgCustomizer
} from '@lib/utils';
import { CurrentItemsInterface } from '@pages/customizer/customizer.page';
import { ModalService } from '@services/modal.service';
import { LangService } from '@services/lang.service';
import { Platform } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LangChangeEvent } from '@ngx-translate/core';
import { PlatformService } from '@services/platform.service';
import { OnActionEventInterface } from '@core/components';
import { DeliveryService } from '@services/delivery.service';
import { StorageService } from '@services/storage.service';
import { kiosk } from '@core/constants';
import { Router } from '@angular/router';
import { isGroundr, isMaestro } from '@core/functions';

export enum ToppingListSelectionMode {
  Divided = 'D',
  Full = 'F'
}

export interface ToppingListSideInterface {
  id: CustomizerSides
  name: string
}

@Component({
  selector: 'app-topping-list',
  templateUrl: './topping-list.component.html',
  styleUrls: ['./topping-list.component.scss','./topping-list.groundr.component.scss']
})
export class ToppingListComponent implements OnInit {
  isGroundr: boolean = isGroundr()
  isMaestro: boolean = isMaestro()
  numberOfItems: number = 0;
  isWizard : boolean = false
  @Input() set currentData(value: CurrentItemsInterface) {
    if (value && value.family && value.menu && value.product) {
      if (this.selectionMode !== value.selectionMode) {
        this.currentSide = value.currentSide
        this.selectionMode = value.selectionMode
        value.forceReload = true
      }
      let updateToppings: boolean = (value.forceReload) ? true : ((!this.current.family || !this.current.product) || (this.current.family.id !== value.family.id) || (this.current.product.id !== value.product.id))
      let updateCurrentToppings: boolean = (value.forceReload) ? true : (JSON.stringify(this.current.recipeToppings) !== JSON.stringify(value.recipeToppings))
      if (updateToppings) {
        this.current = this.pgCustomizer.copy(value)
        this.setToppings()
      }
      if (updateCurrentToppings) {
        if ((JSON.stringify(this.current.toppings) !== JSON.stringify(value.toppings))) {
          this.current.forceUpdate = true
          this.current.doughs = value.doughs
          this.current.toppings = value.toppings
          this.current.recipeToppings = value.recipeToppings
          this.setToppings()
        } else {
          this.current.recipeToppings = value.recipeToppings
          this.updateCurrentRecipeToppings(this.current.recipeToppings)
        }
      }
      this.current.forceReload = false
    }
  }
  currentStepTopping: number = 0;
  //Edit
  @Output() currentStepToppingChange = new EventEmitter<number>();

  @Input() set currentStep(value: number) {
    this.currentStepTopping = (value === 99 ? 0 : value)
  }
  @Input() isLoyalty: boolean;
  @Output() onToppingsChange: EventEmitter<MenuFamilyCustomToppingInterface[]> = new EventEmitter()
  @Output() onUpdateCurrentSide = new EventEmitter<CustomizerSides>()
  @Output() onUpdateSelectionMode = new EventEmitter<ToppingListSelectionMode>()
  current: CurrentItemsInterface
  doughs: MenuFamilyCustomToppingInterface[]
  isHalfNdHalf: boolean
  visible: boolean = true
  pgCustomizer: PgCustomizer
  sides: ToppingListSideInterface[]
  toppingGroups: MenuCustomToppingGroupInterface[]
  toppingsUrl: string = environment.assetsUrl
  defaultToppingsShowed: number = 3;
  defaultToppingsShowedInCustom: number = 0;
  private unsubscribe = new Subject<void>()
  showCustomDough: boolean = true;
  isKiosk: boolean = kiosk
  kioskBranch: any
  assets: string = environment.assetsUrl;
  currentSide: CustomizerSides = CustomizerSides.whole
  customizerSides = CustomizerSides
  selectionMode: ToppingListSelectionMode = ToppingListSelectionMode.Full
  selectionModes: typeof ToppingListSelectionMode = ToppingListSelectionMode
  productsUrl: string = environment.productsUrl
  customizerAllowedSides: CustomizerAllowedSizesInterface = CustomizerAllowedSides
  constructor(
    private readonly modalService: ModalService,
    private readonly langService: LangService,
    private platform: Platform,
    private readonly platformService: PlatformService,
    private readonly deliveryService: DeliveryService,
    private readonly storageService: StorageService,
    private elRef: ElementRef,
    private readonly router: Router,
  ) {
    this.current = {
      currentSide: null,
      dough: null,
      doughs: null,
      family: null,
      forceReload: false,
      forceUpdate: false,
      menu: null,
      product: null,
      promoEngineOptions: null,
      promoLine: null,
      selectionMode: null,
      stockProducts: null,
      stockToppings: null,
      recipeToppings: null,
      toppings: null
    }
    this.doughs = []
    this.isHalfNdHalf = false
    this.toppingGroups = []
    this.pgCustomizer = new PgCustomizer(null, null, {
      families: [],
      majorGroups: [],
      toppingGroups: []
    }, this.toppingsUrl)
    this.sides = []
    if(this.router.url == "/wizard") {
      this.isWizard = true;
    }
  }

  expandCollapse() {
    this.visible ? this.visible = false : this.visible = true;
  }

  incrementCurrentStepTopping() {
    this.currentStepTopping++;
    this.currentStepToppingChange.emit(this.currentStepTopping); //Edit
  }

  ngAfterViewChecked() {
    // Como hay algunos que hay 8 elementos o menos, no debe aparecer.
    this.updateScrollBarVisibility();
  }

  private updateScrollBarVisibility() {
    const cardProducts = this.elRef.nativeElement.querySelectorAll('ion-item');
    this.numberOfItems = cardProducts.length;
    const scrollBarElement = this.elRef.nativeElement.querySelector('.scrollBar');
    if (scrollBarElement) {
      if (this.numberOfItems <= 7) {
        scrollBarElement.style.overflowY = 'hidden';
      } else {
        scrollBarElement.style.overflowY = 'scroll';
      }
    }
  }

  ngOnInit(): void {

    this.toppingGroups.forEach(tg => {
      if(tg.mandatoryCondition) {
        this.visible = true;
        return;
      } else {
        this.visible = false;
      }
    })

    if (this.doughs.length == 0) {
      this.currentStepTopping++
    }

    this.platform.ready().then(
      () => {
        this.langService.onLangChange().pipe(
          takeUntil(this.unsubscribe)
        ).subscribe(
          (result: LangChangeEvent) => {
            this.updateSidesConfig()
          }
        )
      }
    )
  }
  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  addTopping(t: MenuFamilyCustomToppingInterface, side?: CustomizerSides): void {
    let addedResult: boolean = false
    if (t.id === '671LESS') {
      this.pgCustomizer.modifyTopping(t, t.side, this.pgCustomizer.toggleBinaryResult(t.quantity))
      addedResult = true
    } else if (t.id === '671') {
      if (t.quantity == 1) {
        this.pgCustomizer.modifyTopping(t, (side ? side : t.side), 2)
      } else {
        this.pgCustomizer.modifyTopping(t, (side ? side : t.side), 1)
      }
      addedResult = true
    } else {
      let newSide: CustomizerSides = t.side
      if (side) {
        if (t.quantity === 0) {
          newSide = side
        } else {
          if (side === CustomizerSides.whole) {
            newSide = side
          } else {
            if (t.side !== CustomizerSides.whole) {
              if ((t.side === CustomizerSides.left && side === CustomizerSides.right) || (t.side === CustomizerSides.right && side === CustomizerSides.left)) {
                newSide = CustomizerSides.whole
              } else {
                newSide = side
              }
            }
          }
        }
      }
      addedResult = this.pgCustomizer.addTopping(
        t,
        newSide,
        this.current.promoEngineOptions,
        this.current.promoLine
      )
    }


    this.updateView(addedResult, t)
    this.currentStepTopping++;

  }

  isCompletedDividedPizza(): boolean {
    let containsLeft = false;
    let containsRight = false;
    let containsDoble = false
    this.pgCustomizer.getToppings().forEach(tp => {
      if(tp.quantity > 0) {
        if(tp.side == this.customizerSides.whole) {
          containsDoble = true;
        } else if(tp.side == this.customizerSides.left) {
        }else if(tp.side == this.customizerSides.right) {
          containsRight = true;
        }
      }});
    if(containsLeft && containsRight || containsDoble) {
      return true;
    } else {
    return false;
  } 
}

  checkCurrentDough(): void {
    this.doughs.forEach(
      value => {
        if (value.quantity > 0) {
          this.current.dough = value.id
        }
      }
    )
  }



  getCurrentToppings(): void {
    const result: MenuFamilyCustomToppingInterface[] = this.pgCustomizer.getDoughs().concat(this.pgCustomizer.getToppings())
    this.onToppingsChange.emit(result)
  }
  setSideName(): string {
    let sideName: string = ''
    switch (this.currentSide) {
      case CustomizerSides.left:
        sideName = 'left'
        break
      case CustomizerSides.right:
        sideName = 'right'
        break
      default:
        sideName = 'whole'
        break
    }
    return sideName
  }
  setToppings(): void {
    this.pgCustomizer = new PgCustomizer(this.current.family, this.current.product, this.current.menu, this.toppingsUrl, this.current.stockToppings)
    if (this.current.forceUpdate === true) {
      this.pgCustomizer.updateDoughs(this.current.doughs)
      this.pgCustomizer.updateRecipeToppings(this.current.recipeToppings)
      this.pgCustomizer.updateToppings(this.current.toppings)
      this.current.forceUpdate = false
    }
    // this.pgCustomizer.setDoughsByDefault()
    this.updateSidesConfig()
    this.doughs = this.pgCustomizer.getDoughs()
    this.updateToppingGroups()
    this.checkCurrentDough()
  }
  updateCurrentRecipeToppings(t: MenuFamilyCustomToppingInterface[]): void {
    this.pgCustomizer.updateRecipeToppings(t)
    this.updateToppingGroups()
  }
  updateCurrentSide(event): void {
    if (this.selectionMode === ToppingListSelectionMode.Full) {
      if (this.currentSide !== CustomizerSides.whole) {
        this.currentSide = CustomizerSides.whole
        this.onUpdateCurrentSide.emit(this.currentSide)
        this.updateSidesConfig()
      }
    } else if (this.selectionMode === ToppingListSelectionMode.Divided) {
      if (this.currentSide === CustomizerSides.left && event.offsetX >= 172) {
        this.currentSide = CustomizerSides.right
      } else if (this.currentSide === CustomizerSides.right && event.offsetX >= 172) {
        this.currentSide = CustomizerSides.left
      }
      this.onUpdateCurrentSide.emit(this.currentSide)
      this.updateSidesConfig()
    }
  }
  updateSelectionMode(event: CustomEvent): void {
    const mode: ToppingListSelectionMode = event.detail.value
    this.onUpdateSelectionMode.emit(mode)
  }
  updateSidesConfig(): void {
    this.isHalfNdHalf = this.pgCustomizer.checkProductCustom()
    this.sides = [
      {
        'id': this.currentSide,
        'name': 'whole'
      }
    ]
  }
  updateToppingGroups(): void {
    const tGroups = this.pgCustomizer.getToppingGroups(true)
    tGroups.forEach(
      value => {
        const tGroup = this.pgCustomizer.findInArray(this.toppingGroups, 'id', value.id)
        if (tGroup) {
          value.showMore = tGroup.showMore
        }
      }
    )
    this.toppingGroups = tGroups;
    this.toppingGroups.forEach(
      value => {
        
        value.toppings.sort(function (a, b) {
          if (a.recipeQuantity > 0 && b.recipeQuantity > 0) {
            return 0
          } else if (a.recipeQuantity === 0 && b.recipeQuantity > 0) {
            return 1
          } else if (a.recipeQuantity > 0 && b.recipeQuantity === 0) {
            return -1
          } else {
            return a.order - b.order
          }
        })
      }
    )
  }
  updateView(addedResult: boolean, t: MenuFamilyCustomToppingInterface): void {
    this.doughs.forEach(
      value => {
        const topping: MenuFamilyCustomToppingInterface = this.pgCustomizer.findInArray(this.pgCustomizer.getDoughs(), 'id', value.id)
        value.quantity = topping.quantity
      }
    )
    this.updateToppingGroups()
    this.checkCurrentDough()
    this.getCurrentToppings()
    if (addedResult) {
    } else {
      const toppingGroup: MenuCustomToppingGroupInterface = this.pgCustomizer.getToppingGroups().find(g => g.id === t.grouppingID)
      const errorModal: Promise<any> = this.modalService.openModalToppingError(t, toppingGroup)//(t.quantity > 0) ? this.modalService.openModalWithCustomMsgNdChooseOption(1018, t.description) : this.modalService.openModalWithCustomMsg('LANG_TOPPING_LIMIT_EXCEEDED')
      errorModal.then(
        (resp: any) => {
          try {
            if (resp.data.result === true) {
              this.pgCustomizer.removeTopping(t)
              this.updateToppingGroups()
              this.getCurrentToppings()
            }
          } catch (e) { }
        }
      )
    }
  }
  showMoreOrLess(group: MenuCustomToppingGroupInterface) {
    if (group.showMore) {
      this.toppingGroups.forEach(tg => {
        tg.showMore = false
      })
    } else {
      this.toppingGroups.forEach(tg => {
        tg.showMore = false
      })
      group.showMore = true
    }
  }
  showDoughs() {
    this.showCustomDough = !this.showCustomDough;
  }

  showInfoDough(dough) {
    this.modalService.openModalChooseDough(true, dough).then(
      (res) => {
      },
      () => {
      }
    )
  }
  errorHandler(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.toppingsUrl + "images/toppingsnotfound.png") {
        event.target.src = this.toppingsUrl + "images/toppingsnotfound.png"
      }
    }
  }

  errorHandlerProduct(event): void {
    if (!this.platformService.isServer()) {
      if (event.target.src != this.productsUrl + "images/productnotfound.png") {
        event.target.src = this.productsUrl + "images/productnotfound.png"
      }
    }
  }

  changeQuantity(event: OnActionEventInterface, topping: MenuFamilyCustomToppingInterface): void {
    if (event.action === 'addQty') {
      this.addTopping(topping)
    } else if (event.action === 'reduceQty') {
      if (topping.quantity > 0) {
        let isToppingRequired: boolean = (topping.requiredQuantity > 0)
        let minQty: number = isToppingRequired ? topping.requiredQuantity : 1
        if (topping.quantity > minQty) {
          this.pgCustomizer.modifyTopping(topping, topping.side, topping.quantity - 1)
        } else {
          if (!isToppingRequired) {
            this.pgCustomizer.removeTopping(topping)
          }
        }
        /*
        if (topping.quantity > 1) {
          this.pgCustomizer.modifyTopping(topping, topping.side, topping.quantity - 1)
        } else {
          this.pgCustomizer.removeTopping(topping)
        }
        */
        this.updateView(true, topping)
      }
    }
  }


  goBack() {
    this.router.navigate(['/menu'])
  }

  checkSelectedToppingSide(topping: MenuFamilyCustomToppingInterface, side: CustomizerSides): boolean {
    if (topping.quantity > 0) {
      if (side === CustomizerSides.left && this.customizerAllowedSides.leftSide.includes(topping.side)) {
        return true
      } else if (side === CustomizerSides.right && this.customizerAllowedSides.rightSide.includes(topping.side)) {
        return true
      } else if (side === CustomizerSides.whole) {
        return true
      }
    }
    return false
  }

  checkUnselectedToppingSide(topping: MenuFamilyCustomToppingInterface, side: CustomizerSides): boolean {
    if (topping.quantity === 0) {
      return true
    } else {
      if (side === CustomizerSides.left && !this.customizerAllowedSides.leftSide.includes(topping.side)) {
        return true
      } else if (side === CustomizerSides.right && !this.customizerAllowedSides.rightSide.includes(topping.side)) {
        return true
      }
    }
    return false
  }

  capitalizeFirstLetter(text: string): string {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
