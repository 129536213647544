import {
    MenuInterface,
    MenuMajorGroupInterface,
    MenuFamilyInterface,
    MenuFamilyProductInterface,
    MenuFamilyToppingInterface,
    PromoInterface,
    PromoTypes,
    CrossSalesSuggestIn,
    PromoOptions,
    ProductOptions,
    PromoDetailApplicableTo
} from '../../../promo-engine/public-api';
import { PgCommon } from './common';
import { PgWizard } from './wizard';

export interface MenuGetGroupAndSubGroupFromFamilyInterface {
    group: MenuGroupInterface
    subGroup: MenuSubGroupInterface
}

export interface MenuSubGroupScrollPositionInterface {
    firstProduct: number
    lastProduct: number
}

export interface MenuSubGroupInterface {
    baseProducts: { [key: number]: string }
    customProducts: { [key: number]: string }
    families: MenuFamilyInterface[]
    id: number
    hasCustomProduct: boolean
    hasProductBase: boolean
    name: string
    products: MenuFamilyProductInterface[]
    promotions: PromoInterface[]
    scrollPosition: MenuSubGroupScrollPositionInterface
}

export interface MenuGroupInterface extends MenuMajorGroupInterface {
    hasCustomProduct: boolean
    hasProductBase: boolean
    isEditable: boolean
    subGroups: MenuSubGroupInterface[]
}

export class PgMenu extends PgCommon {
    private readonly groups: MenuGroupInterface[] = []
    private readonly menu: MenuInterface
    private readonly promotions: PromoInterface[] = []
    constructor(menu: MenuInterface, promotions: PromoInterface[], onlyShowFamiliesShowAtWeb: boolean = false) {
        super()
        if (promotions && Array.isArray(promotions) && promotions.length > 0) {
            this.promotions = promotions
        }
        if (menu && 'families' in menu && 'majorGroups' in menu && 'toppingGroups' in menu) {
            this.menu = this.setMenu(menu)
            this.groups = this.setGroups(onlyShowFamiliesShowAtWeb)
        }
        /*if (menu && promotions) {
            this.menu = this.setMenu(menu)
            this.promotions = promotions
            this.groups = this.setGroups(onlyShowFamiliesShowAtWeb)
        }*/
    }
    // Private
    private setMenu(menu: MenuInterface): MenuInterface {
        menu.families.sort((a, b) => a.order - b.order)
        menu.families.forEach(
            value => {
                if (value.hasOwnProperty('products')) {
                    value.products.sort((a, b) => a.order - b.order)
                }
                if (value.hasOwnProperty('toppings')) {
                    value.toppings.sort((a, b) => a.order - b.order)
                }
            }
        )
        menu.majorGroups.sort((a, b) => a.order - b.order)
        return menu
    }
    private setProducts(families: MenuFamilyInterface[]): MenuFamilyProductInterface[] {
        const list: { [key: number]: MenuFamilyProductInterface } = {}
        families.forEach(
            value => {
                value.products.forEach(
                    v => {
                        const showAtWeb: boolean = ('showAtWeb' in v) ? (v.showAtWeb === 'S') : true
                        if (showAtWeb) {
                            const resultId: string = this.getCommonProductId(value, v)
                            if (!list.hasOwnProperty(resultId)) {
                                list[resultId] = v
                            }
                        }
                    }
                )
            }
        )
        return this.toArray<MenuFamilyProductInterface>(list).sort((a, b) => a.order - b.order)
    }
    // Public
    public getCrossSalesFromProduct(product: MenuFamilyProductInterface, opt: ProductOptions | PromoOptions): PgWizard[] {
        return this.getPromotions()
            .filter(p => ((p.promoType === PromoTypes.CrossSales) && (p.crossSuggestIn === CrossSalesSuggestIn.Menu)))
            .reduce<PgWizard[]>(
                (result, promo) => {
                    const pgWizard = new PgWizard(this.getMenu(), promo, [promo], opt)
                    const isValid: boolean = pgWizard.isValid()
                    if (isValid) {
                        if (product !== null) {
                            const findCodes: string[] = promo.crossFindCode.split(';').filter(Boolean)
                            const findType: PromoDetailApplicableTo = promo.crossFindType
                            const findID: string = (findType === PromoDetailApplicableTo.group ? product.majorGroupID.toString() : (findType === PromoDetailApplicableTo.saleItem ? product.id : product.familyGroupID.toString()))
                            const isProductValid: boolean = findCodes.includes(findID)
                            if (isProductValid) {
                                result.push(pgWizard)
                            }
                        } else {
                            result.push(pgWizard)
                        }
                    }
                    return result
                }, []
            )
    }
    public getFamily(id: number): MenuFamilyInterface {
        let position: number = this.findIndexInArray(this.menu.families, 'id', id)
        return position >= 0 ? this.copy(this.menu.families[position]) : null
    }
    public getFamilyTopping(familyID: number, toppingID: string): MenuFamilyToppingInterface {
        const family: MenuFamilyInterface = this.getFamily(familyID)
        const topping: MenuFamilyToppingInterface = this.findInArray(
            (family && 'toppings' in family && Array.isArray(family.toppings) ? family.toppings : []),
            'id',
            toppingID
        )
        return topping ? this.copy(topping) : null
    }
    public getGroup(groupID: number): MenuGroupInterface {
        return this.copy(this.findInArray(this.getGroups(), 'id', groupID))
    }
    public getGroups(): MenuGroupInterface[] {
        return this.groups
    }
    public getLoyaltyGroups(points, showRedeemable): MenuGroupInterface[] {
        const groups: MenuGroupInterface[] = this.getGroups().reduce(
            (loyaltyGroups: MenuGroupInterface[], group) => {
                const fullSubgroup = group.subGroups.reduce(
                    (result: MenuSubGroupInterface, subgroup) => {
                        if (subgroup.promotions.length === 0) {
                            if (result === null) {
                                result = this.copy(subgroup)
                                result.hasCustomProduct = false
                                result.hasProductBase = false
                                result.name = group.shortName
                                result.products = []
                            } else {
                                result.baseProducts = {
                                    ...result.baseProducts,
                                    ...subgroup.baseProducts
                                }
                                result.customProducts = {
                                    ...result.customProducts,
                                    ...subgroup.customProducts
                                }
                                result.families = result.families.concat(subgroup.families)
                            }
                            const customProductsList: string[] = Object.values(subgroup.customProducts)
                            result.products = result.products.concat(
                                subgroup.families.reduce(
                                    (products, family) => {
                                        products = products.concat(
                                            family.products.reduce(
                                                (loyaltyProducts: MenuFamilyProductInterface[], product) => {
                                                    if ((customProductsList.indexOf(product.id) < 0 && customProductsList.indexOf(product.erpID) < 0) && product.points > 0) {
                                                        if (!showRedeemable) {
                                                            product["size"] = family.size
                                                            loyaltyProducts.push(product)
                                                        } else {
                                                            if (product.points <= points) {
                                                                product["size"] = family.size
                                                                loyaltyProducts.push(product)
                                                            }
                                                        }

                                                    }
                                                    return loyaltyProducts
                                                }, []
                                            )
                                        )
                                        return products
                                    }, []
                                )
                            )
                            if (result.products.length === 0) {
                                result = null
                            }
                        }
                        return result
                    }, null
                )
                if (fullSubgroup !== null) {
                    group.hasCustomProduct = false
                    group.hasProductBase = false
                    group.subGroups = [fullSubgroup]
                    loyaltyGroups.push(group)
                }
                return loyaltyGroups
            }, []
        )
        return groups
    }
    public getMenu(): MenuInterface {
        return this.menu
    }
    public getGroupAndSubGroupFromFamily(familyID: number): MenuGetGroupAndSubGroupFromFamilyInterface {
        const family: MenuFamilyInterface = this.getFamily(familyID)
        const group: MenuGroupInterface = this.findInArray(this.getGroups(), 'id', family.webSalesGroup) || null
        const subGroup: MenuSubGroupInterface = this.findInArray((group && group.subGroups) ? group.subGroups : [], 'id', family.webSubsalesGroup) || null
        return {
            group,
            subGroup
        }
    }
    public getProduct(id: string): MenuFamilyProductInterface {
        let product: MenuFamilyProductInterface = null
        this.menu.families.forEach(
            value => {
                const position: number = this.findIndexInArray(value.products, 'id', id)
                if (position >= 0) {
                    product = this.copy(value.products[position])
                }
            }
        )
        return product
    }
    public getPromotions(): PromoInterface[] {
        return this.promotions
    }
    public isFamilyVisibleAtWeb(family: MenuFamilyInterface): boolean {
        return family.showAtWeb === 'S'
    }
    public setGroups(onlyShowFamiliesShowAtWeb: boolean): MenuGroupInterface[] {
        const groups: MenuGroupInterface[] = []
        const promotionsToShow: PromoInterface[] = this.filterInArray(this.promotions, 'showWebHome', 'S')
        this.menu.majorGroups.forEach(
            value => {
                let families: MenuFamilyInterface[] = []
                /*this.filterInArray(this.menu.families, 'webSalesGroup', value.id)
                if (onlyShowFamiliesShowAtWeb === true) {
                    families = this.filterInArray(families, 'showAtWeb', 'S')
                }*/
                this.menu.families.forEach(
                    v => {
                        if (v.webSalesGroup === value.id) {
                            if (onlyShowFamiliesShowAtWeb === true) {
                                if (this.isFamilyVisibleAtWeb(v)) {
                                    families.push(v)
                                }
                            } else {
                                families.push(v)
                            }
                            // Checking Promotions
                            const promos: PromoInterface[] = this.filterInArray(promotionsToShow, 'familyGroupWebShow', v.id)
                            const isAdded: boolean = this.someInArray(families, 'id', v.id)
                            if (promos.length > 0 && !isAdded) {
                                families.push(v)
                            }
                        }
                    }
                )
                if (families.length > 0) {
                    const group: MenuGroupInterface = {
                        ...value,
                        hasCustomProduct: false,
                        hasProductBase: false,
                        isEditable: false,
                        subGroups: []
                    }
                    families.forEach(
                        v => {
                            const that: this = this
                            const customProduct: MenuFamilyProductInterface = v.products.find(p => that.checkCustomProduct(v, group, p)) || null
                            const hasProductBase: boolean = v.products.some(p => that.checkBaseProduct(v, p))
                            const position: number = this.findIndexInArray(group.subGroups, 'id', v.webSubsalesGroup)
                            if (position >= 0) {
                                group.subGroups[position].baseProducts[v.id] = v.baseProduct
                                group.subGroups[position].customProducts[v.id] = customProduct?.id || ''
                                group.subGroups[position].families.push(v)
                                if (!group.subGroups[position].hasCustomProduct) {
                                    group.subGroups[position].hasCustomProduct = (customProduct && 'id' in customProduct)
                                }
                                if (!group.subGroups[position].hasProductBase) {
                                    group.subGroups[position].hasProductBase = hasProductBase
                                }
                            } else {
                                group.subGroups.push({
                                    baseProducts: {
                                        [v.id]: v.baseProduct
                                    },
                                    customProducts: {
                                        [v.id]: customProduct?.id || ''
                                    },
                                    families: [v],
                                    hasCustomProduct: (customProduct && 'id' in customProduct),
                                    hasProductBase: hasProductBase,
                                    id: v.webSubsalesGroup,
                                    name: v.shortName,
                                    products: [],
                                    promotions: [],
                                    scrollPosition: {
                                        firstProduct: 0,
                                        lastProduct: 0
                                    }
                                })
                            }
                            // Se comprueba si alguna de las familias de todo el grupo, se puede customizar
                            if (v.toppings.length > 0 && group.toppingsAllowed === 'S') {
                                group.isEditable = true
                            }
                        }
                    )
                    group.subGroups.forEach(
                        value => {
                            value.products = this.setProducts(value.families)
                            value.families.forEach(
                                val => {
                                    this.filterInArray(promotionsToShow, 'familyGroupWebShow', val.id).forEach(
                                        v => {
                                            if (this.findIndexInArray(value.promotions, 'id', v.id) < 0) {
                                                value.promotions.push(v)
                                            }
                                        }
                                    )
                                }
                            )
                            value.promotions.sort((a, b) => a.order - b.order)
                            // Se comprueba si tiene producto custom
                            if (value.hasCustomProduct) {
                                group.hasCustomProduct = value.hasCustomProduct
                            }
                            // Se comprueba si tiene producto base
                            if (value.hasProductBase) {
                                group.hasProductBase = value.hasProductBase
                            }
                        }
                    )
                    groups.push(group)
                }
            }
        )
        return groups
    }
}