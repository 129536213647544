import {
    MenuFamilyInterface,
    MenuFamilyProductInterface,
    MenuFamilyProductToppingInterface,
    MenuFamilyToppingInterface,
    MenuInterface,
    ProductOptions,
    MenuToppingGroupInterface,
    OrderLineTypes,
    ProductCalculatedResponse,
    PromoLineInterface
} from '../../../promo-engine/public-api';
import { CounterInterface, PgCommon } from './common';
import {
    MenuGetGroupAndSubGroupFromFamilyInterface,
    MenuGroupInterface,
    MenuSubGroupInterface,
    PgMenu
} from './menu';
import { CreateOrderLineItemsInterface, PgOrderLineToppingsInterface } from './order';

export enum CustomizerSides {
    left = 'L',
    right = 'R',
    whole = 'W'
}

export const CustomizerAllowedSides: CustomizerAllowedSizesInterface = {
    leftSide: [
        CustomizerSides.left,
        CustomizerSides.whole
    ],
    rightSide: [
        CustomizerSides.right,
        CustomizerSides.whole
    ]
}

export interface CustomizerCheckMinToppingGroupsInterface {
    [key: number]: boolean
}

export interface CustomizerCheckMaxPropertiesInterface<T> {
    group: T
    product: T
    // promotion: T
}

export interface CustomizerCheckMinPropertiesInterface<T, V> {
    group: V
    product: T
    // promotion: T
}

export type CustomizerCheckMaxSidesType = keyof CustomizerSidesAliasInterface<string>

export interface CustomizerSidesAliasInterface<T> {
    leftSide: T
    rightSide: T
}

export interface CustomizerAllowedSizesInterface extends CustomizerSidesAliasInterface<CustomizerSides[]> { }

export interface CustomizerCheckMaxInterface extends CustomizerSidesAliasInterface<CustomizerCheckMaxPropertiesInterface<boolean>> { }

export interface CustomizerCheckMinInterface extends CustomizerCheckMinPropertiesInterface<boolean, CounterInterface<boolean>> { }

export interface CustomizerToppingsActionInterface extends CustomizerSidesAliasInterface<boolean> { }

export interface CustomizerToppingsSidesPositionInterface extends CustomizerSidesAliasInterface<number> { }

export interface MenuCustomToppingGroupInterface extends MenuToppingGroupInterface {
    chooseCondition: boolean
    chooseUpToCondition: boolean
    hasToppings: boolean
    mandatoryCondition: boolean
    showMore: boolean
    showOnlyRecipeToppings: boolean
    showOnlyRequiredToppings: boolean
    toggleToppings: boolean
    toppings: MenuFamilyCustomToppingInterface[]
}

export interface MenuFamilyCustomToppingInterface extends MenuFamilyToppingInterface {
    imageUrl: string
    isRequired: boolean
    quantity: number
    side: CustomizerSides
    stock: boolean
    recipeQuantity: number
    requiredQuantity: number
}

export interface UpdateToppingInterface {
    toppingPosition: number
    toppingProperty: string
}

export class PgCustomizer extends PgCommon {
    private assetsUrl: string
    private availableFamilies: MenuFamilyInterface[]
    private doughGroupID: number | string
    private swirlGroupID: number | string
    private doughRequired: boolean
    private doughs: MenuFamilyCustomToppingInterface[]
    private family: MenuFamilyInterface
    private group: MenuGroupInterface = null
    private lastDoughSelected: string = ''
    private mustSelectDough: boolean
    private pgMenu: PgMenu
    private product: MenuFamilyProductInterface
    private productHasRecipe: boolean
    private selectedDoughs: CounterInterface<string> = {}
    private size: string
    private stockProducts: CounterInterface<boolean> = null
    private stockToppings: CounterInterface<boolean> = null
    private subgroup: MenuSubGroupInterface = null
    private toppingGroups: MenuCustomToppingGroupInterface[]
    private toppings: MenuFamilyCustomToppingInterface[]
    private toppingsDescription: string
    constructor(
        f: MenuFamilyInterface,
        p: MenuFamilyProductInterface,
        menu: MenuInterface,
        assetsUrl: string,
        stockToppings: CounterInterface<boolean> = null,
        mustSelectDough: boolean = true
    ) {
        super()
        this.assetsUrl = assetsUrl
        this.pgMenu = new PgMenu(menu, [])
        this.doughGroupID = 1
        this.swirlGroupID = 4
        this.mustSelectDough = mustSelectDough
        if (stockToppings) {
            this.updateStockToppings(stockToppings)
        }
        if (f && p) {
            this.updateFamily(f)
            this.updateProduct(p)
            if (this.availableFamilies.length > 0) {
                this.initSelectedDoughs()
            }
        }
    }
    addTopping(
        t: MenuFamilyCustomToppingInterface | MenuFamilyToppingInterface,
        side: CustomizerSides,
        promoEngineOptions: ProductOptions,
        promoLine: PromoLineInterface,
        ignoreCheck: boolean = false
    ): boolean {
        let isDough: boolean = (t.grouppingID == this.doughGroupID)
        let doughPosition: number = this.findIndexInArray(this.doughs, 'id', t.id)
        let toppingPosition: number = isDough ? doughPosition : this.findIndexInArray(this.toppings, 'id', t.id)
        let toppingProperty: string = isDough ? 'doughs' : 'toppings'
        let toppingGroup = this.getToppingGroups().find(g => g.id === t.grouppingID)
        let result: boolean = true
        if (toppingPosition >= 0) {
            const topping: MenuFamilyCustomToppingInterface = this[toppingProperty][toppingPosition]
            if (isDough) {
                this.doughs.forEach(
                    value => {
                        value.quantity = 0
                    }
                )
                topping.quantity = 1
            } else if (toppingGroup.toggleToppings && !this.checkProductCustom()) {
                let isAdded: boolean = JSON.parse(JSON.stringify(topping.quantity > 0))
                // result = !isAdded
                if (!isAdded) {
                    this.toppings.forEach(
                        value => {
                            if (value.grouppingID === t.grouppingID) {
                                value.quantity = 0
                            }
                        }
                    )
                    topping.quantity = 1
                }
            } else {
                const checkMaxResult: CustomizerCheckMaxInterface = this.checkMax(topping, promoEngineOptions, promoLine, ignoreCheck)
                let checkProperties: CustomizerCheckMaxSidesType[] = []
                if (topping.side === side) {
                    if (topping.quantity > 2) {
                        topping.quantity = topping.requiredQuantity
                    } else {
                        if (topping.side === CustomizerSides.whole) {
                            checkProperties = [
                                'leftSide',
                                'rightSide'
                            ]
                        } else if (topping.side === CustomizerSides.left) {
                            checkProperties = [
                                'leftSide'
                            ]
                        } else if (topping.side === CustomizerSides.right) {
                            checkProperties = [
                                'rightSide'
                            ]
                        }
                    }
                } else {
                    if (topping.side === CustomizerSides.whole) {
                        if (topping.quantity === 0) {
                            if (side === CustomizerSides.left) {
                                checkProperties = [
                                    'leftSide'
                                ]
                            } else if (side === CustomizerSides.right) {
                                checkProperties = [
                                    'rightSide'
                                ]
                            }
                        } else {
                            topping.side = side
                        }
                    } else if (topping.side === CustomizerSides.left) {
                        checkProperties = [
                            'rightSide'
                        ]
                    } else if (topping.side === CustomizerSides.right) {
                        checkProperties = [
                            'leftSide'
                        ]
                    }
                }
                if (checkProperties.length > 0) {
                    checkProperties.forEach(
                        value => {
                            if (checkMaxResult[value].group === false || checkMaxResult[value].product === false) {
                                result = false
                            }
                        }
                    )
                    if (topping.side === side) {
                        if (result) {
                            topping.quantity += 1
                        }
                    } else {
                        if (result) {
                            topping.quantity = 1
                            topping.side = side
                        }
                    }
                }
            }
        }
        this.setToppingGroups()
        return result
    }
    changeFamily(familyID: number): void {
        const position: number = this.findIndexInArray(this.availableFamilies, 'id', familyID)
        if (this.family.id !== familyID && position >= 0 && this.availableFamilies[position].products.length > 0) {
            this.updateFamily(this.availableFamilies[position])
            this.updateProduct(this.availableFamilies[position].products[0])
        }
    }
    checkDoughRequired(): void {
        let result: boolean = false
        if (this.productHasRecipe === true) {
            this.product.recipe.forEach(
                value => {
                    const position: number = this.findIndexInArray(this.getDoughs(), 'id', value.invSaleItemID)
                    if (position >= 0 && value.required === 'S') {
                        result = true
                    }
                }
            )
        }
        this.doughRequired = result
    }
    checkChooseConditionOfToppingGroup(toppingGroup: MenuToppingGroupInterface): boolean {
        return (toppingGroup.maxToppings === toppingGroup.minToppings) && toppingGroup.maxToppings > 1
    }
    checkChooseUpToConditionOfToppingGroup(toppingGroup: MenuToppingGroupInterface): boolean {
        return toppingGroup.minToppings === 0
    }
    checkMandatoryConditionOfToppingGroup(toppingGroup: MenuToppingGroupInterface): boolean {
        return (toppingGroup.maxToppings === toppingGroup.minToppings) && toppingGroup.maxToppings === 1
    }
    checkProductBase(): boolean {
        return this.checkBaseProduct(this.getCurrentFamily(), this.getCurrentProduct())
    }
    checkProductCustom(): boolean {
        return this.checkCustomProduct(
            this.getCurrentFamily(),
            this.getGroup(),
            this.getCurrentProduct()
        )
    }
    checkRequiredTopping(g: MenuCustomToppingGroupInterface, t: MenuFamilyCustomToppingInterface): boolean {
        if ((g.showOnlyRequiredToppings && t.requiredQuantity > 0) || !g.showOnlyRequiredToppings) {
            return true
        }
        return false
    }
    checkStockTopping(t: MenuFamilyCustomToppingInterface): boolean {
        return (this.stockToppings !== null && t.id in this.stockToppings) ? this.stockToppings[t.id] : true
    }
    checkStockToppings(): void {
        this.doughs.concat(this.toppings).forEach(
            value => {
                value.stock = this.checkStockTopping(value)
            }
        )
        this.toppingGroups.forEach(
            value => {
                value.toppings.forEach(
                    (v) => {
                        v.stock = this.checkStockTopping(v)
                    }
                )
            }
        )
    }
    isEditable(): boolean {
        return this.toppings.length > 0
    }
    isPizza(): boolean {
        return this.getCurrentFamily().majorGroupID == 1
    }
    modifyTopping(t: MenuFamilyCustomToppingInterface | MenuFamilyToppingInterface, side: CustomizerSides, quantity: number): UpdateToppingInterface {
        let isDough: boolean = (t.grouppingID == this.doughGroupID)
        let doughPosition: number = this.findIndexInArray(this.doughs, 'id', t.id)
        let toppingPosition: number = isDough ? doughPosition : this.findIndexInArray(this.toppings, 'id', t.id)
        let toppingProperty: string = isDough ? 'doughs' : 'toppings'
        if (toppingPosition >= 0) {
            let topping: MenuFamilyCustomToppingInterface = this[toppingProperty][toppingPosition]
            let toppingGroup = this.getToppingGroups().find(g => g.id === t.grouppingID)
            if (isDough) {
                this.doughs.forEach(
                    value => {
                        value.quantity = 0
                    }
                )
            }
            if (toppingGroup && toppingGroup.toggleToppings) {
                this.toppings.forEach(
                    value => {
                        if (value.grouppingID === toppingGroup.id) {
                            value.quantity = 0
                        }
                    }
                )
            }
            topping.quantity = quantity
            topping.side = side
        }
        this.setToppingGroups()
        return {
            toppingPosition,
            toppingProperty
        }
    }
    removeTopping(t: MenuFamilyCustomToppingInterface | MenuFamilyToppingInterface): UpdateToppingInterface {
        let toppingPosition: number = this.findIndexInArray(this.toppings, 'id', t.id)
        let toppingProperty: string = 'toppings'
        if (toppingPosition >= 0) {
            const topping: MenuFamilyCustomToppingInterface = this[toppingProperty][toppingPosition]
            if (t.id === topping.id) {
                topping.quantity = 0
            }
        }
        this.setToppingGroups()
        return {
            toppingPosition,
            toppingProperty
        }
    }
    // PgMenu
    getFamily(id: number): MenuFamilyInterface {
        return this.pgMenu.getFamily(id)
    }
    getProduct(id: string): MenuFamilyProductInterface {
        return this.pgMenu.getProduct(id)
    }
    // Doughs
    getDoughIDfromPreviousDoughID(previousDoughID: string): string {
        let doughList: CounterInterface<string[]> = {
            classic: [
                'SFCRF0012',
                'SFCRF0013'
            ],
            doubleCheddar: [
                'DO002',
                'DO003'
            ],
            thick: [
                'FGPZT3042',
                'FGPZT2042'
            ],
            thin: [
                '636',
                '635'
            ],
            thinBrown: [
                '204',
                '203'
            ],
            ultrathin: [
                'SFCRF0010',
                'SFCRF0009'
            ]
        }
        let doughID: string = Object.keys(doughList).reduce(
            (result: string, value: string) => {
                let position: number = doughList[value].indexOf(previousDoughID)
                if (position >= 0) {
                    result = doughList[value][1 - position]
                }
                return result
            }, ''
        )
        return doughID
    }
    getSelectedDoughs(): CounterInterface<string> {
        return this.copy(this.selectedDoughs)
    }
    setDoughsByDefault(): void {
        if (!this.isDoughRequired()) {
            this.doughs.forEach(
                value => {
                    value.quantity = (this.selectedDoughs[this.getCurrentFamily().id] === value.id) ? 1 : 0
                }
            )
            this.updateDoughs(this.doughs)
        }
    }
    initSelectedDoughs(): void {
        this.selectedDoughs = this.getAvailableFamilies().reduce(
            (result: CounterInterface<string>, value) => {
                result[value.id] = ''
                return result
            }, {}
        )
    }
    updateSelectedDough(items: CreateOrderLineItemsInterface = null): void {
        if (items && 'selectedDoughs' in items && Object.keys(items.selectedDoughs).length > 0) {
            for (let f in items.selectedDoughs) {
                this.selectedDoughs[f] = items.selectedDoughs[f]
            }
        } else {
            this.lastDoughSelected = this.getDoughs().reduce(
                (result: string, value) => {
                    if (value.quantity > 0) {
                        result = value.id
                    }
                    return result
                }, ''
            )
            let currentFamilyID: string = this.getCurrentFamily().id.toString()
            if (this.lastDoughSelected && this.lastDoughSelected.length > 0) {
                this.selectedDoughs[currentFamilyID] = this.lastDoughSelected
                Object.keys(this.selectedDoughs).filter(f => f !== currentFamilyID).forEach(
                    f => {
                        this.selectedDoughs[f] = this.getDoughIDfromPreviousDoughID(this.lastDoughSelected)
                    }
                )
            }
        }
    }
    // Checking Max
    checkMax(
        topping: MenuFamilyCustomToppingInterface,
        promoEngineOptions: ProductOptions,
        promoLine: PromoLineInterface,
        ignoreCheck: boolean,
        qty: number = 1
    ): CustomizerCheckMaxInterface {
        const result: CustomizerCheckMaxInterface = {
            leftSide: {
                group: ignoreCheck,
                product: ignoreCheck
            },
            rightSide: {
                group: ignoreCheck,
                product: ignoreCheck
            }
        }
        if (ignoreCheck !== true) {
            const leftSideToppings: string[] = this.getCurrentToppingsList(CustomizerAllowedSides.leftSide)
            const rightSideToppings: string[] = this.getCurrentToppingsList(CustomizerAllowedSides.rightSide)
            const leftSideToppingsCounter: CounterInterface = this.getToppingsQuantityForEachGroup(CustomizerAllowedSides.leftSide)
            const rightSideToppingsCounter: CounterInterface = this.getToppingsQuantityForEachGroup(CustomizerAllowedSides.rightSide)
            // const leftSideTotalToppingsCounter: number = Object.keys(leftSideToppingsCounter).reduce(
            //     (result, value) => result + leftSideToppingsCounter[value], 0
            // )
            // const rightSideTotalToppingsCounter: number = Object.keys(rightSideToppingsCounter).reduce(
            //     (result, value) => result + rightSideToppingsCounter[value], 0
            // )
            const leftSideCalculated: ProductCalculatedResponse = this.calculateProduct(
                promoEngineOptions,
                this.pgMenu.getMenu(),
                this.getCurrentProduct().id,
                leftSideToppings
            )
            if (leftSideCalculated) {
                result.leftSide.group = this.checkMaxToppingsGroup(
                    topping,
                    leftSideToppingsCounter[topping.grouppingID],
                    qty
                )
                result.leftSide.product = this.checkMaxToppingsProduct(
                    leftSideCalculated.total_num_ingredientes,
                    promoLine,
                    qty
                )
                // result.leftSide.promotion = (promoLine! && 'maxToppingsQtty' in promoLine) ? this.checkMaxPromoLine(promoLine, leftSideTotalToppingsCounter) : true
            }
            if (this.checkProductCustom()) {
                const rightSideCalculated: ProductCalculatedResponse = this.calculateProduct(
                    promoEngineOptions,
                    this.pgMenu.getMenu(),
                    this.getCurrentProduct().id,
                    rightSideToppings
                )
                if (rightSideCalculated) {
                    result.rightSide.group = this.checkMaxToppingsGroup(
                        topping,
                        rightSideToppingsCounter[topping.grouppingID],
                        qty
                    )
                    result.rightSide.product = this.checkMaxToppingsProduct(
                        rightSideCalculated.total_num_ingredientes,
                        promoLine,
                        qty
                    )
                    // result.rightSide.promotion = (promoLine! && 'maxToppingsQtty' in promoLine) ? this.checkMaxPromoLine(promoLine, rightSideTotalToppingsCounter) : true
                }
            } else {
                result.rightSide.group = true
                result.rightSide.product = true
            }
        }
        return result
    }
    checkMaxPromoLine(promoLine: PromoLineInterface, toppingsNbr: number): boolean {
        return toppingsNbr <= promoLine.maxToppingsQtty
    }
    checkMaxToppingsGroup(
        topping: MenuFamilyCustomToppingInterface,
        toppingsNbr: number,
        qty: number
    ): boolean {
        const groupID: number = topping.grouppingID
        const toppingGroup: MenuCustomToppingGroupInterface = this.findInArray(this.getToppingGroups(), 'id', groupID)
        return (toppingsNbr + qty) <= toppingGroup.maxToppings
    }
    checkMaxToppingsProduct(
        toppingsNbr: number,
        promoLine: PromoLineInterface,
        qty: number
    ): boolean {
        let maxQuantity: number = (promoLine! && 'maxToppingsQtty' in promoLine) ? promoLine.maxToppingsQtty : this.getCurrentProduct().maxToppings
        return (toppingsNbr + qty) <= maxQuantity
    }
    // Checking Min
    checkMin(promoLine: PromoLineInterface = null): CustomizerCheckMinInterface {
        const leftSideToppingsCounter: CounterInterface = this.getToppingsQuantityForEachGroup(CustomizerAllowedSides.leftSide)
        const leftSideTotalToppingsCounter: number = Object.keys(leftSideToppingsCounter).reduce(
            (result, value) => result + leftSideToppingsCounter[value], 0
        )
        const rightSideToppingsCounter: CounterInterface = this.getToppingsQuantityForEachGroup(CustomizerAllowedSides.rightSide)
        const rightSideTotalToppingsCounter: number = Object.keys(rightSideToppingsCounter).reduce(
            (result, value) => result + rightSideToppingsCounter[value], 0
        )
        const result: CustomizerCheckMinInterface = {
            group: this.getToppingGroups().reduce(
                (result: CounterInterface<boolean>, value) => {
                    const groupID: number = value.id
                    result[groupID] = true
                    if (!this.checkMinToppingsGroup(groupID, leftSideToppingsCounter[groupID])) {
                        result[groupID] = false
                    }
                    if (this.checkProductCustom()) {
                        if (!this.checkMinToppingsGroup(groupID, rightSideToppingsCounter[groupID])) {
                            result[groupID] = false
                        }
                    }
                    return result
                }, {}
            ),
            product: true
        }
        if (promoLine! && 'minToppingsQtty' in promoLine) {
            result.product = this.checkMinPromoLine(promoLine, leftSideTotalToppingsCounter)
            if (result.product && this.checkProductCustom()) {
                result.product = this.checkMinPromoLine(promoLine, rightSideTotalToppingsCounter)
            }
        }
        return result
    }
    checkMinPromoLine(promoLine: PromoLineInterface, toppingsNbr: number): boolean {
        return toppingsNbr >= promoLine.minToppingsQtty
    }
    checkMinToppingsGroup(groupID: number, toppingsNbr: number): boolean {
        const toppingGroup: MenuCustomToppingGroupInterface = this.findInArray(this.getToppingGroups(), 'id', groupID)
        return toppingsNbr >= toppingGroup.minToppings
    }
    // Getters
    getAvailableFamilies(): MenuFamilyInterface[] {
        return this.copyArray(this.availableFamilies)
    }
    getDoughGroupID(): number | string {
        return this.doughGroupID
    }
    getGroup(): MenuGroupInterface {
        return this.group
    }
    getSwirlGroupID(): number | string {
        return this.swirlGroupID
    }
    getLastDoughSelected(): string {
        return this.lastDoughSelected
    }
    getItemsToCreateOrderLine(qty: number): CreateOrderLineItemsInterface {
        const toppingList: PgOrderLineToppingsInterface[] = []
        this.getCurrentToppings().forEach(
            value => {
                const qtyAdded: number = (value.grouppingID == this.doughGroupID) ? 1 : (value.quantity - value.recipeQuantity)
                if (qtyAdded !== 0) {
                    toppingList.push({
                        description: value.description,
                        grouppingID: value.grouppingID,
                        fromRecipe: (value.recipeQuantity > 0),
                        id: value.id,
                        quantity: qtyAdded,
                        side: value.side
                    })
                }
            }
        )
        return {
            calories: 0,
            comments: null,
            data: toppingList,
            familyID: this.getCurrentFamily().id,
            groupID: this.getCurrentFamily().webSalesGroup,
            isHalfNdHalf: this.checkProductCustom(),
            menu: this.pgMenu.getMenu(),
            productID: this.getCurrentProduct().id,
            productShortName: this.getCurrentProduct().shortName,
            quantity: qty,
            toppings: this.getCurrentToppingsList(),
            toppingsNbr: 0,
            leftToppings: this.getCurrentToppingsList(CustomizerAllowedSides.leftSide),
            rightToppings: this.getCurrentToppingsList(CustomizerAllowedSides.rightSide),
            unitPrice: 0,
            selectedDoughs: this.copy(this.selectedDoughs)
        }
    }
    getCurrentFamily(): MenuFamilyInterface {
        return this.copy(this.family)
    }
    getCurrentProduct(): MenuFamilyProductInterface {
        return this.copy(this.product)
    }
    getCurrentProductImageID(subgroup: MenuSubGroupInterface = null): string {
        let familiesLength: number = (subgroup && 'families' in subgroup) ? subgroup.families.length : this.getAvailableFamilies().length
        return familiesLength > 1 ? this.getCommonProductId(this.getCurrentFamily(), this.getCurrentProduct()) : this.getCurrentProduct().id
    }
    getCurrentToppings(): MenuFamilyCustomToppingInterface[] {
        const result: MenuFamilyCustomToppingInterface[] = []
        this.getDoughs().forEach(
            value => {
                if (value.quantity > 0) {
                    result.push(value)
                }
            }
        )
        this.getToppings().forEach(
            value => {
                if (value.quantity > 0 || value.recipeQuantity > 0) {
                    result.push(value)
                }
            }
        )
        result.sort(
            (a, b) => {
                if (a.grouppingID === b.grouppingID) {
                    return a.order - b.order
                } else {
                    return a.grouppingID - b.grouppingID
                }
            }
        )
        return this.copyArray(result)
    }
    getCurrentToppingsList(filters: CustomizerSides[] = []): string[] {
        return this.getCurrentToppings().reduce(
            (result: string[], value) => {
                const canAdd: boolean = (filters.length === 0 || (filters.length > 0 && filters.indexOf(value.side) >= 0))
                const qtyAdded: number = (value.grouppingID == 1 && !value.isRequired) ? 1 : (value.quantity - value.recipeQuantity)
                if (canAdd === true && qtyAdded !== 0) {
                    const fillTimes: number = (qtyAdded > 0) ? qtyAdded : 1
                    const mathSymbol: string = (qtyAdded > 0) ? '+' : '-'
                    result = this.pushToArrayNtimes(result, fillTimes, `${mathSymbol}${value.id}`)
                }
                return result
            }, []
        )
    }
    getDoughs(): MenuFamilyCustomToppingInterface[] {
        return this.copyArray(this.doughs)
    }
    getRecipeToppings(): MenuFamilyCustomToppingInterface[] {
        return this.copyArray(this.filterIfNotInArray(this.toppings, 'recipeQuantity', 0))
    }
    getSize(): string {
        return this.size
    }
    getSubgroup(): MenuSubGroupInterface {
        return this.subgroup
    }
    getToppingGroups(excludeDoughs: boolean = false): MenuCustomToppingGroupInterface[] {
        return this.toppingGroups.reduce(
            (result: MenuCustomToppingGroupInterface[], value) => {
                if ((excludeDoughs && value.id !== this.getDoughGroupID()) || !excludeDoughs) {
                    result.push(this.copy(value))
                }
                return result
            }, []
        )
    }
    getToppings(): MenuFamilyCustomToppingInterface[] {
        return this.copyArray(this.toppings)
    }
    getToppingsDescription(): string {
        return this.toppingsDescription
    }
    getToppingsQuantityForEachGroup(filters: CustomizerSides[] = []): CounterInterface {
        const counter: CounterInterface = {}
        this.getToppingGroups().forEach(
            value => {
                counter[value.id] = 0
                const toppingsList: MenuFamilyCustomToppingInterface[] = value.toppings// this.filterInArray(this.getRecipeToppings(), 'grouppingID', value.id).concat(value.toppings)
                toppingsList.forEach(
                    v => {
                        let canCount: boolean = (filters.length === 0 || filters.length > 0 && filters.indexOf(v.side) >= 0)
                        if (canCount === true) {
                            counter[value.id] += v.quantity
                        }
                    }
                )
            }
        )
        return counter
    }
    isDoughRequired(): boolean {
        return this.doughRequired
    }
    // Setters
    setAvailableFamilies(): void {
        this.availableFamilies = []
        if (this.product) {
            const groupAndSubGroup: MenuGetGroupAndSubGroupFromFamilyInterface = this.pgMenu.getGroupAndSubGroupFromFamily(this.family.id)
            this.availableFamilies = this.getAvailableFamiliesOfProduct((groupAndSubGroup && groupAndSubGroup.subGroup && groupAndSubGroup.subGroup.families) ? groupAndSubGroup.subGroup.families : [], this.product, true)
            this.group = groupAndSubGroup.group
            this.subgroup = groupAndSubGroup.subGroup
        }
    }
    setSize(): void {
        this.size = (this.family.size && this.family.size !== 'N') ? this.family.size : 'N'
    }
    setToppingGroups(): void {
        this.toppingGroups = []
        const menu: MenuInterface = this.pgMenu.getMenu()
        const that: this = this
        menu.toppingGroups.forEach(
            value => {
                const toppings: MenuFamilyCustomToppingInterface[] = (value.id === this.getDoughGroupID()) ? this.filterInArray(this.getDoughs(), 'grouppingID', value.id) : this.filterInArray(this.getToppings(), 'grouppingID', value.id)
                const recipeRequiredToppings = this.getCurrentProduct().recipe.reduce(
                    (result: MenuFamilyCustomToppingInterface[], value) => {
                        const position: number = this.findIndexInArray(toppings, 'id', value.invSaleItemID)
                        if (position >= 0 && toppings[position].isRequired) {
                            result.push(toppings[position])
                        }
                        return result
                    }, []
                )
                const hasRequiredToppings: boolean = recipeRequiredToppings.length > 0//this.someInArray(recipeToppings, 'isRequired', true)
                if (toppings.length > 0) {
                    let addedToppings: MenuFamilyCustomToppingInterface[] = this.filterIfNotInArray(toppings, 'quantity', 0)
                    let hasCheeseByDefault: boolean = (addedToppings.length === 1 && addedToppings[0].id === '671' && addedToppings[0].quantity === addedToppings[0].recipeQuantity)
                    let showOnlyRecipeToppings: boolean = (value.minToppings > 0 && value.maxToppings > value.minToppings && hasRequiredToppings)
                    this.toppingGroups.push(
                        {
                            ...value,
                            chooseCondition: that.checkChooseConditionOfToppingGroup(value),
                            chooseUpToCondition: that.checkChooseUpToConditionOfToppingGroup(value),
                            hasToppings: (addedToppings.length === 0 || hasCheeseByDefault) ? false : true,
                            mandatoryCondition: that.checkMandatoryConditionOfToppingGroup(value),
                            showMore: false,
                            showOnlyRecipeToppings: showOnlyRecipeToppings,
                            showOnlyRequiredToppings: (recipeRequiredToppings.length >= value.maxToppings),
                            toggleToppings: (value.maxToppings === 1),
                            toppings: showOnlyRecipeToppings ? recipeRequiredToppings : toppings
                        }
                    )
                }
            }
        )
    }
    setToppings(): void {
        this.doughs = []
        this.toppings = []
        this.family.toppings.sort(
            (a, b) => {
                if (a.grouppingID === b.grouppingID) {
                    return a.order - b.order
                } else {
                    return a.grouppingID - b.grouppingID
                }
            }
        ).forEach(
            value => {
                let productRecipeToppings: MenuFamilyProductToppingInterface[] = (this.productHasRecipe === true) ? this.filterInArray(this.product.recipe, 'invSaleItemID', value.id) : []
                let isRequired: boolean = (productRecipeToppings.length > 0 && productRecipeToppings.some(t => t.required === 'S'))
                let productRequiredToppings: MenuFamilyProductToppingInterface[] = (isRequired) ? productRecipeToppings.filter(t => t.required === 'S') : []
                let isDough: boolean = (value.grouppingID == this.doughGroupID)
                let prop: string = isDough ? 'doughs' : 'toppings'
                let topping: MenuFamilyCustomToppingInterface = {
                    ...value,
                    imageUrl: `${this.assetsUrl}toppings/${value.id}.png`,
                    isRequired: isRequired,
                    quantity: (isDough && !isRequired && this.mustSelectDough) ? 0 : productRecipeToppings.length,
                    recipeQuantity: productRecipeToppings.length,
                    requiredQuantity: productRequiredToppings.length,
                    side: CustomizerSides.whole,
                    stock: true
                }
                this[prop].push(topping)
            }
        )
        this.setToppingGroups()
        this.checkDoughRequired()
        this.updateToppingsDescription()
    }
    // Update
    updateAssetsUrl(url: string): void {
        const properties: ['doughs', 'toppings'] = ['doughs', 'toppings']
        this.assetsUrl = url
        properties.forEach(
            value => {
                this[value].forEach(
                    v => {
                        v.imageUrl = `${this.assetsUrl}toppings/${v.id}.png`
                    }
                )
            }
        )
        this.setToppingGroups()
    }
    updateCustomizerFromItems(
        items: CreateOrderLineItemsInterface,
        promoEngineOptions: ProductOptions,
        promoLine: PromoLineInterface
    ): void {
        this.updateSelectedDough(items)
        if (items.data.length > 0) {
            items.data.forEach(
                value => {
                    let toppingID: string = value.id
                    let topping: MenuFamilyToppingInterface = this.pgMenu.getFamilyTopping(items.familyID, toppingID)
                    if (topping) {
                        let isDough: boolean = (topping.grouppingID == this.doughGroupID)
                        let toppingPosition: number = this.findIndexInArray((isDough ? this.doughs : this.toppings), 'id', topping.id)
                        let toppingProperty: string = isDough ? 'doughs' : 'toppings'
                        if (toppingPosition >= 0) {
                            let customTopping: MenuFamilyCustomToppingInterface = this[toppingProperty][toppingPosition]
                            let isToppingRequired: boolean = (customTopping.requiredQuantity > 0)
                            if (value.quantity > 0) {
                                let qtyToAdd: number = (value.fromRecipe === true && value.grouppingID !== this.getDoughGroupID()) ? (value.quantity + 1) : value.quantity
                                let checkMaxResult: CustomizerCheckMaxInterface = this.checkMax(
                                    customTopping,
                                    promoEngineOptions,
                                    promoLine,
                                    false,
                                    qtyToAdd
                                )
                                let checkProperties: string[] = []
                                if (value.side === CustomizerSides.whole || value.side === CustomizerSides.left) {
                                    checkProperties.push('leftSide')
                                }
                                if (value.side === CustomizerSides.whole || value.side === CustomizerSides.right) {
                                    checkProperties.push('rightSide')
                                }
                                let addTopping: boolean = checkProperties.reduce(
                                    (result: boolean, v) => {
                                        if (checkMaxResult[v].group === false || checkMaxResult[v].product === false) {
                                            result = false
                                        }
                                        return result
                                    }, true
                                )
                                if (addTopping) {
                                    this.modifyTopping(topping, value.side, qtyToAdd)
                                }
                            } else {
                                if (isToppingRequired) {
                                    this.modifyTopping(topping, value.side, customTopping.requiredQuantity)
                                } else {
                                    this.removeTopping(topping)
                                }
                            }
                        }
                    }
                }
            )
            this.updateToppingsDescription()
        }
    }
    updateCustomizerFromPromoEngineResult(result: ProductCalculatedResponse): void {
        const isComposed: boolean = (result.type === OrderLineTypes.composed)
        let calculated: ProductCalculatedResponse[] = []
        if (isComposed) {
            calculated = result.sublines
        } else {
            calculated.push(result)
        }
        calculated.forEach(
            (value, index) => {
                value.toppings.forEach(
                    v => {
                        if (v) {
                            const mathSymbol: string = v.charAt(0)
                            const toppingID: string = v.substring(1)
                            const topping: MenuFamilyToppingInterface = this.pgMenu.getFamilyTopping(
                                this.getCurrentFamily().id,
                                toppingID
                            )
                            if (topping && 'id' in topping) {
                                const fromRecipe: boolean = this.someInArray(this.getCurrentProduct().recipe, 'invSaleItemID', topping.id)
                                if (mathSymbol === '+') {
                                    // Quantity
                                    const toppingQuantity: number = (value.toppings as Array<string>).filter(t => t === v).length
                                    const qty: number = (fromRecipe === true && topping.grouppingID !== this.getDoughGroupID()) ? (toppingQuantity + 1) : toppingQuantity
                                    // Side
                                    const toppingSide: CustomizerSides = (!isComposed) ? CustomizerSides.whole : (index === 0 ? CustomizerSides.left : (calculated[0].toppings.indexOf(v) >= 0) ? CustomizerSides.whole : CustomizerSides.right)
                                    this.modifyTopping(topping, toppingSide, qty)
                                } else if (mathSymbol === '-') {
                                    this.removeTopping(topping)
                                }
                            }
                        }
                    }
                )
            }
        )
        this.updateToppingsDescription()
    }
    updateDoughs(d: MenuFamilyCustomToppingInterface[]): void {
        this.doughs = this.copy(d)
        this.doughs.sort(
            (a, b) => {
                if (a.grouppingID === b.grouppingID) {
                    return a.order - b.order
                } else {
                    return a.grouppingID - b.grouppingID
                }
            }
        )
        this.setToppingGroups()
        this.updateToppingsDescription()
    }
    updateFamily(f: MenuFamilyInterface): void {
        this.family = this.copy(f)
        this.setSize()
    }
    updateProduct(p: MenuFamilyProductInterface): void {
        this.product = this.copy(p)
        this.productHasRecipe = (this.product && this.product.hasOwnProperty('recipe') && Array.isArray(this.product.recipe) && this.product.recipe.length > 0)
        this.setAvailableFamilies()
        this.setToppings()
    }
    updateStockProducts(stockProducts: CounterInterface<boolean>): void {
        this.stockProducts = stockProducts
    }
    updateStockToppings(stockToppings: CounterInterface<boolean>): void {
        this.stockToppings = stockToppings
    }
    updateRecipeToppings(t: MenuFamilyCustomToppingInterface[]): void {
        t.forEach(
            value => {
                const position: number = this.findIndexInArray(this.toppings, 'id', value.id)
                if (position >= 0) {
                    this.toppings[position].quantity = value.quantity
                    this.toppings[position].side = value.side
                }
            }
        )
        this.toppings.sort(
            (a, b) => {
                if (a.grouppingID === b.grouppingID) {
                    return a.order - b.order
                } else {
                    return a.grouppingID - b.grouppingID
                }
            }
        )
        this.setToppingGroups()
        this.updateToppingsDescription()
    }
    updateToppings(t: MenuFamilyCustomToppingInterface[]): void {
        this.toppings = this.copyArray(t)
        this.toppings.sort(
            (a, b) => {
                if (a.grouppingID === b.grouppingID) {
                    return a.order - b.order
                } else {
                    return a.grouppingID - b.grouppingID
                }
            }
        )
        this.setToppingGroups()
        this.updateToppingsDescription()
    }
    updateToppingsDescription(): void {
        let currentToppings: MenuFamilyCustomToppingInterface[] = this.getCurrentToppings()
        this.toppingsDescription = (currentToppings.length > 0) ? currentToppings.reduce(
            (result: string[], value, index, arr) => {
                result = this.pushToArrayNtimes(result, value.quantity, value.description)
                return result
            }, []
        ).join(', ') : this.getCurrentProduct().webDescription
    }

    findToppingsAdded(toppingGroupID, isSwirl = false) {
        let toppings = [];
        let res = '';
        let toppingGroups: MenuCustomToppingGroupInterface[] = this.getToppingGroups();
        for (let z = 0; z < toppingGroups.length; z++) {
            const topG = toppingGroups[z];
            if (toppingGroupID && topG.id == toppingGroupID) {
                for (let y = 0; y < topG.toppings.length; y++) {
                    const topping = topG.toppings[y];
                    if (!isSwirl && topping.quantity > 0) {
                        toppings.push(topping.sourceName);
                    } else if (isSwirl && topping.quantity > 0) {
                        toppings.push(topping.sourceName);
                    }
                }
            } else if (!toppingGroupID) {
                const topG = toppingGroups[z];
                if (topG.id != this.getDoughGroupID() && topG.id != this.getSwirlGroupID()) {
                    for (let y = 0; y < topG.toppings.length; y++) {
                        const topping = topG.toppings[y];
                        if (topping.quantity > 0) {
                            toppings.push(topping.sourceName);
                        }
                    }
                }
            }
        }
        for (let j = 0; j < toppings.length; j++) {
            const element = toppings[j];
            if (j < toppings.length - 1) {
                res += element + ", ";
            } else {
                res += element
            }
        }
        return res != '' ? res : false;
    }

    findToppingsAddedList(toppingGroupID, isSwirl = false) {
        let toppings = [];
        let toppingGroups: MenuCustomToppingGroupInterface[] = this.getToppingGroups();
        for (let z = 0; z < toppingGroups.length; z++) {
            const topG = toppingGroups[z];
            if (toppingGroupID && topG.id == toppingGroupID) {
                for (let y = 0; y < topG.toppings.length; y++) {
                    const topping = topG.toppings[y];
                    if (!isSwirl && topping.quantity > 0) {
                        toppings.push(topping.sourceName);
                    } else if (isSwirl && topping.quantity > 0) {
                        toppings.push(topping.sourceName);
                    }
                }
            } else if (!toppingGroupID) {
                const topG = toppingGroups[z];
                if (topG.id != this.getDoughGroupID() && topG.id != this.getSwirlGroupID()) {
                    for (let y = 0; y < topG.toppings.length; y++) {
                        const topping = topG.toppings[y];
                        if (topping.quantity > 0) {
                            toppings.push(topping.sourceName);
                        }
                    }
                }
            }
        }
        return toppings;
    }


}